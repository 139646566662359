import React, { useEffect, useRef } from "react";
import StyledG from "./DescriptionStyle";
import * as d3 from "d3";
import { wrapText, givenRange, makeCurlyBrace } from "./common";
import { remaining } from "common/graphs/Axis";

const thresholdBrackets = [
  {
    from: "threshold",
    to: "max",
    label: `Values over threshold`,
    classed: "warning-zone",
  },
];

const thresholdCardinals = [
  { name: "max", label: "Maximum" },
  { name: "median", label: "Median" },
  { name: "min", label: "Minimum" },
  { name: "threshold", label: "Warning Threshold" },
];

const ThresholdsDescription = ({
  canvas,
  data = {},
  axis = {},
  xFrom = "x",
  yFrom = "y",
  dimensions = null,
  margin = { ...DEFAULT_MARGIN, left: 0, right: 0 },
  precision = 2,
  units = undefined,
  format = (d) => (d % 1 ? formaDecimal(d) : d),
  label,
  brackets = thresholdBrackets,
  cardinals = thresholdCardinals,
  ...scaleParams
}) => {
  const chart = useRef(null);
  label =
    label !== undefined
      ? label
      : (d) => (d.value === null ? "--" : d.value.toFixed(precision));
  useEffect(() => {
    if (dimensions === null) {
      return;
    }
    const { width, height } = remaining(dimensions, margin);
    if (width < 1 || height < 1) {
      return;
    }

    const xAxis = axis[xFrom];
    const yAxis = axis[yFrom];
    const xScale = xAxis.scale;
    const yScale = yAxis.scale;

    const [left, right] = xScale.range();
    const center = (right - left) / 2;
    const thickness = d3.max([(right - left) / 4, 20]);

    const main = d3.select(chart.current);
    const plot = main.append("g").attr("class", "plot");
    const overX = givenRange(xScale.range());

    const [rangeStart, rangeEnd] = xScale.range();
    const newBrackets = plot
      .selectAll(".bracket")
      .data(brackets)
      .enter()
      .append("g")
      .attr("class", ({ classed }) => `bracket ${classed}`);
    newBrackets
      .append("path")
      .attr("d", ({ from, to }, index) =>
        makeCurlyBrace(left, yScale(data[from]), left, yScale(data[to]))
      )
      .attr("class", ({ classed }) => `${classed} reverse`);

    newBrackets
      .append("text")
      .text((d) => d.label)
      .attr("y", ({ from, to }) => (yScale(data[from]) + yScale(data[to])) / 2)
      .attr("x", right - 16)
      .attr("class", `align-left`);

    const newCardinals = plot
      .selectAll(".cardinal")
      .data(cardinals)
      .enter()
      .append("g")
      .attr("class", "cardinal");
    newCardinals
      .append("text")
      .attr(
        "class",
        ({ name }, index) => `cardinal ${name} ${index === 0 ? "hanging" : ""}`
      )
      .text(({ label }) => label)
      .attr("x", left)
      .attr("y", ({ name }) => yScale(data[name]));
    newCardinals
      .append("line")
      .attr("class", ({ name }) => name)
      .attr("x1", function () {
        const text = this.previousElementSibling;
        const textWidth = text.getBBox().width;
        return left + textWidth;
      })
      .attr("y1", ({ name }) => yScale(data[name]))
      .attr("x2", right)
      .attr("y2", ({ name }) => yScale(data[name]));

    return () => {
      main.selectAll(".plot").remove();
      main.selectAll(".axis").remove();
    };
  }, [chart, canvas, dimensions, axis]);

  return <StyledG ref={chart}></StyledG>;
};

export default ThresholdsDescription;
