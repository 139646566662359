/*globals views, components */
import React, { useEffect, useState, useRef } from 'react';
import styled from "styled-components";
import * as d3 from "d3";
import Request from 'common/Request';
import NVLineChart from 'common/graphs/NVLineChart';
import RangeSliderInput from 'common/RangeSliderInput';
import {safeStr} from "common/api";


const TileContainer = styled.div`
  min-height: 10cm;
  display: flex;
  flex-direction: column;
  & > h4 {
    text-align: center;
    margin: 20px 0 0 0;
  }
  & > .graph {
    flex: 1 1 100%;
    .nv-y.nv-axis .nv-axisMaxMin {
      display: none;
    }
  }
  &.spread {
    justify-content: space-between;
    padding: 1em;
    align-content: flex-start;
  }
  &.can-compress {
    min-height: 2cm;
  }
  .input-group {
    max-width: 20ch;
  }
`;

const parseStatisticsResponse = (
  input,
  ipAddress,
  direction,
  timePeriod,
  metric
  ) => {
  const [head, ...rows] = input.trim("\n").split("\n");

  let column = "";
  let networkColumn = "";
  switch (metric) {
    case "averageSpeed":
      column = direction === "downlink" ? "DOWN-MBPS" : "UP-MBPS";
      break;
    case "maxSpeed":
      column = direction === "downlink" ? "DOWN-MBPS" : "UP-MBPS";
      break;
    case "activeFlows":
      column = "FL-ACTIVE";
      break;
    case "flowsCreated":
      column = "FL-CREATED";
      break;
    case "latency":
      column = "SUBS-ACCESS-RTT-MS";
      networkColumn = "NETWORK-ACCESS-RTT-MS";
      break;
    case "retransmissions":
      column = "SUBS-ACCESS-RTX-%";
      networkColumn = "NETWORK-ACCESS-RTX-%";
      break;
    case "congestion":
      column = "CONGESTION-%";
      break;
    case "traffic":
      column = "BYTES-DN-NEAR-LIMIT-%";
      break;
  }
  const translations = {
    [column]: ipAddress,
  };

  if (networkColumn) {
    translations[networkColumn] = "network";
  }

  const skipped = [];

  const keys = head
    .split(/\s+/)
    .map((key) =>
      skipped.includes(key) ? key : translations[key] || asJSAttribute(key)
    );
    return rows.map((row) => {
      const item = Object.fromEntries(timeAndNumbers(row, keys, skipped));
      if (metric === "flowsCreated") {
        item[ipAddress] = item[ipAddress] / timePeriodMap[timePeriod];
      }
  
      return item;
    });
};

export const parseFloatOrNull = (value) =>
  value === "n/a"
    ? null
    : value.endsWith("%") === true
    ? parseFloat((parseFloat(value.trim("%")) / 100).toFixed(4))
    : parseFloat(value);

const asJSAttribute = (input) =>
  input
    .split(/[\s-]+/)
    .map(lowerFirstUpperOthers)
    .join("");

const capitalizeFirstLetter = (word) =>
  word[0].toUpperCase() + word.slice(1).toLowerCase();

const lowerFirstUpperOthers = (word, index) =>
  index === 0 ? word.toLowerCase() : capitalizeFirstLetter(word);

const dateTimeParser = d3.timeParse("%Y-%m-%dT%H:%M:%S");

const timeAndNumbers = (row, keys, skipped = []) =>
  row
    .split(/\s+/)
    .map((value, index) =>
      skipped.length > 0 && skipped.includes(keys[index])
        ? null
        : [
            keys[index],
            index > 0 ? parseFloatOrNull(value) : dateTimeParser(value),
          ]
    )
    .filter((item) => item !== null);

const calcTimeRange = (items) => {
  const [from, to] = d3.extent(items.map((item) => item.time));
  return { from, to };
};

const isInsideRange =
  ({ from, to }, field = "time") =>
  (item) =>
    from <= item[field] && item[field] <= to;

const timePeriodMap = {
  oneDay: 10,
  oneWeek: 10,
  oneMonth: 60,
  threeMonths: 60,
};

const hoursByTimePeriodMap = {
  oneDay: 24,
  oneWeek: 24 * 7,
  oneMonth: 24 * 30,
  threeMonths: 24 * 30 * 3,
};

const metricMap = {
  averageSpeed: "speed",
  maxSpeed: "max-speed",
  activeFlows: "flows",
  flowsCreated: "flows",
  latency: "latency",
  retransmissions: "retransmission",
  congestion: "congestion",
  traffic: "congestion",
};

const tooltipUnitMap = {
  averageSpeed: "mbps",
  maxSpeed: "mbps",
  activeFlows: "flows",
  flowsCreated: "flows/minute",
  latency: "ms",
  retransmissions: "%",
  congestion: "%",
  traffic: "%",
};

const yAxisUnitMap = {
  averageSpeed: "Mbps",
  maxSpeed: "Mbps",
  activeFlows: "Flows",
  flowsCreated: "Flows-per-minute",
  latency: "ms",
  retransmissions: "% retransimissions",
  congestion: "% congestion",
  traffic: "% traffic at max speed",
};

const SingleColumnGrid = styled.div`
  display: grid;
  flex: 1 1 100%;
`;

const returnTo = "viewStatusSubscribers";

const openSubscriberDetails = (entity, key) => {
  const target =
    entity === "ip-address"
      ? { addr: key, subsId: null }
      : { addr: null, subsId: key };
  views.doKeep(returnTo);
  globalNavigate("viewStatusSubscribers", {...target, returnView: returnTo});
};

const TimeEvolutionMetricsChart = ({
  direction,
  timePeriod,
  metric,
  entity,
  refreshSignal,
  subscribersToPlot=null,
}) => {
  const [request, setRequest] = useState(null);
  const [range, setRange] = useState();
  const [initialRange, setInitialRange] = useState();
  const [showRangeSliderInput, setShowRangeSliderInput] = useState(false);
  const context = useRef({entity, metric, timePeriod, direction});
  const yAxisUnits = yAxisUnitMap[metric];

  const doLoad = () => {
    setRequest(loadStatistics());
  };

  useEffect(() => {
    removeTooltips();
    setShowRangeSliderInput(false);
  }, [request]);

  useEffect(() => {
    return setRange(initialRange);
  }, [initialRange]);

  useEffect(() => {
    context.current = {...context.current, entity, metric, timePeriod, direction, subscribersToPlot};
    doLoad();
  }, [metric, timePeriod, refreshSignal, entity, subscribersToPlot]);

  const loadStatistics = () =>  {
    const start = Date.now()
    const {entity, metric, timePeriod, direction, subscribersToPlot} = context.current;
    if (subscribersToPlot === null ) { 
      return Promise.resolve({fields: [], items: []})
    }
    const targets = entity === 'ip-address'
      ? subscribersToPlot.map( ({addr}) => addr )
      : Array.from(new Set(subscribersToPlot.map( ({subsId}) => subsId )))

    if (entity === 'subscriber-id' && (metric === "latency" || metric === "retransmissions")) {
      targets.push("network");
    }

    const doTimeRangeSettings = (result) => {
      setInitialRange(calcTimeRange(result));
      setShowRangeSliderInput(true);
      return result;
    } 
    const parseResponseOf = target => response =>
      parseStatisticsResponse(
        response,
        target,
        direction,
        timePeriod,
        metric
      );
    const requestAndParse = (target) =>
      ifCl.rush(
        `show statistics subscribers ${metricMap[metric]} ${
          entity === "ip-address" ? '' : 'id '
        }${safeStr(target)
        } hours ${hoursByTimePeriodMap[timePeriod]} interval ${timePeriodMap[timePeriod]}`)
        .then(parseResponseOf(target))
        .catch(console.error) 
    
    const combineResults = ([first, ...rest]) =>
      first === undefined
        ? []
        : doTimeRangeSettings(first).map( (current, position) => 
            Object.assign(current, ...rest.map( other => other[position] )) 
          )
    const timeThis = result => {
      const took = (Date.now() - start);
      console.log(`processing loadStatistics ${targets.length} targets took ${took}ms ` )
      return result;
    }
    return Promise.all(targets.map(target => requestAndParse(target)))
      .then( combineResults )
      .then( timeThis )
      .then( items => ({
        items,
        fields: [
          ...(targets.map( name => ({ name, label: name }))),
          ...(
            (entity === 'subscriber-id' && (metric === "latency" || metric === "retransmissions"))
            ? [{ name: "network", label: "Whole-network" }]
             : []
          )
        ]
      }))
  }

  function removeTooltips() {
    for (const element of document.getElementsByClassName(
      "nvtooltip xy-tooltip"
    )) {
      element.remove();
    }
  }

  const formatUnit = (metric) => (value) =>
    value === null || value === undefined
      ? "n/a"
      : `${
          ["averageSpeed", "maxSpeed"].includes(metric)
            ? value.toFixed(3)
            : value
        } ${tooltipUnitMap[metric]}`;

  return (
    <SingleColumnGrid gap="1rem">
      <TileContainer>
        <small>
          <code>right-click</code> in the lines inside the chart or in the
          labels for each subscriber to be inspected in the dashboard.
        </small>
        <div className="graph">
          {request === null ? null : (
            <Request during={request}>
              {({ fields, items }) => (
                  <NVLineChart
                    xField="time"
                    items={range ? items.filter(isInsideRange(range)) : items}
                    yAxisUnits={yAxisUnits}
                    xAxisFormat={d3.timeFormat("%m/%d %H:%M")}
                    fields={fields}
                    yAxisTopGap={0.05}
                    yAxisTicksNumber={5}
                    yTooltipFormat={formatUnit(metric)}
                    onSeriesInspect={(key) =>
                      openSubscriberDetails(entity, key)
                    }
                  />
                )
              }
            </Request>
          )}
        </div>
      </TileContainer>
      <TileContainer className="full-width can-compress">
        {showRangeSliderInput && initialRange && (
          <RangeSliderInput
            {...initialRange}
            onChange={(value) => setRange(value)}
          />
        )}
      </TileContainer>
    </SingleColumnGrid>
  );
}

export default TimeEvolutionMetricsChart;
