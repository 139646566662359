import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";

export const applyParams = (child, params = {}) =>
  React.isValidElement(child) ? React.cloneElement(child, params) : child;

const WrapperDIV = styled.div`
  &,
  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const Canvas = ({ className, children }) => {
  const [wrapper, canvas] = [useRef(null), useRef(null)];
  const [dimensions, setDimensions] = useState(null);
  const updateDimmensions = () => {
    if (canvas.current === null) {
      return;
    }
    const { width, height } = canvas.current.getBoundingClientRect();
    setDimensions({ width, height });
  };
  useEffect(() => {
    const target = wrapper.current;
    const resizeObserver = new ResizeObserver(updateDimmensions);
    resizeObserver.observe(target);
    return () => {
      resizeObserver.unobserve(target);
    };
  }, [wrapper, canvas]);
  return (
    <WrapperDIV ref={wrapper} className={className}>
      <svg ref={canvas}>
        {dimensions !== null
          ? React.Children.map(children, (child) =>
              applyParams(child, { canvas, dimensions })
            )
          : null}
      </svg>
    </WrapperDIV>
  );
};

export default Canvas;
