import styled from "styled-components";
import colors from './colors';
import colorsDark from "./colorsDark";

const StyledContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  .field {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;

    .square {
      width: 12px;
      min-width: 12px;
      height: 12px;
      border: 2px solid;
      border-radius: 6px;
    }

    label {
      user-select: none;
      margin-bottom: 0;
      font-size: 12px;
      font-weight: normal;
      color: var(--chart-text-color);
      cursor: pointer;
      font-family: "AllianceNo2-Regular";
    }
  }
`;

function LegendItem({ color, label, name, isSelected, onSelect }) {
  const field = { label, name, color };

  return (
    <div
      className="field"
      onClick={() => onSelect(field, "single")}
      onDoubleClick={() => onSelect(field, "double")}
    >
      <div
        className="square"
        style={{
          backgroundColor: isSelected ? color : "white",
          borderColor: color,
        }}
      ></div>
      <label> {label}</label>
    </div>
  );
}

export default function Legend({ fields, onChange, value }) {
  const colorsSet = login.isTheme("light") ? colors : colorsDark;

  function handleSelect(field, selection) {
    if (selection === "single") {
      if (value.size === 1) {
        const newFilterSet = new Set(fields.map((field) => field.name));
        onChange(newFilterSet);
      } else {
        const newFilterSet = new Set(value);
        if (newFilterSet.has(field.name)) {
          newFilterSet.delete(field.name);
        } else {
          newFilterSet.add(field.name);
        }
        onChange(newFilterSet);
      }
    } else {
      const newFilterSet = new Set([field.name]);
      onChange(newFilterSet);
    }
  }

  return (
    <StyledContainer>
      {fields.map((field, index) => {
        const { name, label, color } = field;
        
        return (
          <LegendItem
            label={label}
            color={color || colorsSet[index]}
            name={name}
            isSelected={value.has(name)}
            onSelect={handleSelect}
          />
        );
      })}
    </StyledContainer>
  );
}
