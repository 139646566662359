//
// Table adapter class
//
const tabulatedToArray = function (row) {
  return row.trim().split(/ +/);
};
const doesNothing = () => {};

const MAX_CELL_TEXT_LENGTH = 31;

//
// IP v4 Ascii to Integer
const fromHex = (part) => parseInt(part, 16) || 0;

const expandToLength = (value, index, array) =>
  value === "" ? new Array(8 - (array.length - 1)).fill(null) : value;

export const atoiv6 = (value) =>
  value
    .split(":")
    .map(expandToLength)
    .flat()
    .reduce(
      (prev, part, idx) =>
        prev + BigInt(fromHex(part) * Math.pow(65536, 7 - idx)),
      BigInt(0)
    );

function atoiv4(value) {
  return Number(
    value
      .split(".")
      .reduce((prev, part, idx) => prev + part * Math.pow(256, 3 - idx), 0)
  );
}

export const atoi = value => 
  value.includes(":") ? atoiv6(value) : atoiv4(value);

// IP v4 Integer to Ascii
function itoav4(value) {
  const part1 = value & 255;
  const part2 = (value >> 8) & 255;
  const part3 = (value >> 16) & 255;
  const part4 = (value >> 24) & 255;
  return part4 + "." + part3 + "." + part2 + "." + part1;
}

const abbreviate = (value) => {
  const matches = value.match(/:(0:)+/g);
  if (matches === null) {
    return value;
  }
  const [larger, ...rest] = [...matches].sort((a, b) =>
    a.length > b.length ? -1 : 1
  );
  return value
    .replace(larger, "::")
    .replace(/^:[^:]/, "0:") //ahead zero
    .replace(/::0$/, "::"); // trailing zero
};

const compressor = (value, idx) =>
  value === "0000" ? "0" : value.replace(/^0+/, "");

export const itoav6 = (value) =>
  value === 0n ? '::'
  : abbreviate(
    value
      .toString(16)
      .padStart(32, "0")
      .match(/[0-9A-Fa-f]{4}/g)
      .map(compressor)
      .join(":")
  );

const toggleGroupingCollapse =  ({target}) => {
  const trigger = $(target.closest('.grouping'));
  const collapsed = trigger.is('.collapsed') === false;
  collapsed ? trigger.addClass('collapsed') : trigger.removeClass('collapsed');
}
// IP v4 or v6 Integer to Ascii
export const itoa = (value) => {
  return typeof value === "bigint" ? itoav6(value) : itoav4(value);
};

const sumToSeconds = (result, current, index) =>
  result + current * Math.pow(60, 2 - index);

function durationToInt(value) {
  return value.split(":").reduce(sumToSeconds, 0);
}

function intToDuration(input) {
  let value = input;
  const fillValue = (_, index) => {
    const weigth = Math.pow(60, 2 - index);
    const part = index < 2 ? Math.floor(value / weigth) : value;
    value = value - part * weigth;
    return index > 0 ? String(part).padStart(2, "0") : String(part);
  };
  return Array(3).fill(0).map(fillValue).join(":");
}

const formatPrecision = (value, precision, thousands) =>
  value.toLocaleString(undefined, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    useGrouping: thousands,
  });

const dynamicPrecision = (value, precisions = []) => {
  const [ _threshold, precision ] = precisions.find( ([below, precision]) => value < below ) || [0, 0];
  return formatPrecision(value, precision);
}

const expressNumber = (value, precision = 0, thousands = true) =>
  Array.isArray(precision)
    ? dynamicPrecision(value, precision)
    : formatPrecision(value, precision, thousands)

const _localeSettings = [
  "en-US",
  {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  },
];

const expressAsDataSpeed = (value) =>
  value > 999999
    ? `${Number(value / 1000000).toLocaleString(..._localeSettings)} Gbps`
    : value > 999
    ? `${Number(value / 1000).toLocaleString(..._localeSettings)} Mbps`
    : `${Number(value).toLocaleString(..._localeSettings)} Kbps`;

const inDataSpeedUnits = (value) =>
  value === "no" || value === false
    ? "no"
    : expressAsDataSpeed(parseFloat(value));

class Table {
  constructor(
    target,
    columns = [],
    { htmlOnly = false, filters = null, order = [], provider, pageLength = 50 }
  ) {
    this._target = $(target);
    this._columns = columns;
    this._htmlOnly = htmlOnly;
    this._order = order;
    this._table = null;
    this._filters = filters;
    this._clickHandlerAssignation = null;
    this._provider = provider;
    this._pageLength = pageLength;
  }
  enableCSVExport(paginator) {
    const triggerHTML = `
      <span class="export-label">Export:</span> 
      <a class="clickable export-icon" alt="Export to CSV">
        <i class="material-icons">file_download</i>
      </a>`;
    const triggerWrapper = this._target
      .closest('.dataTables_wrapper')
      .find('.dataTables_filter').prepend(triggerHTML);
    triggerWrapper.find('.clickable').click( () => paginator.exportAsCSV() )
  }
  alreadyPresent() {
    return this._target.hasClass("present");
  }
  stop() {
    this._activeIngest = false;
    this._hideIngest();
  }
  clear() {
    this._disableClick();
    this.stop();
    if (this._isDatatable() && $.fn.DataTable.isDataTable(this._target)) {
      try {
        const datatable = this._target.DataTable();
        datatable && datatable.clear && datatable.clear();
        datatable && datatable.destroy && datatable.destroy();
      } catch (e) {
        console.error("table destroy failed", e);
      }
    }
    this._target.html("");
  }
  insert(rows) {
    if (this._isDatatable()) {
      this._insertIntoDataTable(rows);
    } else {
      this._insertIntoHTML(rows);
    }
  }
  _showIngest(count, total) {
    let message = this._target.find("thead #processing");
    if (message.length === 0) {
      message = $(`
      <tr id="processing">
        <th colspan="99" id="report" >
          Loading <span id="count">0</span> of ${total}.
          <a id="cancel" class="hyperlink-text">press here to cancel</a>
        </th>
      </tr>`);
      message.prependTo(this._target.find("thead"));
      const abortIngest = (_event) => {
        this.stop();
        message.find("#report").html("cancelled.");
      };
      message.find("#cancel").on("click", abortIngest);
    }
    message.find("#count").html(count);
  }
  _hideIngest() {
    this._target.find("thead #processing").hide("slow");
  }
  async ingest(rows, chunkSize = 500, delay = 10) {
    if (rows.length <= chunkSize) {
      this.insert(rows);
    } else {
      this._doSegmentedIngest(rows, chunkSize, delay);
    }
  }
  async _doSegmentedIngest(rows, chunkSize = 500, delay = 10) {
    const wrapper = this;
    const total = rows.length;
    const start = Date.now();
    this._activeIngest = true;
    for (let position = 0; position < total; position += chunkSize) {
      if (this._activeIngest !== true) {
        console.log("Ingest aborted at", position);
        return false;
      }
      const chunk = rows.slice(position, position + chunkSize);
      this._showIngest(position + chunkSize, total);
      await new Promise((resolve, _reject) => {
        setTimeout(() => {
          wrapper.insert(chunk, position === 0);
          resolve();
        }, 10);
      });
    }
    this._hideIngest();
    const end = Date.now();
    console.log(`${rows.length} records took ${end - start}`);
  }
  render(data = []) {
    if (this._isDatatable()) {
      this._renderDataTable(data);
    } else {
      this._renderAsHTML(data);
    }
    return this._target.addClass("present");
  }
  getCellData(target) {
    return this._target.DataTable().cell(target).data();
  }
  getRowData(target) {
    const tr = $(target).closest("tr");
    const values = this._target.DataTable().row(tr).data();
    const valueRepresentation = (value, index) => {
      const repr = index < this._columns.length && this._columns[index].repr;
      return repr === undefined ? value : repr(value);
    }
    return values.map(valueRepresentation);
  }
  _attrUpdatesForColumnCells(column, onCellRender=doesNothing) {
    const table = this;
    const addTitleAttributes = (title) =>
      title === undefined
        ? undefined
        : (td) => {
            td.setAttribute("data-toggle", "tooltip");
            td.setAttribute("data-placement", "top");
            td.setAttribute("title", title);
          };
    const addCellClassName = (cellClassName) =>
      cellClassName === undefined
        ? undefined
        : typeof cellClassName === "function"
        ? (td, params) => {
            td.className = `${td.className} ${cellClassName(...params)}`;
          }
        : (td) => {
            td.className = `${td.className} ${cellClassName}`;
          };
    const doAddTitleAttributes = addTitleAttributes(column.title);
    const doAddCellClassName = addCellClassName(column.cellClassName);
    const enableActions =
      column.actions === undefined
        ? undefined
        : (column, td) => {
            const row = table.getRowData(td);
            const cellData = table.getCellData(td);
            const actions = typeof column.actions === "function"
              ? column.actions(cellData, row)
              : column.actions;
            const handlerById = Object.fromEntries(
              actions
                .filter( ({disabled}) => disabled !== true)
                .map(({ id, label, onClick }) => [id || label, onClick])
            );
            td.querySelectorAll(".action").forEach((anchor) => {
              anchor.addEventListener("click", (event) => {
                const id = anchor.getAttribute("id");
                handlerById.hasOwnProperty(id) && handlerById[id](
                  cellData,
                  row,
                  event.target
                );
              });
            });
          };
    return doAddTitleAttributes === undefined &&
      doAddCellClassName === undefined &&
      enableActions === undefined
      ? undefined
      : (td, ...rest) => {
          const cellData = table.getCellData(td);
          const row = table.getRowData(td);
          enableActions && enableActions(column, td, ...rest);
          doAddTitleAttributes && doAddTitleAttributes(td);
          doAddCellClassName &&
            doAddCellClassName(td, [
              cellData, row,
              column,
            ]);
          onCellRender(td, cellData, row);
        };
  }
  _columnsAsDataTable() {
    return this._columns.map((column, idx) => ({
      ...column.asDataTableDef(),
      targets: idx,
      createdCell: this._attrUpdatesForColumnCells(column, column.onCellRender),
    }));
  }
  _disableClick() {
    if (this._clickHandlerAssignation === null) {
      return false;
    }
    this._target
      .get(0)
      .removeEventListener("click", this._clickHandlerAssignation);
    this._clickHandlerAssignation = null;
    this._target.attr("click-handler-enabled", true);
    return true;
  }
  onClickHandler (event) {
    const { target } = event;
    if (target.tagName !== "TD" && target.closest("TD") === null) {
      return true; /* keeps going */
    }
    specificClickHandler(event);
    event.preventDefault();
  }
  _enableClick() {
    const specificClickHandler =
      this._htmlOnly === true
        ? this._getHandlerForHTMLClick()
        : this._getHandlerForDataTableClick();

    if(this._clickHandlerAssignation){
      this._target.get(0).removeEventListener("click", this._clickHandlerAssignation);
    }

    function onClickHandler(event) {
      const { target } = event;
      if (target.tagName !== "TD" && target.closest("TD") === null) {
        return true; /* keeps going */
      }
      specificClickHandler(event);
      event.preventDefault();
    }

    this._clickHandlerAssignation = onClickHandler;
    
    this._target
      .get(0)
      .addEventListener("click", onClickHandler);
    this._target.attr("click-handler-enabled", true);
  }
  reload() {
    this._table.ajax.reload();
    return this;
  }
  _getHandlerForDataTableClick() {
    const instance = this;
    const allColumns = this._columns;
    const columns = allColumns.filter(({ hidden }) => hidden !== true);

    return function dataTableClickHandler(event) {
      const { target } = event;
      const cell = target.tagName === "TD" ? target : target.closest("TD");
      const index = $(cell).index();
      const action = columns[index].onClick;
      if (typeof action !== "function") {
        return;
      }
      const tr = $(target).closest("tr");
      const rowIndex = tr.index();
      const rows = instance._target.DataTable().ajax.json().data;
      const row = rows[rowIndex];
      if (row === undefined) {
        console.warn("row not found for", $(this));
        return false;
      }
      const translateToColumnFormat = (value, index) => {
        const { repr } = allColumns[index];
        return typeof repr === "function" ? repr(value) : value;
      };
      const reprRow = row.map(translateToColumnFormat);
      const cellData = reprRow[index];
      action(cellData, reprRow, target);
      event.stopPropagation();
    };
  }
  _getHandlerForHTMLClick() {
    const table = this._table;
    const columns = this._columns.filter(({ hidden }) => hidden !== true);
    return function htmlClickHandler(event) {
      const index = $(this).index();
      const action = columns[index].onClick;
      if (action === undefined) {
        return;
      }
      const tr = $(this).closest("tr");
      const row = tr.data("value"); // returns undefined
      const cell = row[index];
      action(cell, row, event.target);
      event.stopPropagation();
    };
  }
  _applyFilters(data) {
    const allFilters = this._filters;
    if (allFilters === null || allFilters.length === 0) {
      return data;
    }
    const applyAllFilters = (data) =>
      allFilters.every((filters) => filters(data));
    return data.filter(applyAllFilters);
  }
  _insertIntoDataTable(rows, draw = true) {
    const data = this._applyFilters(this._parseRows(rows));

    const adition = this._table.rows.add(data);
    if (draw === true) {
      adition.draw();
    }
  }
  _isDatatable() {
    return (
      this._htmlOnly !== true && typeof this._target.DataTable == "function"
    );
  }
  _insertIntoHTML(rows) {
    const parsedRows = this._parseRows(rows);
    const target = this._table.find("tbody");
    parsedRows.forEach((row) => {
      const newRow = $(this._renderRowAsHTML(row));
      newRow.appendTo(target);
      newRow.data("value", row);
    });
  }
  _parseRows(source) {
    const parsedTabsToArray = (columns) => {
      return (line) => {
        const row = tabulatedToArray(line);
        return columns.map(({ idx, value }) =>
          value === undefined ? row[idx] : value(row[idx], row)
        );
      };
    };
    const parseByThisColumns = parsedTabsToArray(this._columns);
    return typeof source[0] == "string"
      ? source.map(parseByThisColumns)
      : source;
  }
  _renderCellAsHTML(
    { colClassName = "", cellClassName = "", title },
    idx,
    row
  ) {
    const cellValue = row[idx];
    const newCellClassName =
      typeof cellClassName === "function"
        ? cellClassName(row[idx], row, idx)
        : cellClassName;
    const tooltip =
      title !== undefined
        ? `data-toggle="tooltip" data-placement="top" title="${title}"`
        : "";
    return `
      <td class="${colClassName} cell ${newCellClassName}" ${tooltip} >${cellValue}</td>`;
  }
  _renderRowAsHTML(row) {
    const composeCells = (prev, column, idx) =>
      prev + this._renderCellAsHTML(column, idx, row);
    return `
      <tr>
        ${this._columns.reduce(composeCells, "")}
      </tr>`;
  }
  _renderDataTable(source) {
    const allColumns = this._columns;
    const visibleColumns = allColumns.filter(({ hidden }) => hidden !== true);
    const options = {
      columnDefs: this._columnsAsDataTable(),
      pageLength: this._pageLength,
      order: this._order,
      responsive: true,
      ajax: this._provider,
      serverSide: this._provider !== undefined,
      fnDrawCallback: function () {
        $(this._target).find('[data-toggle="tooltip"]').tooltip();
      },
      headerCallback: function(thead, data) {
        [].forEach.call(thead.children, (header, index) => {
          const {title, label} = visibleColumns[index];
          if (title !== undefined) {
            $(header)
              .html(`<span title="${title}">${label}</span>`)
              .find('span')
              //.tooltip();
          }
        });
      },
      createdRow: function (row) {
        $(row)
          .find("td.truncate")
          .each((index, truncated) => {
            $(truncated).attr("title", truncated.textContent);
          });
      },
    };
    this._table = this._target.DataTable(options);
    this._enableClick();
    $(this._target).css({ "min-width": "100%" });
  }
  _renderHeadersAsHTML() {
    return this._columns.reduce((prev, column) => {
      return `
        ${prev}
        <th class="${column.colClassName}">${column.label}</th>
     `;
    }, "");
  }
}

const renderIp = (addr, label, onClick = false) =>
  onClick === false
    ? `<span data-toggle="tooltip" data-placement="top"
          title="${label}">
          ${addr}
        </span>`
    : `<a href="#"
          id="addr"
          data-toggle="tooltip" data-placement="top"
          title="${label}">
          ${addr}
        </a>`;

const renderIpWithIcons = (addr, label, iconsRight = [], onClick = false) => `
  <div class="align-contents-middle">
    ${renderIp(addr, label, onClick)}
    ${iconsRight.reduce(
      (prev, current) => `
      ${prev}
      <a href="#" id="${current.id}"
        data-toggle="tooltip" data-placement="top"
        title="${current.title} graph">
        <i class="material-icons actions-icon-color">${current.icon}</i>
      </a>`,
      ""
    )}
  </div>
`;
const handleClickWithIcons = (onClick = () => {}, iconsRight = []) =>
  iconsRight.length === 0
    ? onClick
    : (data, row, trigger) => {
        data = typeof data === "string" ? data : itoa(data);
        const link = trigger.tagName === "A" ? trigger : trigger.closest("A");
        const activeIconId = (link !== null && link.getAttribute("id")) || null;
        const activeIcon = iconsRight.find(({ id }) => id === activeIconId);
        const action =
          (activeIcon !== undefined && activeIcon.action) || onClick;
        action(data, row, trigger);
      };

const renderActionButton = ({ id, label, icon, disabled=false }) => `
  <a id="${id || label}"
    class="action ${disabled ? 'disabled' : ''}"
    data-toggle="tooltip" data-placement="top"
    title="${label}">
    <i class="material-icons actions-icon-color">${icon}</i>
  </a>`;

const isLightColor = (color) => {
  const hex = color.replace("#", "");
  const c_r = parseInt(hex.substring(0, 0 + 2), 16);
  const c_g = parseInt(hex.substring(2, 2 + 2), 16);
  const c_b = parseInt(hex.substring(4, 4 + 2), 16);
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
  return brightness > 155;
};
  
  const getColor = (color) => {
    if (color === 'transparent') return "#555"

    return isLightColor(color) ? "black" : "white";
  }
 

Table.Column = {
  Text: ({
    idx = 0,
    label = "Not-defined",
    className = undefined,
    colClassName = undefined,
    cellClassName = undefined,
    onClick = undefined,
    title = undefined,
    expand = false,
    width = undefined,
    parse = undefined,
    icon = undefined,
    ...terms
  }) => ({
    idx,
    asDataTableDef: () => ({
      title: label,
      visible: true,
      className: colClassName,
      autoWidth: expand === true,
      width,
      render: (value, _, row) => {
        const iconToDisplay = typeof icon === 'function'?icon(row):icon;
        return `
          ${iconToDisplay?`<a href="#">
              <i class="material-icons">${iconToDisplay}</i>
              ${value}
            </a>`:value}
          `}
    }),
    asHTML: () => ({ content: label }),
    colClassName,
    cellClassName,
    label,
    onClick,
    title,
    parse: parse === undefined ? (data) => data : parse,
    value: (value, _row) => value,
    exportable: true,
    ...terms,
  }),
  TextColor: ({ value, label, colClassName, color, ...terms }) => ({
    ...Table.Column.Text({ value, label, colClassName, ...terms }),
    asDataTableDef: () => ({
      visible: true,
      title: label,
      className: colClassName,
      render: (value) => {
        return `<div class="color-label" style="background: ${color(value)}; color: ${getColor(color(value))}">
          ${value}
        </div>`;
      },
    }),
  }),
  Number: ({
    idx = 0,
    label = "Not-defined",
    precision = 0,
    colClassName = undefined,
    cellClassName = undefined,
    onClick = undefined,
    thousands = true,
    title = undefined,
    ...terms
  }) => ({
    idx,
    asDataTableDef: () => ({
      title: label,
      visible: true,
      className: colClassName,
      render: (value) => typeof value === 'string'
        ? value
        : expressNumber(value, precision, thousands),
    }),
    asHTML: () => ({ content: label }),
    cellClassName,
    colClassName,
    label,
    onClick,
    title,
    parse: (data) => (precision === 0 ? parseInt(data) : parseFloat(data)),
    value: thousands ? (value, row) => Number(value) : undefined,
    exportable: true,
    ...terms,
  }),
  NumberColor: ({ value, label, colClassName, color, ...rest }) => ({
    ...Table.Column.Number({ value, label, colClassName, ...rest }),
    asDataTableDef: () => ({
      visible: true,
      title: label,
      className: colClassName,
      render: (value) => {
        return `<div class="color-label-number" style="background: ${color(value)}; color: ${getColor(color(value))}">
          ${value}
        </div>`;
      },
    }),
  }),
  Value: (
    value,
    {
      label = "Not-defined",
      precision = 0,
      cellClassName = undefined,
      colClassName = undefined,
      onClick = undefined,
      title = undefined,
      actions = [],
      orderable = false,
      asDataTableDef = () => ({}),
      ...terms
    }
  ) => ({
    asDataTableDef: () => ({
      title: label,
      visible: true,
      className: colClassName,
      orderable,
      render: value,
      ...asDataTableDef(),
    }),
    asHTML: () => ({ content: label }),
    cellClassName,
    colClassName,
    label,
    onClick,
    title,
    parse: (data) => data,
    value: (data, type, row, meta) => value(data, row),
    actions,
    exportable: true,
    ...terms,
  }),
  Hidden: ({ idx = 0 }) => ({
    idx,
    parse: (value) => value,
    asDataTableDef: () => ({ visible: false, classname: "hidden" }),
    asHTML: () => ({ content: "" }),
    hidden: true,
  }),
  NumberOrNA: ({ value, percent = false, precision = 0, thousands = true, ...terms }) => ({
    ...Table.Column.Number({ value, precision, ...terms }),
    parse: (data) => {
      return data === "n/a"
        ? null
        : parseFloat(percent === true ? data.trim("%") : data);
    },
    asDataTableDef: () => ({
      ...Table.Column.Number({ value, ...terms }).asDataTableDef(),
      render: (data, type, row, meta) =>
        data === null
          ? "n/a"
          : percent === true
          ? `${data.toFixed(precision)}%`
          : expressNumber(data, precision, thousands),
    }),
  }),
  NumberOrYesNo: ({ value, percent = false, precision = 0, ...terms }) => ({
    ...Table.Column.Number({ value, precision, ...terms }),
    parse: (data) => {
      return data === "no"
        ? 'no'
        : data === 'yes'
        ? 'yes'
        : parseFloat(percent === true ? data.trim("%") : data);
    },
    asDataTableDef: () => ({
      ...Table.Column.Number({ value, ...terms }).asDataTableDef(),
      render: (data, type, row, meta) =>
        data === 'yes' || data === 'no'
          ? data
          : percent === true
          ? `${data.toFixed(precision)}%`
          : expressNumber(data, precision),
    }),
  }),
  HiddenNumberOrNA: (...args) => ({
    ...Table.Column.NumberOrNA(...args),
    asDataTableDef: () => ({ visible: false, classname: "hidden" }),
    asHTML: () => ({ content: "" }),
    hidden: true,
  }),
  Action: ({
    value,
    label,
    colClassName,
    cellClassName,
    idx = 0,
    icon,
    onClick = false,
    ...terms
  }) => ({
    idx,
    colClassName,
    cellClassName,
    asDataTableDef: () => ({
      visible: true,
      title: label,
      orderable: true,
      className: colClassName,
      render: (data, type, row, meta) =>
        `<div class="align-contents-middle">
          <a href="#" 
            data-toggle="tooltip" data-placement="top"
            title="${label}">
            <i class="material-icons actions-icon-color">${icon}</i>
          </a>
      </div>`,
    }),
    onClick,
    exportable: false,
  }),
  IPAddress: ({
    value,
    label,
    colClassName,
    iconsRight = [],
    onClick = false,
    ...terms
  }) => ({
    ...Table.Column.Number({ value, label, colClassName, ...terms }),
    asDataTableDef: () => ({
      visible: true,
      title: label,
      className: colClassName,
      render: (data, type, row, meta) =>
        renderIpWithIcons(itoa(data), label, iconsRight, onClick),
    }),
    onClick: handleClickWithIcons(onClick, iconsRight),
    parse: atoi,
    repr: (data) => (data === null ? null : itoa(data)),
  }),
  IPAddressOrNA: ({ value, label, ...terms }) => {
    const original = Table.Column.IPAddress({ value, label, ...terms });
    const originalDataTableDef = original.asDataTableDef();
    return {
      ...original,
      asDataTableDef: () => ({
        ...originalDataTableDef,
        render: (data, ...rest) =>
          data === null ? "--" : originalDataTableDef.render(data, ...rest),
      }),
      parse: (value) => (value === "n/a" ? null : atoi(value)),
    };
  },
  Time: ({
    value,
    label,
    colClassName,
    iconsRight = [],
    onClick = false,
    ...terms
  }) => ({
    ...Table.Column.Number({ value, label, colClassName, ...terms }),
    asDataTableDef: () => ({
      visible: true,
      title: label,
      className: colClassName,
      render: (data, type, row, meta) =>
        data === null ? "n/a" : intToDuration(data),
    }),
    onClick: handleClickWithIcons(onClick, iconsRight),
    parse: (data) => (data === "n/a" ? null : durationToInt(data)),
  }),
  TimeAgo: ({
    value,
    label,
    colClassName,
    iconsRight = [],
    onClick = false,
    ...terms
  }) => ({
    ...Table.Column.Time({ value, label, colClassName, ...terms }),
    asDataTableDef: () => ({
      visible: true,
      title: label,
      className: colClassName,
      render: (data, type, row, meta) =>
        data === null ? "ongoing" : intToDuration(data),
    }),
    parse: (data) => (data === "ongoing" ? null : durationToInt(data)),
  }),
  DateTimeOrNA: ({
    value,
    label,
    colClassName,
    iconsRight = [],
    onClick = false,
    ...terms  }) => ({
    ...Table.Column.Number({ value, label, colClassName, ...terms }),
    asDataTableDef: () => ({
      visible: true,
      title: label,
      className: colClassName,
      render: (data, type, row, meta) =>
        data === null
          ? "n/a"
          : `${data.toLocaleDateString()} ${data.toLocaleTimeString()}`,
    }),
    onClick: handleClickWithIcons(onClick, iconsRight),
    parse: (data) => (data === "n/a" ? null : new Date(`${data}`)),
    value: undefined,
  }),
  SpeedOrNo: ({ value, label, colClassName, onClick = false, ...terms }) => ({
    ...Table.Column.Number({ value, label, colClassName, ...terms }),
    asDataTableDef: () => ({
      visible: true,
      title: label,
      className: colClassName,
      render: (data, type, row, meta) =>
        data === null ? "no" : inDataSpeedUnits(data),
    }),
    parse: (data) => ((data === "no" || data === 'n/a') ? null : parseFloat(data)),
  }),
  Actions: ({ are = [], ...rest }) => ({
    ...Table.Column.Value(
      (data, type, row, meta) =>
        typeof are === "function"
          ? are(data, row).map(renderActionButton).join("")
          : are.map(renderActionButton).join(""),
      {
        ...rest,
        actions: are,
      }
    ),
    exportable: false,
  }),
  GroupedText: ({
    idx = 0,
    label,
    collapsed = true,
    ...terms
  }) => ({
    idx,
    ...Table.Column.Text({idx, label, ...terms}),
    ...terms,
    asDataTableDef: () => ({
      ...terms,
      title: label,
      render: (values, _, row) => `
        <span class="grouping ${collapsed && 'collapsed'}">
          <span>${values.length} entries</span>
          <i class="material-icons icon-collapse-row">expand_less</i>
        </span>
        <ul class="entries">
          ${values.map(value => `<li>${value}</li>`).join('')}
        </ul>
      `
    }),
    title: label,
    parse: (data) => data,
    group: true,
    value: (value, _row) => value,
    exportable: true,
    onCellRender: (cell) => $(cell).on('click', '.grouping', toggleGroupingCollapse)
  }),
};

const selectionField = (field, initial, label) => {
  const selected = new Set([initial]);  
  const icon = () =>
    selected.has(true)
      ? "check_box"
      : "check_box_outline_blank";
  const togglesRow = (value, row, target) => {
    selected.has(true) ? selected.clear() : selected.add(true);
    if (target && target.innerText) {
      target.innerText = icon();
    }
  };
  return (source, row) => [{
    id: `selection-${field}`,
    icon: icon(),
    label: label,
    onClick: togglesRow,
  }];
}

const findFieldIndexIn = (headers, field) => {
  const index = headers.indexOf(field);
  return index > -1 ? index : undefined;
};

const givenHeader = (header) => ({
  completeFieldAndIndex: ({ idx, field, ...column }) =>
    (idx === undefined && field === undefined) ||
    (idx !== undefined && field !== undefined)
      ? { idx, field, ...column } //Nothing to do
      : field === undefined
      ? { idx, field: header.at(idx), ...column }
      : { idx: findFieldIndexIn(headers, field), field, ...column }, //when idx is undefined
});

function downloadBlob(content, filename, contentType) {
  // Create a blob
  var blob = new Blob([content], { type: contentType });
  var url = URL.createObjectURL(blob);

  // Create a link to download it
  var pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', filename);
  pom.click();
}

const treatQuotes = value => value.replaceAll('"', '""');

const treatCommas = value =>
  value.includes(',') ? `"${value}"`: value;

const escapeValueSymbols = value =>
  treatCommas(treatQuotes(value))


const valueIsArray = value => Array.isArray(value);

const copyValues = (values, groupIndex) =>
  (groupValue) =>
    values.map( (value, index) =>
      index === groupIndex ? groupValue : value
    )

const expandGroup = values => {
  const groupIndex = values.findIndex(valueIsArray);
  return groupIndex === undefined  || groupIndex === -1
    ? [values]
    : values[groupIndex].map(copyValues(values, groupIndex))
}

const escapeStringValues = value =>
  typeof value === 'string' ? escapeValueSymbols(value) :
  value
    
const escapeSymbols = values => values.map(escapeStringValues);

const hasLabelIsExportable = ({label, exportable=true}) =>
  label !== undefined && exportable !== false;

const sourceOfExportable = columns => {
  const exportableColumnIndex = columns.map(hasLabelIsExportable);
  return ({__source__: source}) =>
    source.flatMap( (value, index) =>
      exportableColumnIndex[index] === true
        ? [value]
        : []
    )
}
const parsedTabsToArray = (columns) => {
  return (line) => {
    const row = tabulatedToArray(line);
    const source = [];
    const values = columns.map(({ idx, label, parse, exportable, ...column }) =>
      (label === undefined || exportable !== true ? true : source.push(row[idx]) ) &&
      typeof parse === "function" ? parse(row[idx], row) : row[idx]
    );
    values.__source__ = source;
    return values;
  };
};

const intoGroups = (columns) => {
  const parser = parsedTabsToArray(columns);
  const indexIsGroup = columns.map( column => column.group === true);
  const sameGroup = (one, another) =>
    indexIsGroup.every( (group, index) => 
      group === true || one[index] === another[index],
    )
  const includeInto = (one, another) => {
    const values = indexIsGroup.map( (group, index) => 
      group !== true ? one[index] :
      [...one[index], another[index]]
    )
    values.__source__ = indexIsGroup.map( (group, index) => 
      group !== true ? one.__source__[index] :
      [...one.__source__[index], ...another.__source__[index]]
    )
    return values
  }
  const group = (one, another) =>
    sameGroup(one, another)
      ? [ includeInto(one, another) ]
      : [ one, another ];

  return (result, line) => {
    const [last] = result.slice(-1)
    const parsed = parser(line)
    const groupsAsArrays = (value, index) => indexIsGroup[index] ? [value] : value;
    const values = parsed.map(groupsAsArrays);
    values.__source__ = parsed.__source__.map(groupsAsArrays);
    return last === undefined 
      ? [ values ]
      : [ ...result.slice(0, -1), ...group(last, values)] 
  }
}

export class Paginator {
  constructor(columns = [], rows = [], filters = [], header = []) {
    this._columns = columns.map(givenHeader(header).completeFieldAndIndex);
    this._columnsByIndex = Object.fromEntries(
      columns
        .filter(({ idx }) => idx !== undefined)
        .map((column) => [column.idx, column])
    );
    this._rows = this.parse(rows);
    this._filters = filters;
  }
  parse(source) {
    if (typeof source[0] !== "string"){
      return source;
    }
    const start = Date.now();
    const hasGroups = this._columns.some( ({group}) => group === true);
    const tabulatedToArray = function (row) {
      return row.trim().split(/ +/);
    };
    const parseByThisColumns = hasGroups === false
      ? source => source.map(parsedTabsToArray(this._columns))
      : source => source.reduce(intoGroups(this._columns), []);
    const result = parseByThisColumns(source)
    const end = Date.now();
    return result;
  }
  getColumns() {
    return this._columns;
  }
  getColumnValues(field) {
    const index = this._columns.findIndex(
      (column) => column.field === field || column.label === field
    );
    if (index === -1) {
      console.warn(`${field} not found as column`);
      return [];
    }
    return this._rows.map((values) => values.at(index));
  }
  exportAsCSV(filename='export.csv') {
    const head = this._columns
      .filter(hasLabelIsExportable)
      .map(column => column.label)
    const rows = (this._sortedRows || [] )
      .map(sourceOfExportable(this._columns))
      .flatMap(expandGroup)
      .map(
        row => (escapeSymbols(row) || []).join(',')
      );
    downloadBlob( `${head.join(',')}\n${rows.join('\n')}`, filename, 'text/csv;charset=utf-8;');
  }
  _applySorting(data, order) {
    const start = Date.now();
    order.forEach(({ column, dir }) => {
      const /*one*/ [greater, lesser] /*than another*/ =
          dir === "asc" ? [1, -1] : [-1, 1];
      const [anotherNullIsAlwaysLesser, oneNullIsAlwaysLesser] = [-1, 1];
      const position = column; //s[column].idx;
      const ruleNullToEnd = (one, another) =>
        one === null && another == null
          ? 0
          : one === null
          ? oneNullIsAlwaysLesser
          : anotherNullIsAlwaysLesser;
      const sortByColumnValue = (one, another) =>
        one === null || another == null
          ? ruleNullToEnd(one, another)
          : one > another
          ? greater
          : lesser;

      const byAffectedColumn = (one, another) =>
        sortByColumnValue(one[position], another[position]);
      data.sort(byAffectedColumn);
    });
    const end = Date.now();
    console.log(`${data.length} records took ${end - start}`);
    return data;
  }
  _applyFilters({ value, _regex }, source) {
    let data = [...source];
    
    if(value.length > 0){
      const pattern = new RegExp(value, 'i')
      const filterSearch = (row) =>
        JSON.stringify(row.__source__).match(pattern) !== null;
      data = data.filter(filterSearch);
    }

    const allFilters = this._filters;

    if(allFilters !==null && allFilters.length > 0){
      const applyAllFilters = (data) =>
        allFilters.every((filters) => filters(data));
      data = data.filter(applyAllFilters);
    }

    return data;
  }
  getTotal() {
    return this._applyFilters({value:[]}, this._rows).length;
  }
  request(params, callback = doesNothing, _settings) {
    const total = this.getTotal();
    const { length, start, search, order, draw } = params;
    const allRows = this._rows;
    const involvedRows = this._applyFilters(search, allRows);
    if (order.length > 0 && this._lastOrdering !== order) {
      this._applySorting(involvedRows, order);
      this._lastOrdering = order;
    }
    this._sortedRows = involvedRows;
    const page = involvedRows.slice(start, start + length);
    const result = {
      recordsTotal: total,
      recordsFiltered: total,
      data: page,
      draw: draw + 1,
    };
    callback(result);
  }
  injectColumn(column, value=null) {
    if (this._columns.find( current => column.label === current.label)){
      return this;
    }
    this._columns.push(column);
    this._rows.forEach( row => {
      row.push(value);
    })
    return this;
  }
}
Paginator.groupby = field => {
  let groups = {};
  return (record, index, all) => {
    const current = groups[record.name] || {...record, [field]: []}
    current[field] = [...current[field], record[field]];
    groups[record.name] = current;
    const ends = index === all.length - 1;
    return ends === true
      ? Object.entries(groups).map(([_name, values]) => values)
      : []
  } 
}

Table.Paginator = Paginator;

export default Table;
