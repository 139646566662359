/*globals DPI_STATS_TMOUT_MS*/
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Request from "common/Request";
import { parseLatenciesByRTT, expressLatencyTarget } from "../api";
import BarPlot from "common/graphs/BarPlot";
import { safeStr } from "common/api";

const keysAndValues = (data) =>
  data.map(({ bin, share }) => ({ key: bin, value: share }));

const PlotLayout = styled.div`
  width: 100%;
  height: 100%;
`;

const valueAsPercent =  d => `${(d.value*100).toFixed(0)}%`;

const _MARGIN = { top: 20, right: 1, bottom: 70, left: 50 };
const LatencyDistributionsResult = ({ latencies }) => (
  <PlotLayout>
    <BarPlot
      minHeight="50vh"
      className="bar-plot-graph"
      key={"label"}
      grid={true}
      items={keysAndValues(latencies)}
      margin={_MARGIN}
      valueLabel={valueAsPercent}
      allYValues={[0, 1]}
      showRoundCorners={true}
    />
  </PlotLayout>
);
//return <ChartJSBars {...splitLabelsAndData(latencies)} />;

const requestLatencyDistribution = ({ service, target, hours }) => {
  const setCategory = service === "all-average" ? "all" : `category ${safeStr(service)}`;
  return expressLatencyTarget(target).then((expressedTarget) =>
    ifCl
      .run(
        `show statistics dpi latency bins ${setCategory} \
            hours ${hours} bins 21 bin-size 5 minimum 5 \
            ${expressedTarget} all-internet`,
        { timeout: DPI_STATS_TMOUT_MS }
      )
      .catch((error) => console.error(error))
      .then(parseLatenciesByRTT)
      .then((latencies) => ({ latencies }))
  );
};

const LatencyDistribution = ({ service, target, hours }) => {
  const [request, setRequest] = useState(null);
  const doLoad = () => {
    setRequest(requestLatencyDistribution({ service, target, hours }));
  };
  useEffect(
    () => (service !== null && doLoad()) || undefined,
    [service, target, hours]
  );
  return request === null ? null : (
    <Request during={request}>
      {({ latencies = [] }) => (
        <LatencyDistributionsResult latencies={latencies} />
      )}
    </Request>
  );
};
export default LatencyDistribution;
