/*globals DPI_STATS_TMOUT_MS*/
import React, { useState, useEffect } from "react";
import Request from "common/Request";
import ChartJSBars from "common/graphs/ChartJSBars";
import { parseLatenciesByTime, expressLatencyTarget } from "../api";
import { retrieveRange, safeStr } from "common/api";
import styled from "styled-components";

const intervalForHours = (hours, interval = 1) =>
  hours === 24 * 7 ? 2 : hours === 24 * 30 ? 4 : interval;

const splitLabelsAndDataByTime = (latencies = []) => {
  if (latencies.length === 0) {
    return { labels: [], datasets: [] };
  }
  const bins = latencies[0].values.map((item) => item.bin);
  return {
    labels: latencies.map((item) => item.time.toLocaleString()),
    datasets: bins.map((bin, index) => ({
      label: bin,
      data: latencies.map((row) => row.values[index].share),
    })),
  };
};

const requestLatencyChronology = ({ service, target, hours }) => {
  const setCategory = service === "all-average" ? "all" : `category ${safeStr(service)}`;
  return expressLatencyTarget(target).then((expressedTarget) =>
    ifCl
      .run(
        `show statistics dpi latency time ${setCategory}\
           hours ${hours} interval ${intervalForHours(
          hours
        )} bins 11 bin-size 10 minimum 10 \
           ${expressedTarget} all-internet`,
        { timeout: DPI_STATS_TMOUT_MS }
      )
      .then(parseLatenciesByTime)
      .catch((error) => console.error(error))
  );
};
const provideRange = (range, hours) =>
  range !== undefined ? Promise.resolve(range) : retrieveRange(hours);

const requestLatencyChronologyAndRange = ({ range, hours, ...params }) =>
  Promise.all([
    requestLatencyChronology({ hours, ...params }),
    provideRange(range, hours),
  ]).then(([latencies, range]) => ({ latencies, range }));

const isInsideRange =
  ({ from, to }, field = "time") =>
  (item) =>
    from <= item[field] && item[field] <= to;

const LatencyChronologyLayout = styled.div`
  max-height: 50vh;
`

const LatencyChronologysResult = ({ latencies, range }) => {
  return (
    <LatencyChronologyLayout>
      <ChartJSBars
        /*xAxesAsTime={true}*/
        {...splitLabelsAndDataByTime(latencies.filter(isInsideRange(range)))}
      />
    </LatencyChronologyLayout>
  );
};

const LatencyChronology = ({ service, ...context }) => {
  const [request, setRequest] = useState(null);
  const doLoad = () => {
    request === null &&
      setRequest(requestLatencyChronologyAndRange({ service, ...context }));
  };
  useEffect(
    () => (service !== null && doLoad()) || undefined,
    [service, context]
  );
  return request === null ? null : (
    <Request during={request}>
      {({ latencies = [], range }) => (
        <LatencyChronologysResult
          range={range}
          {...context}
          latencies={latencies}
        />
      )}
    </Request>
  );
};

export default LatencyChronology;
