import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import ActionsContext from "common/ActionsContext";
import HelpModal from "./Help";
import FilteringControls from "./FilteringControls";
import Report from "./Report";
import Actions from "./Actions";

const ContextMenu = () => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      <a
        data-loading-effect="pulse"
        data-toggle="cardloading"
        title="Help"
        onClick={() => actions.send("open-help")}
      >
        <i className="material-icons">help_outline</i>
      </a>
      <a
        onClick={() => actions.send("do-load")}
        data-toggle="cardloading"
        data-loading-effect="pulse"
        title="Refresh"
      >
        <i className="material-icons">refresh</i>
      </a>
    </ul>
  );
};

const HeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  & .title {
    text-transform: uppercase;
  }
  & .context-menu {
    display: flex;
    flex-direction: row;
  }
`;

const SwitchInputDiv = styled.div`
  width: 100%;
  padding: 10px;
  margin: 0 0 20px;
  border-radius: 5px;
  border: 1px solid #bcbcbc;

  span {
    font-weight: 100;
    font-size: 18px;
  }
`;


const SwitchInputContainer = styled.div`
  width: 100%;
  padding: 0 2em;
`;

export const applyParams = (child, params = {}) =>
  React.isValidElement(child) ? React.cloneElement(child, params) : child;

const SubscriberGroups = ({ returnView, ipAddress, addr, subsId, dpiEnabled, direction, timePeriod, metric}) => {
  const actions = useContext(ActionsContext);
  useEffect(() => () => actions.clear(), []);

  const defaults = {};
  if(direction){
    defaults.direction = direction;
  }

  if(timePeriod){
    defaults.timePeriod = timePeriod;
  }

  if(metric){
    defaults.metric = metric;
  }

  return (
    <div className="viewStatusSubscriberGroups col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="card">
        <HeaderDiv className="header block-header">
          <h2 className="title">Subscriber Groups</h2>
          <ContextMenu />
        </HeaderDiv>
        <div id="subsGroupsReportContainer" className="body flex-panel-column">
          <Actions />
          <FilteringControls ipAddress={ipAddress} defaults={defaults} className="subsGroups-controls">
            {(settings) => (
              <Report {...settings} />)}
          </FilteringControls>
        </div>
        <HelpModal />
        {returnView !== undefined && returnView !== null ? (
          <div className="control modal-footer">
            <button
              type="button"
              className="btn btn-link waves-effect"
              onClick={() => {
                if (returnView === "viewSubsDetail" ){
                  globalNavigate(returnView, {subsId, addr, dpiEnabled}, false)
                } else {
                  globalNavigate(returnView || "viewStatusSubscriberGroups")
                }
              }
               
              }
            >
              Return
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SubscriberGroups;
