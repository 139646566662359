export const hoursScopeChoices = [
    { value: 24, label: "1 Day" },
    { value: 24 * 7, label: "1 Week" },
    { value: 24 * 30, label: "1 Month" },
  ];

  export const categoryChoices = [
    { value: 5, label: "5 Cats." },
    { value: 10, label: "10 Cats." },
    { value: 15, label: "15 Cats." },
    { value: 20, label: "20 Cats." },
    { value: 30, label: "30 Cats." },
  ];

  export const PERIOD_TO_HOURS = {
    Day: 24,
    Week: 24 * 7,
    Month: 24 * 30,
  };

  export const PERIOD_TO_LINES = {
    Day: 288,
    Week: 2016,
    Month: 8640,
  };

  export const periodScopeChoices = [
    { value: "Day", label: "1 Day" },
    { value: "Week", label: "1 Week" },
    { value: "Month", label: "1 Month" },
  ];