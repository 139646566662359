import React, { useState, useContext, useEffect, useRef } from "react";
import Modal from "common/Modal.jsx";
import ActionsContext from "common/ActionsContext";
import NumberInput from "common/NumberInput";

const doesNothing = () => {};

const UpdateShaping = ({
  others = [],
  doSave = doesNothing,
  doValidate = doesNothing,
  label = "",
}) => {
  const actions = useContext(ActionsContext);
  const [rateUpdate, setShapingUpdate] = useState(false);
  const showShapingUpdate = () => setShapingUpdate(true);
  const hideShapingUpdate = () => setShapingUpdate(false);
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    actions.recv(
      "edit-shaping-value",
      (settings) => setSettings(settings) || showShapingUpdate()
    );
  }, []);

  return (
    <React.Fragment>
      {rateUpdate === true ? (
        <UpdateShapingModal
          active={rateUpdate}
          others={others}
          doSave={doSave}
          doValidate={doValidate}
          onClose={hideShapingUpdate}
          label={label}
          {...settings}
        />
      ) : null}
    </React.Fragment>
  );
};

const getFormData = (form) => {
  const data = new FormData(form);
  return Object.fromEntries(data.entries());
};

const UpdateShapingModal = ({
  doValidate = doesNothing,
  doSave = doesNothing,
  onClose = doesNothing,
  field,
  initial,
  subscriberGroup
}) => {
  const form = useRef(null);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(true);
  const doClose = () => setOpen(false);

  if (open === false) {
    return null;
  }

  const handleChange = () => {
    try {
      setError(null);
      const currently = getFormData(form.current);
      doValidate(currently);
    } catch (error) {
      setError(error);
    }
  };

  const doApply = () => {
    Promise.resolve(getFormData(form.current))
      .then(doSave)
      .then(doClose)
      .catch(setError);
  };

  return (
    <Modal
      title={`Configure subscriber group ${field} rate limit`}
      superIcon="edit"
      large={false}
      content={() => (
        <form ref={form}>
          <input type="hidden" value={field} name="field" />
          <input type="hidden" value={subscriberGroup} name="subscriberGroup" />
          <NumberInput
            name="value"
            defaultValue={initial}
            onChange={handleChange}
            onEnter={doApply}
            label={`Mbps (leave blank to have no limit):`}
            precision={3}
            hideArrows={true}
          />
        </form>
      )}
      applyLabel="OK"
      onApply={doApply}
      applyDisabled={error !== null}
      closeLabel="CANCEL"
      onClose={onClose}
      footerMessage={
        (error !== null && (
          <span className="modal-err-msg color-red align-left">{error}</span>
        )) ||
        null
      }
    />
  );
};
export default UpdateShaping;
