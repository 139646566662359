/* global nv, NO_DATA_AVAILABLE*/
import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import * as d3 from "d3";
import colors from "./colors";
import colorsDark from "./colorsDark";
import { translateCoordsOver } from "./NVLineChart";

const intervals = (from=0, to=1500, step=50) =>
  Array(Math.round((to - from )/ step)).fill(0).map( (_, i) => [from + (i * step) + 1, from + ( (i + 1) * step), step]);

const sidebar = 300

const produceLabelsEstimations = (labelsCount, start, end, columnWidth) => {
  const result = `
    @media only screen and (min-width: ${start}px) and (max-width: ${end}px) {
      --labels-count: ${labelsCount};
      --columns: calc( ${end - sidebar} / ${columnWidth});
      --rows: calc(var(--labels-count) / var(--columns))
      --labels-height: calc(var(--rows) * 30px);
      min-height: calc(var(--labels-height) + var(--graph-min-height)) !important;
    }
  `
  return result
}

const WrapperDIV = styled.div`
  position: relative;
  height: 100%;
  & > svg {
    width: 100%;
    height: 100%;
    position: absolute;
    .nv-background rect {
      stroke: var(--chart-inner-border-color, #000);
      stroke-opacity: 0.75;
    }
    .nvd3 .nv-axis path.domain {
      stroke-opacity: 0;
    }
    text.threshold-label {
      text-anchor: end;
      dominant-baseline: text-after-edge;
    }
    --graph-min-height: ${(props) => props.minHeight || "6cm"};
  }
`;

const extendDomain = ([min, max], { topGap, bottomGap, bottomValue = 0 }) => [
  min < bottomValue ? min - min * bottomGap : bottomValue,
  max + max * topGap,
];

const expandLinearScale = (scale, params) => {
  const result = scale.copy();
  const originalDomain = result.domain.bind(result);
  result.domain = (...args) =>
    args.length === 0
      ? originalDomain()
      : originalDomain(extendDomain(...args, params));
  return result;
};

export const mayExpandLinearScale = (scale, params) =>
  params.bottomGap === undefined && params.topGap === undefined
    ? scale
    : expandLinearScale(scale, params);

const endOfChunkOrList = (index, chunkSize, total) =>
  index === total - 1 || index % chunkSize === 0;

const TIME_TOLERANCE = 20 * 60 * 1000 + 1;
let bestOfBest = 0;

const groupsMax = (chunkSize, name, xField = "time") => {
  let best = null;
  let lastTime = null;
  const mind = ([time, value], drop) => {
    if (lastTime !== null && time - lastTime > TIME_TOLERANCE) {
      // Data received is a padding from the time range, ends continuity.
      best = value;
      lastTime = time;
      return [
        [time, value],
      ];
    }

    best = value > best ? value : best;
    bestOfBest = value > bestOfBest ? value : bestOfBest;
    const result = drop ? [[time, best]] : [];
    best = drop ? 0 : best;
    lastTime = time;
    return result;
  };
  const reduce = (items) =>
    items.flatMap((item, index, { length: total }, itemIndex) =>
      mind(
        [item[xField].getTime(), item[name]],
        endOfChunkOrList(index, chunkSize, total),
        itemIndex
      )
    );
  return { reduce };
};
const onlyThoseWithNonZeroValues = ({ values }) =>
  values.length > 1 && values.some(([_time, value]) => value > 0);

const intoTimeValueTuples = (items, name, xField = "time", defaults = 0) =>
  items.map((item) => [item[xField].getTime(), item[name] || defaults]);

export const splitByFields = ([xField, ...fields], items, ratio, skipVoid) => {
  const colorsSet = login.isTheme("light") ? colors : colorsDark;

  const newFields = fields.map(({ name, label, color }, index) => ({
    key: label,
    color: color || colorsSet[index],
    ratio,
    values:
      ratio > 1
        ? groupsMax(ratio, name, xField).reduce(items)
        : intoTimeValueTuples(items, name, xField),
  }));

  if (!skipVoid) {
    return newFields;
  }

  return newFields.filter(onlyThoseWithNonZeroValues);
};

export const calcItemsRatio = (count, canvas) => {
  if (canvas === null) {
    return 1;
  }
  const { width } = canvas.getBoundingClientRect();
  return (count > width && Math.floor(count / (width * 0.4))) || 1;
};

const defaultTimeFormat = d3.timeFormat("%Y-%m-%d %H:%M");

export const formats = {
  noDecimals: d3.format(".0f"),
  oneDecimal: d3.format(".1f"),
  twoDecimals: d3.format(".2f"),
  threeDecimals: d3.format(".3f"),
};

export const defaultYAxisFormat = (d) =>
  d === 0 || d >= 10.0
    ? formats.noDecimals(d)
    : d >= 1.0
    ? formats.oneDecimal(d)
    : formats.twoDecimals(d);

const LoadingOverlayWrapper = styled.div`
  background: white;
  color: rgb(85, 85, 85);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  /* And then, position it at will */
  top: 0;
  left: 0;
`;

export const LoadingOverlay = ({ loading = false }) => {
  useEffect(() => {
    console.log("LoadingOverlay", loading);
  }, [loading]);
  return loading !== true ? null : (
    <LoadingOverlayWrapper>Loading</LoadingOverlayWrapper>
  );
};
const NVStackedAreaChart = ({
  margin = { top: 90, right: 50, bottom: 30, left: 70 },
  className,
  items,
  xField,
  fields,
  thresholds = [],
  tooltipContentGenerator = undefined,
  xAxisFormat = defaultTimeFormat,
  yAxisUnits = "",
  yAxisFormat = defaultYAxisFormat,
  yAxisTopGap = undefined,
  yTooltipFormat = null,
  onRenderFinished = () => {
    return;
  },
  itemsRatio = undefined,
  xAxisTicksNumber = undefined,
  onSeriesInspect = undefined,
  durationAnimation = undefined,
  showLegend = true,
  tooltipEnabled = true,
  fixedYRange,
  skipVoid = true,
}) => {
  const colorsSet = login.isTheme("light") ? colors : colorsDark;
  const [wrapper, canvas] = [useRef(null), useRef(null)];
  const [legendsHeight, setLegendsHeight] = useState(0);  
  const isLoading = useRef(true);
  let resizeObserver = null;
  let chart = null;
  let _initialDimmensions = null;

  const render = (target) => {
    isLoading.current = true;
    nv.addGraph(function () {
      const start = new Date().getTime();
      if (chart === null) {
        chart = nv.models
          .stackedAreaChart()
          .margin(margin)
          .x((d) => d[0])
          .y((d) => d[1])
          .useInteractiveGuideline(true) // This affects tooltip behavior
          .showControls(false)
          .noData(NO_DATA_AVAILABLE)
          .duration(durationAnimation)
          .yScale(
            mayExpandLinearScale(d3.scaleLinear(), {
              topGap: yAxisTopGap,
            })
          )
          .xScale(d3.scaleTime())
          .showLegend(showLegend)
          .clipEdge(true);
          chart.interactiveLayer.tooltip.enabled(tooltipEnabled);
        if (tooltipEnabled) { 
          chart.interactiveLayer.tooltip.hideDelay(0);
          chart.interactiveLayer.tooltip.gravity("w"); // 's', 'n', 'w', 'e'
          if (typeof tooltipContentGenerator === "function") {
            chart.interactiveLayer.tooltip.contentGenerator(
              tooltipContentGenerator
            );
          } else if (yTooltipFormat !== null) {
            chart.interactiveLayer.tooltip.valueFormatter(yTooltipFormat);
          }
        }
      
        chart.xAxis.tickFormat(xAxisFormat).ticks(xAxisTicksNumber);
             //.scale(d3.scale.linear().domain([0, yMax]))
        chart.yAxis
        .axisLabel(yAxisUnits)
        .showMaxMin(false)
        .tickFormat(yAxisFormat);
     if (resizeObserver === null) {
          const updateChart = () =>
            typeof chart.update === "function" &&
            setTimeout(updateLegendsMargin, 50);
          resizeObserver = new ResizeObserver(updateChart);
          resizeObserver.observe(target);
        }
      }
      const ratio = itemsRatio || calcItemsRatio(items.length, canvas.current);
      const datum = splitByFields([xField, ...fields], items, ratio, skipVoid);
      const end = new Date().getTime();
      console.log(
        "took ",
        (end - start) / 1000,
        datum,
        items.length,
        bestOfBest
      );
      bestOfBest = 0;
      d3.select(canvas.current).datum(datum);
      const [_yDomainMin, yDomainMax] = chart.yAxis.domain();
      if (fixedYRange) {
        chart.forceY(fixedYRange);
      } else {
        chart.forceY([
          0,
          d3.max([yDomainMax, ...thresholds.map((t) => t.value)]),
        ]);
      }
      d3.select(canvas.current).call(chart);
      d3.select(canvas.current).select(".nv-background rect").attr("rx", 9)
      const main = d3.select(canvas.current);
      const linesArea = main.select(".nvd3.nv-stackedarea");
      const thresholdsGroup = linesArea.select(".thresholds").node()
        ? linesArea.select(".thresholds")
        : linesArea.append("g").attr("class", "thresholds");
      const handleThresholds = (target) => {
        const groups = target
          .selectAll(".threshold")
          .data(thresholds, (d) => d.label);
        const newGroups = groups.enter();
        newGroups.append("g").attr("class", "threshold");
        newGroups
          .select(".threshold")
          .append("line")
          .attr("class", "threshold-line");
        newGroups
          .select(".threshold")
          .append("text")
          .attr("class", "threshold-label");
        groups
          .select("line")
          .attr("x1", chart.xAxis.range()[0])
          .attr("x2", chart.xAxis.range()[1])
          .attr("y1", ({ value }) => chart.yAxis.scale()(value))
          .attr("y2", ({ value }) => chart.yAxis.scale()(value))
          .style("stroke", (d) => d.color || "red");
        groups
          .select("text")
          .text((d) => d.label)
          .attr("x", chart.xAxis.range()[1])
          .attr("y", ({ value }) => chart.yAxis.scale()(value))
          .attr("dx", "-.5em")
          .attr("dy", "-.5em")
          .style("fill", (d) => d.color || "red");
        groups.exit().remove();
      };

      thresholdsGroup.call(handleThresholds);
      const legends = d3.select(canvas.current).select(".nv-legendWrap").node();
      const updateLegendsMargin = () => {
        if (legends === null) {
          return;
        }
        const legendDimmensions = legends.getBBox();
        if (legendDimmensions.height === 0) {
          // grapg hidden
          return;
        }
        setLegendsHeight(legendDimmensions.height);
        if (legendDimmensions.top !== margin.top) {
          chart.margin({ ...margin, top: 20 + legendDimmensions.height });
        }
        chart.update();
        thresholdsGroup.call(handleThresholds);
        _initialDimmensions =
          _initialDimmensions ||
          canvas.current.querySelector(".nv-focus").getBBox();
        const requiredHeight =
          _initialDimmensions.height + legendDimmensions.height;
        if (canvas.current) {
          const available = canvas.current.getBoundingClientRect().height;
          Math.abs(available - requiredHeight) > 90
            ? null /*glitch*/
            : Math.abs(available - requiredHeight) > 10
            ? canvas.current.setAttribute("height", requiredHeight + 20)
            : null;
        }
      };
      chart.dispatch.on("stateChange", () => {
          setTimeout(() => thresholdsGroup.call(handleThresholds), 400);
      });

      if (onRenderFinished) {
        onRenderFinished();
      }
      chart.dispatch.on("renderEnd", function () {
        isLoading.current = false;
      });
      if (onSeriesInspect !== undefined) {
        const handleInspect = (event, d) => {
          event.preventDefault();
           // react on right-clicking
          onSeriesInspect({...d, name: d.key});
          if (tooltipEnabled) {
            setTimeout(() => chart.interactiveLayer.tooltip.hidden(true), 100);
          }
        }
        d3.select(canvas.current).select(".nv-legendWrap").selectAll("text")
          .on("contextmenu", handleInspect);
        linesArea.on("contextmenu", (event) => {
          if (event.target.tagName !== 'path') {
            return;
          }
          const [data] = d3.select(event.target).data();
          return handleInspect(event, data);
        });
      }

      return chart;
    });

    return () => {
      resizeObserver && resizeObserver.unobserve(target);
    };
  };
  useEffect(() => {
    const target = wrapper.current;
    return items ? render(target) : () => {};
  }, [items]);

  useEffect(() => {
    return () => {
      console.log("nv-lineChart destroy");
      if (chart !== null && tooltipEnabled) {
        chart.interactiveLayer.tooltip.hidden(true);
        // chart.destroy();
      }
    };
  }, []);

  return (
    <WrapperDIV ref={wrapper} className={className} legendsHeight={legendsHeight || 0}>
      <svg ref={canvas}></svg>
      <LoadingOverlay isLoading={isLoading.current} />
    </WrapperDIV>
  );
};

export default NVStackedAreaChart;

const CONST = {
  HDR_LINE_INDEX: 0,
  FIRST_DPI_DATA_LINE: 1,
  DPI_CATEGORY_OFFSET: 3,
  UNITS: "Mbps",
  TOOLTIP_UNITS: "%",
  NON_APPLICABLE_VALUE: "n/a",
  ANIMATION_DURATION: 100,
};

export const tooltipContentGeneratorForUnits = (
  unit = CONST.UNITS,
  format = formats.twoDecimals,
  showZeroValue = true,
  showPercentage = true,
) =>
  function (d) {
    $(".nvtooltip").each(function () {
      this.style.opacity = 0;
    });
    
    const ROW_MAX = 8;
    const date = d3.timeFormat("%Y-%m-%d  %H:%M:%S")(new Date(d.value));
    const dateHTML = `<b style="white-space: pre;">${date}</b>`;

    const seriesTotalRest = d.series.reduce(
      (acc, value) => {
        const { key } = value;
        if (key.toLowerCase() === "rest") {
          acc.rest = value;
        } else if (key.toLowerCase() === "total") {
          acc.total = value;
        } else {
          const { color, value: valuePoint, key } = value;
          if (
            !isNaN(valuePoint) &&
            valuePoint !== null 
          ) {
            if(valuePoint !== 0 || showZeroValue){
              acc.series.push(value);
            }
          }
        }
        return acc;
      },
      {
        rest: {},
        total: {},
        series: [],
      }
    );

    const total = seriesTotalRest.total.value;

    if (total <= 0) {
      return dateHTML;
    }

    const hasManySeries =
      seriesTotalRest.series.length >= ROW_MAX ? " large" : "";
    const seriesHTML = seriesTotalRest.series.reduce((acc, value) => {
      const { color, value: valuePoint, key } = value;
      const html = `<div class="series-tooltip-stacked-area">
                        <div class="series-tooltip-stacked-area-name-color">
                          <div class="series-tooltip-stacked-area-color" style="background-color:${color};"></div>
                          <div class="series-tooltip-stacked-area-name">${key}</div>
                        </div>
                        <div class="series-tooltip-stacked-area-value">${showPercentage ? d3.format(
                          total === undefined ? '.0f' : '.2f'
                        )(
                          total === undefined ? 100 :
                          total <= 0 ? 0 : (valuePoint * 100) / total): ''}${
                            showPercentage ? CONST.TOOLTIP_UNITS: ''
      } ${format(valuePoint)} ${unit}</div>
                      </div>`;
      acc.push(html);
      return acc;
    }, []);

    const { value: restValue, color: restColor } = seriesTotalRest.rest;
    const restHTML =
      !isNaN(restValue) && restValue !== null
        ? `<div class="series-tooltip-stacked-area">
                        <div class="series-tooltip-stacked-area-name-color">
                          <div class="series-tooltip-stacked-area-color" style="background-color:${restColor};"></div>
                          <div class="series-tooltip-stacked-area-name">REST</div>
                        </div>
                        <div class="series-tooltip-stacked-area-value">${showPercentage ? d3.format(
                          ".2f"
                        )(total <= 0 ? 0 : (restValue * 100) / total): ''}${
                          showPercentage ? CONST.TOOLTIP_UNITS: ''
          } ${format(restValue)} ${unit}</div>
                      </div>`
        : "";

    const { value: totalValue } = seriesTotalRest.total;
    const totalHTML =
      totalValue === undefined
        ? ""
        : `<div class="series-tooltip-stacked-area-wrapper${hasManySeries}">
                          <div class="series-tooltip-stacked-area total${hasManySeries}">
                              <div class="series-tooltip-stacked-area-name-color">
                                <div class="series-tooltip-stacked-area-color" style="background-color:transparent; border:none"></div>
                                <div class="series-tooltip-stacked-area-name total">TOTAL</div>
                              </div>
                              <div class="series-tooltip-stacked-area-value">${format(
                                totalValue
                              )} ${unit}</div>
                            </div>
                        </div>`;

    const showPercentageClass = showPercentage ? "" : " noPercentage";
    const legendsHTML = `<div class="tooltip-stacked-area-wrapper${hasManySeries}${showPercentageClass}">${dateHTML}<div style="height: ${hasManySeries ? 10 + (seriesHTML.length/2*20) : 10 + (seriesHTML.length*20)}px" class="series-tooltip-stacked-area-wrapper series${hasManySeries}">${restHTML}${seriesHTML.join(
      ""
    )}</div>${totalHTML}</div>`;

    return legendsHTML;
  };

export const tooltipContentGenerator = tooltipContentGeneratorForUnits("Mbps");
/*
      

        if (limits.isZero) {
          console.log('FORCING Y-SCALE');
          chart.forceY([0,1]);
        } else {
          chart.forceY([0, null]);
        }

        if (!limits.isZero && that.threshold &&
          that.threshold < (limits.max * ULTP) && that.threshold > (limits.max * 0.90)) {
          yMax = limits.max * ULTP;
        } else {
          yMax = limits.max;
        }

        // NVD3 workaround
        if (that.threshold) {
          chart.yDomain([0, yMax]);
        }

        chart.yAxis
          //.scale(d3.scale.linear().domain([0, yMax]))
          .axisLabel(that.units)
          .ticks(6)
          .showMaxMin(false)
          .tickFormat()
          ;

        d3.select(svgSelector)
          .datum(that.data)
          .call(chart)
          ;

        d3.select(that.jqSelector).select(".nv-lineChart")
          .on("mouseleave", function() {
            that.tooltipMgr.onMouseLeave(chart);
          })
          ;

        nv.utils.windowResize(chart.update);

*/
