import React, { useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import Canvas from "common/graphs/Canvas";
import Axis from "common/graphs/Axis";
import BoxPlot, { DEFAULT_MARGIN } from "common/graphs/BoxPlot";
import { NoEnoughtData } from "./common";
import GroupDetails from "./GroupDetails";
import * as d3 from "d3";
import ActionsContext from "common/ActionsContext";

export const BoxPlotWrapper = styled.div`
  --is-highest: #3d85c6;
  --is-higher: #6aa84f;
  --is-lower: #e69138;
  --is-lowest: #cc0000;
  --warning: #cc0000;
  --warning-shade: #cc000015;
  &.invert-colors {
    --is-lowest: #3d85c6;
    --is-lower: #6aa84f;
    --is-higher: #e69138;
    --is-highest: #cc0000;
  }
  svg {
    .plot {
      .min,
      .min-median,
      .max,
      .median-max {
        stroke: currentColor;
      }
      .q1-median,
      .median-q3 {
        color: white;
        fill: currentColor;
      }
    }
    .warning-zone {
      .area,
      .value {
        fill: none;
      }
      .divisor {
        stroke: none;
      }
    }
  }
  &.colorize-warnings {
    svg {
      .plot {
        .warning-zone {
          .divisor {
            stroke: var(--warning);
          }
          .area {
            fill: var(--warning-shade);
          }
          .value {
            fill: var(--warning);
          }
        }
      }
    }
  }
  &.colorize-quartiles {
    svg {
      .plot {
        .min,
        .min-median {
          color: var(--is-lowest);
          fill: var(--is-lowest);
        }
        .q1-median {
          color: var(--is-lower);
          fill: var(--is-lower);
        }
        .median-q3 {
          color: var(--is-higher);
          fill: var(--is-higher);
        }
        .max,
        .median-max {
          color: var(--is-highest);
          fill: var(--is-highest);
        }
      }
    }
  }
`;

const notFarThreshold = ([min, max, threshold]) => {
  const main = max - min;
  return min - threshold > main || threshold - max > main
    ? [min, max]
    : d3.extent([min, max, threshold]);
};

const addGap = (coefficient, [min, max]) => {
  const gap = ((max - min) * coefficient) / 2;
  return [min - gap, max + gap];
};

const areQuartilesEmpty = (data) =>
  data === undefined || data === null || Object.keys(data).length < 5;

const doesNothing = () => {};

const firstPart = (units) => {
  const [first, ...rest] = units.split(" ");
  return first;
};

const makeThresholdLabelWhen = (inverted, units) => (value) =>
  `Warn if ${inverted ? ">" : "<"} ${value}${firstPart(units)}`;

const MARGIN= { top: 10, right: 10, bottom: 10, left: 40}

const BoxPlotGroup = ({
  field,
  label,
  legend,
  units,
  format,
  quartiles,
  threshold,
  inverted = false,
  colorizeQuartiles = false,
  tooltip,
}) => {
  return <BoxPlotLayout>
    <LegendDiv className="box-plot-legend">
      <h5
        {...(tooltip === undefined
          ? {}
          : { title: tooltip, "data-toggle": "tooltip" })}
      >
        {label}
      </h5>
      <span>{legend}</span>
    </LegendDiv>
    {(areQuartilesEmpty(quartiles) && false) ? (
      <NoEnoughtData />
    ) : (
      <GroupDetails
        {...{ field, quartiles, threshold: threshold.value }}
        units={units}
        format={format}
        inverted={inverted}
        thresholdLabel={
          field === "MAX-Mbps"
            ? (value) => `Warn if < ${value}% of limit`
            : makeThresholdLabelWhen(inverted, units)
        }
        colorizeQuartiles={colorizeQuartiles}
      >
        {field !== "MAX-Mbps" ? (
          <BoxPlotGraph
            field={field}
            data={{ ...quartiles, inverted, threshold: threshold.value }}
            units={units}
            format={format}
            colorizeQuartiles={colorizeQuartiles}
            margin={MARGIN}
          />
        ) : (
          <BoxPlotGraph
            field={field}
            data={{
              ...quartiles,
              threshold: threshold.calculated ? threshold.value : null,
            }}
            units={units}
            format={format}
            inverted={inverted}
            colorizeQuartiles={colorizeQuartiles}
            margin={MARGIN}
          />
        )}
      </GroupDetails>
    )}
  </BoxPlotLayout>
};

const BoxPlotGraph = ({
  field = undefined,
  data = {},
  margin = DEFAULT_MARGIN,
  colorizeQuartiles = false,
  units = undefined,
  format = undefined,
  yGap = 0.1 /*10%*/,
}) => {
  const actions = useContext(ActionsContext);
  const plotRef = useRef(null);
  const handleResetZoom = ({ target }) => {
    if (target !== field) {
      return;
    }
    console.log(plotRef.current);
    plotRef.current && plotRef.current.dispatch("reset-zoom");
  };
  useEffect(() => actions.recv("reset-zoom", handleResetZoom));
  return (
    <BoxPlotWrapper
      className={`group-boxplot ${
        data.inverted === true ? "invert-colors" : ""
      } ${colorizeQuartiles ? "colorize-quartiles" : "colorize-warnings"}`}
    >
      <Canvas>
        <Axis
          margin={margin}
          data={data}
          are={[
            {
              name: "x",
              type: "band",
              bandWidth: "30px",
              along: "width",
              value: (d) => d,
              values: [1],
            },
            {
              name: "y",
              along: "height",
              values: addGap(
                yGap,
                notFarThreshold([data.min, data.max, data.threshold])
              ),
            },
          ]}
        >
          <BoxPlot
            data={data}
            units={units}
            format={format}
            plotRef={plotRef}
          />
        </Axis>
      </Canvas>
    </BoxPlotWrapper>
  );
};

const LegendDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 2.4em;
  h5 {
    margin-bottom: 0;
    text-align: left !important;
  }
  padding-left: 15px;
`;

const BoxPlotLayout = styled.div`
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  margin-top: 1.5em;
  position: relative;
  min-width: 5cm;
  h5 {
    flex: 0 0 2rem;
    text-align: center;
  }
  .group-boxplot {
    flex: 1 1 100%;
  }
  a {
    cursor: pointer;
  }
  i {
    font-size: 20px;
    color: #999;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    position: absolute;
    top: -20px;
  }
  }
`;

export default BoxPlotGroup;
