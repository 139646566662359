import React, { useState, useContext, useEffect } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";
import styled from "styled-components";

const ListWithIcons = styled.ul`
  & li span,
  i.material-icons {
    line-height: 24px;
    vertical-align: middle;
  }
  i.material-icons {
    margin-right: 0.2em;
  }
`;

const SubscriberDetailHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  useEffect(() => {
    return actions.recv("open-help", function () {
      setOpen(true);
    });
  }, []);

  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }
  return (
    <Modal
      title="Subscriber Groups"
      onClose={doClose}
      content={() => (
        <>
          <p>
            This page shows a table with data for all subscribers groups. It also dhows a graph of one of the variables over time, for a maximum of 30 subscriber groups. It is possible to select the variable to plot and the time period.
          </p>
          <p>
            Click on the name to navigate to the subscriber group dashboard.
          </p>
          <p>The subscriber group data we display can be filtered by:</p>
          <ListWithIcons>
            <li>
              <span>
                <i className="material-icons">import_export</i>
                <b>Direction</b>: Show downlink or uplink metrics.
              </span>
            </li>
            <li>
              <span>
                <i className="material-icons">devices</i>
                <b>IP Address or Subscriber-ID</b>: Only the subscriber groups containing the IP address or subscriber-ID entered will be shown.
              </span>
            </li>
          </ListWithIcons>
          <p>Press the <b>Add Subscriber Group...</b> option to add a new subscriber group.</p>
          <p>Click on the edit icon to see and edit the subscribers assigned to the group.</p>
          <p>Click on the <b>Active-Subscribers </b> to see the metrics for each of the subscribers in the group.</p>
          <p>Click on the <i class="material-icons valign-icon">delete</i> icon to delete the corresponding subscriber group.</p>
          <p>Check the <b>PLOT</b> column in the table below and click the <i className="material-icons valign-icon">refresh</i> icon next to the chart to to add the subscriber group to the chart.</p>
          <p> Click on the <i className="material-icons valign-icon">refresh</i> icon on the top right to refresh the whole table and the chart.</p>
        </>
      )}
    />
  );
};
export default SubscriberDetailHelpModal;
