import React, { useContext, useState } from "react";
import ActionsContext from "common/ActionsContext";
import styled from "styled-components";
import * as d3 from "d3";
import { DEFAULT_MARGIN } from "common/graphs/BoxPlot";

const BoxPlotWithPanel = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 100%
  flex-wrap: nowrap;
`;

const TransparentOverlay = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  z-axis: 1;
  cursor: pointer;
  pointer-events: none;
  opacity: 1;
  transition: opacity 0.5s;
  &.fading {
    opacity: 0;
  }
  box-sizing: border-box;
  padding-left: ${({ padding }) => padding.left || 0}px;
  padding-right: ${({ padding }) => padding.right || 0}px;
  padding-top: ${({ padding }) => padding.top || 0}px;
  padding-bottom: ${({ padding }) => padding.bottom || 0}px;
`;

const fields = [
  { name: "max", label: "Max" },
  { name: "p90", label: "90th-percentile" },
  { name: "q3", label: "75th-percentile" },
  { name: "median", label: "Median" },
  { name: "q1", label: "25th-percentile" },
  { name: "p10", label: "10th-percentile" },
  { name: "min", label: "Min" },
];

const FactsLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 100% 1 1;
  height: 100%;
  border: solid 1px gray;
  padding: 0.5em 0;
  border-radius: 5px;
  font-size: 0.85em;
  text-shadow: #fff 1px 0 1px;
  & .fact,
  & .controls {
    padding: 0 0.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #777;
  }
  & .controls {
    padding-top: 0.25em;
    margin-top: auto;
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    justify-content: end;
  }
  & .fact {
    .field {
      font-size: 1em;
      text-align: left;
    }
    .value {
      text-align: right;
      font-weight: 700;
      white-space: pre-wrap;
      white-space: -moz-pre-wrap;
      white-space: -pre-wrap;
      white-space: -o-pre-wrap;
      word-wrap: break-word;
      overflow: visible;
    }
  }
`;

const decimalFormat = d3.format(".1f");
const minimalFactsSet = new Set(["min", "max"]);
const reducedFactsSet = new Set([...minimalFactsSet, "q1", "q3", "median"]);
const extendedFactsSet = new Set([...reducedFactsSet, "p10", "p90"]);

const involvedFactsGiven = ({ total, min, max }) =>
  total > 10
    ? () => true //Show all
    : total < 5 || min === max
    ? ({ name }) => minimalFactsSet.has(name)
    : total < 10
    ? ({ name }) => reducedFactsSet.has(name)
    : ({ name }) => extendedFactsSet.has(name);

const Details = ({
  data = {},
  format = decimalFormat,
  margin = { top: 0, left: 0, right: 0, bottom: 0 },
  className,
  left,
  top,
}) => {
  return (
    <TransparentOverlay
      level={80}
      padding={margin}
      className={className}
      style={{ left, top, zIndex: 500}}
    >
      <FactsLayout margin={margin}>
        {fields.filter(involvedFactsGiven(data)).map(({ name, label }) => (
          <div className="fact" key={name}>
            <span className="field">{label}:</span>
            <span className="value">{format(data[name])}</span>
          </div>
        ))}
      </FactsLayout>
    </TransparentOverlay>
  );
};

const BoxPlotTopBar = styled.div`
  display: flex;
  flex: 1.1em 0 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: ${({ margin }) => margin.left || 0}px;
  margin-right: ${({ margin }) => margin.right || 0}px;
`;

const ThresholdHeader = styled.div`
  display: flex;
  flex: 100% 1 1;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  heigth: 25px;
`;

const GroupDetails = ({
  field,
  quartiles,
  total,
  threshold,
  children,
  units,
  thresholdLabel = (value) => `${value}`,
  ...params
}) => {
  const actions = useContext(ActionsContext);
  const [showDetails, setShowDetails] = useState(false);
  const [detailsCoordinates, setDetailsCoordinates] = useState({ x: 0, y: 0 });

  function thresholdLabelComponent() {
    if (threshold === null) {
      return <label>Warning threshold not set</label>;
    }

    const parts = thresholdLabel(threshold).match(/(Warn if )(.*[%|ms])( .*)?/);

    if(parts === null){
      return "";
    }
    
    return (
      <>
        <label>{parts[1]}&nbsp;</label>
        <label
          className="thresholdValue"
          onClick={() =>
            actions.send("open-thresholds-settings", { target: field })
          }
        >
          {parts[2]}
        </label>
        {parts[3] && <label>&nbsp;{parts[3]}</label>}
      </>
    );
  }

  return (
    <>
      <BoxPlotWithPanel
        onMouseEnter={(e) => {
          setShowDetails(true);
          setDetailsCoordinates({ x: e.clientX + 5, y: e.clientY + 5 });
        }}
        onMouseLeave={() => {
          setShowDetails(false);
        }}
        onMouseMove={(e) => {
          setDetailsCoordinates({ x: e.clientX + 5, y: e.clientY + 5 });
        }}
      >
        <BoxPlotTopBar margin={DEFAULT_MARGIN}>
          <ThresholdHeader>
            <div className="box-plot-warning-label">
              {threshold === null
                ? "Warning threshold not set"
                : thresholdLabel(threshold)}
            </div>
            <span>
              <a
                data-loading-effect="pulse"
                data-toggle="cardloading"
                title="Double-Click/scroll on boxplot to zoom. Click here to reset."
                onClick={() => actions.send("reset-zoom", { target: field })}
              >
                <i className="material-icons">refresh</i>
              </a>
            </span>
          </ThresholdHeader>
        </BoxPlotTopBar>
        {children}
        {showDetails ? (
          <Details
            className="box-plot-details"
            field={field}
            data={{ ...quartiles, total, threshold }}
            margin={{ ...DEFAULT_MARGIN, top: 25 /*px Header height*/ }}
            units={units}
            left={detailsCoordinates.x}
            top={detailsCoordinates.y}
            {...params}
          />
        ) : null}
      </BoxPlotWithPanel>
      <div className="box-plot-warning-label-experimental">
        {thresholdLabelComponent()}
      </div>
    </>
  );
};

export default GroupDetails;
