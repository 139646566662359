/* globals views */
import React, { useState, useEffect, useContext } from "react";
import PubSub from "./PubSub";
import Modal from "./Modal";
import Request from "./Request";
import Form from "./Form";
import ActionsContext from "./ActionsContext";
import styled from "styled-components";
import NotForOperators from "./NotForOperators";

export class InvalidFieldValue extends Error {
  constructor(field, reason) {
    super(`The "${field}" field ${reason}`);
  }
}

const DefaultContextMenu = ({ doLoad = doesNothing }) => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      <li>
        <a
          data-loading-effect="pulse"
          data-toggle="cardloading"
          title="Help"
          onClick={() => actions.send("open-help")}
        >
          <i className="material-icons">help_outline</i>
        </a>
        <a
          onClick={doLoad}
          data-toggle="cardloading"
          data-loading-effect="pulse"
          title="Refresh"
        >
          <i className="material-icons">refresh</i>
        </a>
      </li>
    </ul>
  );
};

const WarnPendingChanges = () => (
  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 align-left">
    <div id="profThrouPendingChangesShow">
      <input
        type="radio"
        className="radio-col-red"
        checked
        onChange={() => {}}
      />
      <label id="profThrouPendingChanges" htmlFor="profThrouPendingChanges">
        <b>Pending Changes</b>
      </label>
    </div>
  </div>
);

const titleForError = (error) =>
  error instanceof InvalidFieldValue ? "INVALID FIELD VALUE" : "APPLY FAILED";

const ReportResultModal = ({ result = null, onClose = doesNothing }) => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    setVisible(result !== null && (result.ok || result.error));
  }, [result]);
  return visible && result !== null ? (
    <Modal
      title={result.error ? titleForError(result.error) : "COMMAND SUCESSFUL"}
      superIcon={result.error ? "error_outline" : undefined}
      onClose={onClose}
      content={() => (
        <>
          {result.ok ? <div className="row">{result.ok.message}</div> : null}
          {result.error ? (
            <div className="error align-left">
              {result.error.message === undefined
                ? result.error
                : result.error.message}
            </div>
          ) : null}
        </>
      )}
      closeLabel={"CLOSE"}
    />
  ) : null;
};

export const applyParams = (child, params = {}) =>
  React.isValidElement(child) ? React.cloneElement(child, params) : child;

const MayBeAForm = ({ wrap = true, onSubmit = null, children }) =>
  wrap === true ? <Form onSubmit={onSubmit}>{children}</Form> : children;

const SettingsPanel = ({
  returnView = undefined,
  reload,
  children,
  apply = () => Promise.resolve(null),
  defaults = {},
  wrapIntoForm = true,
  actionsClassName = "",
  applyActionClassName = "",
  applyLabel = "Apply",
  noDiscard = false,
  local = {}, setLocal = doesNothing,
  PendingChangesIndicator = WarnPendingChanges,
}) => {
  const [state, setState] = useState({
    pristine: true,
    ...defaults,
  });
  const actions = useContext(ActionsContext);
  const [result, setResult] = useState(defaults);

  const onChange = (event, setting = {}) => {
    if (event) {
      const { name, type } = event.target;
      let { value } = event.target;
      value = type === "checkbox" ? event.target.checked === true : value;
      setState((previous) => ({ ...previous, [name]: value, pristine: false }));
    } else {
      const { name, value } = setting;
      setState((previous) => ({ ...previous, [name]: value, pristine: false }));
    }
  };
  const onStateUpdate = (update) =>
    setState((previous) => ({ ...previous, ...update, pristine: false }));

  const notify = (error) => {
    setResult({ error });
    throw error;
  };

  useEffect(() => {
    actions.send("state-changed", state);
  }, [state]);
  const clearResult = () => setResult(null);

  const doApply = (formSettings = {}) =>
    apply({ ...state, ...formSettings }, defaults)
      .then(reload)
      .catch(notify);

  return (
    <MayBeAForm wrap={wrapIntoForm} onSubmit={doApply}>
      <div className="body">
        {React.Children.map(children, (child) =>
          applyParams(child, { onChange, state, defaults, onStateUpdate, local, setLocal })
        )}
      </div>
      <div className="control modal-footer">
        {state.pristine !== true ? (
          <React.Fragment>
            {PendingChangesIndicator === null ? null : (
              <PendingChangesIndicator />
            )}
            <NotForOperators>
              <button
                type="submit"
                className={`btn btn-link waves-effect ${applyActionClassName} ${actionsClassName}`}
                onClick={wrapIntoForm ? null : () => doApply()}
              >
                {applyLabel}
              </button>
            </NotForOperators>
            {noDiscard === true ? null : (
              <button
                type="button"
                className={`btn btn-link waves-effect ${actionsClassName}`}
                onClick={() => reload()}
              >
                Discard
              </button>
            )}
          </React.Fragment>
        ) : returnView !== undefined ? (
          <button
            type="button"
            className="btn btn-link waves-effect"
            onClick={() => globalNavigate(returnView)}
          >
            Return
          </button>
        ) : null}
      </div>
      <ReportResultModal result={result} onClose={clearResult} />
    </MayBeAForm>
  );
};

export const NoContextMenu = () => null;

const doesNothing = () => {};

const SettingsViewLayout = styled.div`
`  
const SettingsView = ({
  title = "title not set",
  titleClassName = "",
  className = "container-lg",
  subtitle = null,
  load = doesNothing,
  apply = () => Promise.resolve(null),
  clear = doesNothing,
  ContextMenu = DefaultContextMenu,
  noHeader,
  children,
  ...settings
}) => {
  const [request, setRequest] = useState(null);
  const [local, setLocal] = useState({})
  const doLoad = () => setRequest(load());
  const actions = new PubSub();
  useEffect(() => {
    doLoad();
  }, []);

  return request === null ? null : (
    <SettingsViewLayout className={className}>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="card">
            {!noHeader && (
              <div className="header block-header">
                <h2 className={`title ${titleClassName}`}>{title}</h2>
                <ContextMenu doLoad={doLoad}></ContextMenu>
                {subtitle === null ? null : subtitle}
              </div>
            )}
            <Request during={request}>
              {(result) => (
                <SettingsPanel
                  {...result}
                  reload={doLoad}
                  apply={apply}
                  clear={clear}
                  defaults={result}
                  {...settings}
                  local={local}
                  setLocal={setLocal}
                >
                  {React.Children.map(children, (child) =>
                    applyParams(child, { actions })
                  )}
                </SettingsPanel>
              )}
            </Request>
          </div>
        </div>
      </div>
    </SettingsViewLayout>
  );
};

export default SettingsView;
