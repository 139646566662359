import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useReducer,
} from "react";
import styled from "styled-components";
import { validateIPv4IPv6 } from "common/api";
import ActionsContext from "common/ActionsContext";

const InlineInputDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  min-width: 50ch;
  flex-wrap: wrap;
  & .input-group {
    max-width: 30rem;
    margin-bottom: 0;
  }
  & .input-group,
  & .input-group-add {
    flex-shrink: 1;
  }
  & .input-group.small {
    flex: 0 1 0;
  }
  & .break {
    flex: 1 0 100%;
  }
  & .error {
    font-size: 0.9em;
    color: red;
  }
  & .input-section {
    display: flex;
    flex-flow: row nowrap;
    align-items: start;
  }
  & .field-selector {
    width: auto;
  }
`;

const reducer = (state, action) => {
  switch (action.type) {
    case "change": {
      const { field, value } = action;
      return { field, value };
    }
  }
  throw Error("Unknown action: " + action.type);
};
const doesNothing = () => {};

const asFieldAndValue = ({ addr = null, subsId = null }) => {
  const value = addr || subsId || "";
  const field = addr !== null ? "addr" : "subs_id";
  return { value, field };
};
const asObject = ({ field, value }) =>
  field === "addr"
    ? { addr: value, subsId: null }
    : { addr: null, subsId: value };

export const TargetInput = ({
  target: defaultTarget,
  onChange = doesNothing,
  onSubmit = doesNothing,
}) => {
  const input = useRef(null);
  const fieldAndValue = asFieldAndValue(defaultTarget);
  const [state, dispatch] = useReducer(reducer, fieldAndValue);
  const doSubmit = () => {
    onSubmit(asObject(state));
  };
  const handleOnKeyDown = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event.preventDefault();
      doSubmit();
    }
  };
  const handleOnKeyUp = (event) => {
    const value = event.target.value;
    const field = validateIPv4IPv6(value) ? "addr" : "subs_id";
    dispatch({ type: "change", field, value });
  };
  useEffect(() => {
    onChange(asObject(state));
  }, [state]);
  return (
    <InlineInputDiv>
      <div className="input-section">
        <div className="input-group">
          <span className="input-group-addon">
            <i className="material-icons" title="Address range">
              {state.field === "addr" ? "devices" : "person_outline"}
            </i>
          </span>
          <div className="form-line">
            <input
              ref={input}
              type="text"
              name="target"
              defaultValue={fieldAndValue.value || ""}
              onKeyUp={handleOnKeyUp}
              onKeyDown={handleOnKeyDown}
              className="form-control"
              placeholder="Ex: 1.1.1.1 || subcriber name"
            />
          </div>
        </div>
        <div className="input-group-add">
          <button className="btn btn-link waves-effect" onClick={doSubmit}>
            Apply
          </button>
        </div>
      </div>
    </InlineInputDiv>
  );
};

const TargetContext = ({ addr = null, subsId = null, children }) => {
  const actions = useContext(ActionsContext);
  const [target, setTarget] = useState({ addr, subsId });
  const updateTarget = (newTarget) => {
    setTarget((previous) => ({
      ...previous,
      ...(newTarget && newTarget.addr !== null &&
      (newTarget.addr.length > 0 || newTarget.addr !== target.address)
        ? { addr: newTarget.addr, subsId: null }
        : newTarget && newTarget.subsId !== null &&
          (newTarget.subsId.length > 0 || newTarget.subsId !== target.subsId)
        ? { subsId: newTarget.subsId, addr: null }
        : {}),
    }));
  };
  const reAssign = () => {
    const currentTarget = { ...target };
    setTarget(null);
    setTimeout(() => setTarget(currentTarget), 0);
    return true;
  };
  useEffect(() => {
    const newTarget =
      addr === null && subsId === null ? null : { addr, subsId };
    updateTarget(newTarget);
  }, [addr, subsId]);
  useEffect(() => {
    return actions.recv("do-load-details", (loadTarget) =>
      loadTarget === undefined
        ? reAssign(target)
        : updateTarget(loadTarget)
    );
  }, [target]);
  return (
    <>
      {target === null
        ? null
        : children.map((child) =>
            React.isValidElement(child)
              ? React.cloneElement(child, {
                  key: child.props.name,
                  target: target,
                })
              : child
          )}
    </>
  );
};

export default TargetContext;
