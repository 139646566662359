/* globals views, showModalError, showModalConfirm, rulesRate */
import React, { useRef, useContext } from "react";
import styled from "styled-components";
import Request from "common/Request";
import ActionsContext from "common/ActionsContext";
import SelectableTable, { Column } from "common/SelectableTable";
import { safeStr } from "common/api";

const returnView = "viewStatusSubscriberGroups";

const openSubscribersDashboard = (subscriberGroup) => {
  views.doKeep(returnView);
  globalNavigate("viewStatusSubscribers", {
    returnView,
    subscriberGroup,
  });
};

const TableContainer = styled.div`
position: relative;
  width: 100%;
  overflow: auto;
  --is-highest: #3d85c6;
  --is-higher: #6aa84f;
  --is-lower: #e69138;
  --is-lowest: #cc0000;
  --warning: #cc0000;
  .inverted {
    --is-lowest: #3d85c6;
    --is-lower: #6aa84f;
    --is-higher: #e69138;
    --is-highest: #cc0000;
  }
  .dataTable tbody td {
    transition: color 1s, background-color: 1s;
  }
  &.colorize-warnings .dataTable tbody td {
    &.warning {
      color: white;
      background: var(--warning);
    }
  }
`;

const dynamicPrecisionRanges = [
  [/*below*/ 10, 3 /*digits*/],
  [/*below*/ 100, 2 /*digits*/],
  [/*below*/ 1000, 1 /*digits*/],
  // Defaults to 0 digits
];
const doesNothing = () => {};

const ListSubscriberGroups = ({
  request,
  onPlotSelection = doesNothing,
  onRemoved = doesNothing,
  permanent,
  nonpermanent,
  timePeriod,
}) => {
  const tableRef = useRef();
  const actions = useContext(ActionsContext);
  const timePeriodRef = useRef();
  timePeriodRef.current = timePeriod;

  return (
    <TableContainer
      ref={tableRef}
      className={"colorize-warnings subsGroups-table"}
    >
      <Request during={request}>
        {({ response }) => {
          if (
            (permanent && permanent.length > 0) ||
            (nonpermanent && nonpermanent.length > 0)
          ) {
            const lines = response.split("\n");
            const newLines = lines.reduce((acc, value) => {
              let newLine;
              const subscriberGroupName = value.split(/\s+/)[0];
              if (permanent.includes(subscriberGroupName)) {
                newLine = `${value} Yes`;
              } else if (nonpermanent.includes(subscriberGroupName)) {
                newLine = `${value} No`;
              } else if (subscriberGroupName === "SUBSCRIBER-GROUP") {
                newLine = `${value} PERMANENT`;
              }
              acc.push(newLine);
              return acc;
            }, []);
            response = newLines.join("\n");
          }

          const tableColumns = [
            Column.Text({
              label: "SUBSCRIBER-GROUP",
              idx: 0,
              title: "Subscriber’s group",
              cellClassName: "hyperlink-text",
              onClick: (subscriberGroup) => {
                globalNavigate("viewStatusSubscriberGroups", {
                  subscriberGroup,
                  timePeriod: timePeriodRef.current,
                });
              },
            }),
            Column.Number({
              label: "SUBS-ACTIVE",
              idx: 2,
              title: "Subscribers active",
              colClassName: "align-right",
              cellClassName: "hyperlink-text",
              onClick: (_, [subscriberGroup]) =>
                openSubscribersDashboard(subscriberGroup),
            }),
            Column.Number({
              label: "FL-ACTIVE",
              title: "FL Active",
              idx: 3,
              colClassName: "align-right",
            }),
            Column.NumberOrNA({
              label: "LIM-Mbps",
              title: "LIM-Mbps",
              idx: 8,
              precision: dynamicPrecisionRanges,
              colClassName: "align-right",
            }),
            Column.Text({
              label: "RATE-POLICY",
              idx: 9,
              title: "RATE-POLICY",
              cellClassName: (value, row) =>
                `align-left${value !== "n/a" ? " hyperlink-text" : ""}${
                  row[6] === "undefined" ? " col-red  cell-with-icon-left" : ""
                }`,
              onClick: (
                _policyName,
                [_name, _active, _flows, _limit, policyName, assignment],
                _trigger
              ) =>
                policyName !== "n/a"
                  ? actions.send("subscriber-group-policy-rate-clicked", {
                      policyName,
                      assignment,
                    })
                  : null,
              icon: ([_name, _active, _flows, _limit, _policyName, assignment]) =>
                assignment === "undefined" ? "warning" : null,
            }),
            Column.Hidden({
              label: "ASSIGNMENT",
              idx: 10,
            }),
            Column.Number({
              label: "FL-CREATED",
              title: "FL Created",
              idx: 4,
              colClassName: "align-right",
            }),
            Column.NumberOrNA({
              label: "MBYTES",
              title: "MBytes",
              precision: dynamicPrecisionRanges,
              idx: 5,
              colClassName: "align-right",
            }),
            Column.NumberOrNA({
              label: "CURR-Mbps",
              title: "Current speed in Mbps",
              idx: 6,
              colClassName: "align-right",
            }),
            Column.NumberOrNA({
              label: "MEAN-Mbps",
              title:
                "Moving average of the mean speed (calculated every 10 minutes) in Mbps.",
              idx: 7,
              colClassName: "align-right",
              precision: dynamicPrecisionRanges,
            }),
            Column.NumberOrNA({
              label: "RTT-ms",
              title:
                "Moving average of latency, in milliseconds. If downlink, latency from this node to subs, if uplink, latency from this node to Internet server",
              idx: 11,
              colClassName: "align-right",
              precision: dynamicPrecisionRanges,
            }),
            Column.NumberOrNA({
              label: "RTX",
              title:
                "Moving average of the percentage of TCP retransmissions (which reflect packet losses in this direction)",

              idx: 12,
              percent: true,
              precision: 1,
              colClassName: "align-right",
            }),
            Column.NumberOrNA({
              label: "MAX-SPEED-%",
              field: "MAX-SPEED",
              title:
                "Moving average of the percentage of traffic sent with a speed close to the maximum",
              idx: 13,
              percent: true,
              colClassName: "align-right",
            }),
            Column.NumberOrNA({
              label: "CONGESTION",
              field: "CONGEST",
              title:
                "Moving average of percentage of traffic suffering congestion",
              idx: 14,
              percent: true,
              colClassName: "align-right",
            }),
            Column.Time({
              label: "LIFETIME",
              title: "Duration so far of this subscriber session",
              idx: 15,
              colClassName: "align-right",
            }),
            Column.Actions({
              label: "ACTIONS",
              idx: 0,
              colClassName: "text-center action",
              cellClassName: "hides-content-to-operators",
              are: [
                {
                  id: "edit-subs-group",
                  label: "Edit",
                  icon: "edit",
                  onClick: (_, [subscriberGroup]) =>
                    actions.send("subscriber-group-edit-clicked", {
                      subscriberGroup,
                    }),
                },
                {
                  id: "delete-subscriber-group",
                  icon: "delete",
                  label: "Delete",
                  onClick: (subscribersGroup) => {
                    showModalConfirm(
                      `Delete ${subscribersGroup} subsciber group.`,
                      "Deleting it will erase its statistics.",
                      "delete_forever",
                      () => {
                        ifCl
                          .run(
                            `clear api subscriber-group ${safeStr(
                              subscribersGroup
                            )}`
                          )
                          .then(() => onRemoved(subscribersGroup))
                          .catch((error) => showModalError(error));
                      }
                    );
                  },
                },
              ],
            }),
          ];

          return (
            <>
              <SelectableTable
                selectionField={"plot"}
                selectionLabel={"PLOT"}
                asObject={([name]) => ({ name })}
                onSelection={onPlotSelection}
                selectFirst={10}
                exportAsCSV={true}
                content={response}
                columns={tableColumns}
              />
            </>
          );
        }}
      </Request>
    </TableContainer>
  );
};

export default ListSubscriberGroups;
