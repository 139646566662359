import React from "react";
import styled from "styled-components";

const ArrangeInColumns = styled.div`
  --grid-layout-gap: ${(props) => props.gap || "3rem"};
  --grid-column-count: ${(props) => props.columns || 4};
  --grid-item--min-width: ${(props) => props.minWidth || "10cm"};

  /**
   * Calculated values.
   */
  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc(
    (100% - var(--total-gap-width)) / var(--grid-column-count)
  );

  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
  );
  grid-gap: var(--grid-layout-gap);
  row-gap: ${(props) => props.rowGap || "var(--grid-layout-gap)"};
  ${ ({alignToTop}) =>
    alignToTop === true ? `
    align-items: flex-start;
    align-content: flex-start;
    `: ''
  }

  & .full-width {
    grid-column: 1 / -1;
  }
  & > div {
    min-width: 200px;
  }
  & .input-group,
  & .group {
    flex: 1 1 calc(50% - 20px);
    &.alone {
      margin-right: 50%;
    }
    &.void {
      height: 0;
      margin: 0;
    }
  }
  & .group.full-width {
    flex: 100%;
  }
  @media screen and (max-width: 700px) {
    & > .input-group,
    & > .input-group {
      flex: 100%;
      &.alone {
        margin-right: 0;
      }
    }
  }
  @media print {
    border: solid red 1px;
    display: block;
    & > * {
      display: block;
    }
  }
`;

export default ArrangeInColumns;

export const VoidControl = () =>
  <div className="input-group void"></div>






  // <!-- ************ Column A ************ -->
  // <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 margin-b-0 padding-0">
  //   <!-- TCP OPTIMIZATION -->
  //   <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12 margin-0 padding-0 margin-t-5" id="poliFlowTcpSection">
  //     <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 margin-b-0 padding-0">
  //       <div class="input-group">
  //         <span class="input-group-addon align-left" title="Optimization">TCP Optimization: <span id="statusTCPO"></span></span>
  //       </div>
  //     </div>
  //     <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 margin-b-0 align-right padding-0">
  //       <input type="checkbox" id="poliFlowOptSw" class="sw-input">
  //       <label for="poliFlowOptSw" class="sw-label min-width-0">
  //         <span class="toggle--on"></span>
  //         <span class="toggle--off"></span>
  //       </label>
  //     </div>
  //     <div class="margin-0 padding-0">
  //     <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-0 padding-0" id="poliFlowTcpParamsPanel" style="display: none;">
  //       <div class="panel-group" id="configFlowAdParams" role="tablist" aria-multiselectable="true">
  //         <div class="panel panel-default">
  //           <div class="panel-heading" role="tab" id="headingOne_2">
  //             <h4 class="panel-title font-size-14">
  //               <a role="button" data-toggle="collapse" data-parent="#configFlowAdParams" href="#configFlowAdParamsContent" aria-expanded="false" aria-controls="configFlowAdParamsContent" class="collapsed-arrow collapsed">
  //                 TCP Advanced Parameters
  //               </a>
  //             </h4>
  //           </div>
  //           <div id="configFlowAdParamsContent" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne_2" aria-expanded="false" style="height: 0px;">
  //             <div class="panel-body">
  //               <div class="input-group spinner" data-trigger="spinner">
  //                 <span class="input-group-addon" title="TCP Optimization">TCP tracking % (0-99.99%)</span>
  //                 <div class="form-line">
  //                   <input id="poliFlowAcc" type="text" class="form-control align-right" value="95" data-precision="2" data-min="0" data-max="99.99" data-step="0.01" data-rule="quantity">
  //                 </div>
  //                 <span class="input-group-addon">%&emsp;</span>
  //                 <span class="input-group-addon">
  //                   <a href="javascript:;" id="poliFlowAccUp" class="spin-up" data-spin="up"><i class="glyphicon glyphicon-chevron-up"></i></a>
  //                   <a href="javascript:;" id="poliFlowAccDown" class="spin-down" data-spin="down"><i class="glyphicon glyphicon-chevron-down"></i></a>
  //                 </span>
  //                 <span class="input-group-addon">
  //                   &nbsp;
  //                   <a href="javascript:void(0);" id="poliFlowAccReset" title="Reset Default">
  //                     <i class="material-icons">backspace</i>
  //                   </a>
  //                 </span>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     </div>
  //   </div>
  //   <div class="col-lg-1 col-md-1 col-sm-0 col-xs-0 margin-0 padding-0"></div>
  // </div>
  // <!-- ************ Column B ************ -->
  // <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 margin-b-0 padding-0" id="poliFlowCompShow">
  //   <div class="col-lg-1 col-md-1 col-sm-0 col-xs-0 margin-0 padding-0"></div>
  //   <div class="col-lg-7 col-md-7 col-sm-8 col-xs-8 margin-b-0 padding-0">
  //     <div class="input-group">
  //       <span class="input-group-addon align-left" title="Compression">Compression: <span id="statusCompression"></span></span>
  //     </div>
  //   </div>
  //   <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 margin-b-0 align-right padding-0">
  //     <input type="checkbox" id="poliFlowCompSw" class="sw-input">
  //     <label for="poliFlowCompSw" class="sw-label min-width-0">
  //       <span class="toggle--on"></span>
  //       <span class="toggle--off"></span>
  //     </label>
  //   </div>
  // </div>
