import React, { useRef, useEffect } from "react";
import * as d3 from "d3";
import styled from "styled-components";
import { remaining } from "./Axis";
import LoginContainer from "../../views/Login/component";
// https://d3-graph-gallery.com/graph/boxplot_basic.html

const doesNothing = () => {};

const maxWidthOf = (nodes) =>
  nodes.reduce((result, node) => {
    const { width } = node.getBBox();
    return width > result ? width : result;
  }, 0);

const StyledG = styled.g`
  rect.frame {
    stroke: currentColor;
    & + path.domain {
      stroke: none;
    }
  }
  .units {
    fill: currentColor;
    font: normal 10px Arial, sans-serif;
    text-anchor: middle;
  }
  .warning-zone .value {
    font: normal 10px Arial, sans-serif;
  }
  .plot {
    fill: white;
    transition: fill 1s, stroke 1s;
    .warning-zone .value {
      text-anchor: start;
    }
    line {
      stroke: currentColor;
    }
    line,
    rect {
      vector-effect: non-scaling-stroke;
    }
    .background {
      fill: transparent;
    }
  }
`;

const atLeast = (min, value) => (min < value ? value : min);

const warningRangeFrom = (threshold, inverted, [bottom, top]) =>
  threshold === undefined || threshold === 0 || threshold === null
    ? null
    : inverted === true
    ? [threshold, top]
    : [bottom, threshold];

const renderThresholdOn = (
  plot,
  { threshold, inverted },
  { width },
  { yScale }
) => {
  const warningRange = warningRangeFrom(threshold, inverted, yScale.domain());

  if (warningRange !== null) {
    const [starts, ends] = warningRange;
    const warning = plot.append("g").attr("class", "warning-zone");
    const height = yScale(starts) - yScale(ends);
    if (height > 0) {
      warning
        .append("rect")
        .attr("class", "area")
        .attr("x", 0)
        .attr("y", yScale(ends))
        .attr("height", height)
        .attr("width", width);
      if (threshold > starts || threshold < ends) {
        warning
          .append("line")
          .attr("class", "divisor")
          .attr("x1", 0)
          .attr("x2", width)
          .attr("y1", yScale(threshold))
          .attr("y2", yScale(threshold));
      }
    }
  }
};

const renderQuartiles = (plot, data, { xScale, yScale }, widthRatio = 1) => {
  const [left, right] = xScale.range();
  const center = (right - left) / 2;
  const thickness = d3.max([(right - left) * widthRatio, 20]);
  const { q1, median, q3, min, max } = data;
  // Vertical lines
  plot
    .append("line")
    .attr("class", "min-median")
    .attr("x1", center)
    .attr("x2", center)
    .attr("y1", yScale(min))
    .attr("y2", yScale(median))
    .attr("stroke", "black");
  plot
    .append("line")
    .attr("class", "median-max")
    .attr("x1", center)
    .attr("x2", center)
    .attr("y1", yScale(median))
    .attr("y2", yScale(max))
    .attr("stroke", "black");
  // Interquartiles
  let height = yScale(q1) - yScale(median);
  plot
    .append("rect")
    .attr("class", "q1-median")
    .attr("x", center - thickness / 2)
    .attr("y", yScale(median))
    .attr("height", height ? height : 0)
    .attr("width", thickness)
    .attr("stroke", "black");
  height = yScale(median) - yScale(q3);
  plot
    .append("rect")
    .attr("class", "median-q3")
    .attr("x", center - thickness / 2)
    .attr("y", yScale(q3))
    .attr("height", height ? height : 0)
    .attr("width", thickness)
    .attr("stroke", "black");

  const quartiles = [
    { name: "min", value: min, label: "Min" },
    { name: "q1", value: q1, label: "25th-percentile" },
    { name: "median", value: median, label: "Median" },
    { name: "q3", value: q3, label: "75th-percentile" },
    { name: "max", value: max, label: "Max" },
  ];
  const newQuartiles = plot.selectAll(".quartile").data(quartiles).enter();
  newQuartiles
    .append("line")
    .attr("class", (d) => `${d.name}`)
    .attr("x1", center - thickness / 2)
    .attr("x2", center + thickness / 2)
    .attr("y1", (d) => yScale(d.value))
    .attr("y2", (d) => yScale(d.value));
};

const renderAxisAndFrame = (
  main,
  { width, height },
  { yScale, format, units, frameless = false, showAxis = true }
) => {
  const axisGroup = main.append("g").attr("class", "y axis");

  if (frameless !== true) {
    const axisGroupRect = axisGroup
      .append("rect")

      axisGroupRect.attr("class", "frame")
      .attr("width", width)
      .attr("height", height);

      axisGroupRect
      .attr("rx", "5")
  }

  const placeUnitsLabel = (axisGroup) =>
    axisGroup
      .select(".units")
      .attr("y", height / 2)
      .attr("dy", "20px");

  const renderYAxis =
    showAxis !== false
      ? (yScale) => d3.axisLeft(yScale).ticks(5, "s").tickFormat(format)
      : () => doesNothing;
  axisGroup.call(renderYAxis(yScale));
  if (units !== undefined) {
    axisGroup
      .attr("x", 0)
      .append("text")
      .attr("class", "units")
      .text(units)
      .attr("transform", `rotate(-90, 0, ${height / 2})`)
      .style("text-anchor", "center");
    axisGroup.call(placeUnitsLabel);
  }
  return [axisGroup, renderYAxis, placeUnitsLabel];
};

export const DEFAULT_MARGIN = { top: 0, right: 10, bottom: 0, left: 40};

const formaDecimal = d3.format(".1f");

const BoxPlot = ({
  canvas,
  data = {},
  axis = {},
  xFrom = "x",
  yFrom = "y",
  dimensions = null,
  margin = DEFAULT_MARGIN,
  units = undefined,
  format = (d) => (d % 1 ? formaDecimal(d) : d),
  frameless = false,
  showAxis = true,
  widthRatio = 0.3,
  zoomEnabled = true,
  plotRef = undefined,
}) => {
  const chart = useRef(null);
  useEffect(() => {
    if (dimensions === null) {
      return;
    }
    const { width, height } = remaining(dimensions, margin);
    if (width < 1 || height < 1) {
      return;
    }

    const xAxis = axis[xFrom];
    const yAxis = axis[yFrom];
    const xScale = xAxis.scale;
    const yScale = yAxis.scale;

    // Position main container.
    const main = d3.select(chart.current);
    main.attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    const [axisGroup, renderYAxis, placeUnitsLabel] = renderAxisAndFrame(
      main,
      { width, height },
      { yScale, format, units, frameless, showAxis }
    );
    const plot = main.append("g").attr("class", "plot");
    plot
      .append("rect")
      .attr("class", "background")
      .attr("width", width)
      .attr("height", height);

    if (plotRef !== undefined) {
      plotRef.current = plot;
    }
    renderQuartiles(plot, data, { xScale, yScale }, widthRatio);
    renderThresholdOn(plot, data, { width, height }, { yScale });

    const maskTop = main.append("g").attr("class", "boxPlotMask");

    maskTop
      .append("rect")
      .attr("class", "background")
      .attr("width", width)
      .attr("height", margin.top)
      .attr("y", -margin.top);

    maskTop
      .append("rect")
      .attr("class", "background")
      .attr("width", width)
      .attr("height", margin.bottom)
      .attr("y", height);

    const resetZoom = () => {
      plot.attr("transform", `translate(0, 0) scale(1 1)`);
      axisGroup.call(renderYAxis(yScale)).call(placeUnitsLabel);
    };
    const enableZoom =
      zoomEnabled !== true
        ? () => doesNothing
        : d3.zoom().on("zoom", function ({ transform }) {
            const { y, k } = transform;
            plot.attr(
              "transform",
              `translate(0, ${y}) scale(1 ${atLeast(1, k)})`
            );
            axisGroup
              .call(renderYAxis(transform.rescaleY(yScale)))
              .call(placeUnitsLabel);
          });
    plot.call(enableZoom);
    plot.on("reset-zoom", resetZoom);

    return () => {
      main.selectAll(".plot").remove();
      main.selectAll(".axis").remove();
    };
  }, [chart, canvas, dimensions, axis]);

  return <StyledG ref={chart}></StyledG>;
};

export default BoxPlot;
