import {timeParse} from "d3";

export const parseSystemDate = (input) => new Date(input);

export const parseFloatOrNull = (value) =>
  value === "n/a"
    ? null
    : value.endsWith("%") === true
    ? parseFloat((parseFloat(value.trim("%")) / 100).toFixed(4))
    : parseFloat(value);

const dateTimeParser = timeParse("%Y-%m-%dT%H:%M:%S");

const capitalizeFirstLetter = (word) =>
  word[0].toUpperCase() + word.slice(1).toLowerCase();

const lowerFirstUpperOthers = (word, index) =>
  index === 0 ? word.toLowerCase() : capitalizeFirstLetter(word);

const asJSAttribute = (input) =>
  input
    .split(/[\s-]+/)
    .map(lowerFirstUpperOthers)
    .join("");

const timeAndNumbers = (row, keys, skipped = []) =>
  row
    .split(/\s+/)
    .map((value, index) =>
      skipped.length > 0 && skipped.includes(keys[index])
        ? null
        : [
            keys[index],
            index > 0 ? parseFloatOrNull(value) : dateTimeParser(value),
          ]
    )
    .filter((item) => item !== null);

export const fixedPrecision = (value, decimals = 4) =>
  parseFloat(value.toFixed(decimals));

export const parseSubscriberVolume = (input) => {
  const [head, ...rows] = input.trim("\n").split("\n");
  const keys = head.split(/\s+/).map(asJSAttribute);
  return rows.map((row) => Object.fromEntries(timeAndNumbers(row, keys)));
};

export const parseSubscriberFlows = (input) => {
  const [head, ...rows] = input.trim("\n").split("\n");
  const keys = head.split(/\s+/).map(asJSAttribute);
  return rows.map((row) => Object.fromEntries(timeAndNumbers(row, keys)));
};

export const parseSubscriberLatencies = (input) => {
  const [head, ...rows] = input.trim("\n").split("\n");
  const keys = head.split(/\s+/).map(asJSAttribute);
  return rows.map((row) => Object.fromEntries(timeAndNumbers(row, keys)));
};

const calcTimeLength = ([first, second]) =>
  (second.time.getTime() - first.time.getTime()) / 1000 /*ms*/

const divideCreationByTimeLength = (timelength) =>
  ({flCreated, ...rest}) =>
    ({flCreatedPerMinute: Math.round(flCreated * 60 / timelength),...rest})

export const calcFlowsCreationPerMinute = source => 
  source.map(divideCreationByTimeLength(calcTimeLength(source)));

export const parseSubscriberMaxSpeeds = (input) => {
  const [head, ...rows] = input.trim("\n").split("\n");
  const translations = {
    "TRAFFIC-AT-MAXSPEED-%": "maxSpeed",
  };
  const keys = head
    .split(/\s+/)
    .map((key) => translations[key] || asJSAttribute(key));
  return rows.map((row) => Object.fromEntries(timeAndNumbers(row, keys)));
};

export const parseSubscriberRetransmissions = (input) => {
  const [head, ...rows] = input.trim("\n").split("\n");
  const translations = {
    "SUBS-ACCESS-RTX-%": "subsAccessRtx",
    "NETWORK-ACCESS-RTX-%": "networkAccessRtx",
  };
  const keys = head
    .split(/\s+/)
    .map((key) => translations[key] || asJSAttribute(key));
  return rows.map((row) => Object.fromEntries(timeAndNumbers(row, keys)));
};

export const parseSubscriberCongestion = (input) => {
  const [head, ...rows] = input.trim("\n").split("\n");
  const translations = {
    "CONGESTION-%": "congestion",
  };
  const keys = head
    .split(/\s+/)
    .map((key) => translations[key] || asJSAttribute(key));
  return rows.map((row) => Object.fromEntries(timeAndNumbers(row, keys)));
};

export const toTransformGiven = (interval, attributes = [], scale = 1) => {
  const transform = (volume) =>
    volume === undefined
      ? undefined
      : volume === null
      ? null
      : fixedPrecision((volume * 8 * scale) / interval, 4);
  const convertToSpeed = ({ time, ...item }) =>
    Object.fromEntries([
      ["time", time],
      ...attributes.map(({ from, to }) => [to, transform(item[from])]),
    ]);
  return { convertToSpeed };
};

const _defaultAttributes = [
  { from: "downMbps", to: "downMbPerSec" },
  { from: "upMbps", to: "upMbPerSec" },
];

export const convertVolumeToSpeed = (
  items,
  { interval = 10, attributes = _defaultAttributes, scale = 1 }
) =>
  items.map(
    toTransformGiven(interval * 60 /*minutes*/, attributes, scale)
      .convertToSpeed
  );
