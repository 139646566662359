import React, { useState, useRef, useEffect, forwardRef } from "react";
import styled from "styled-components";
import NVLineChart from "./NVLineChart";
import Legend from "./Legend";
import {
  dataMapKey,
  getSystemDate,
  twoDigits,
  getItemsUpdate,
  getTooltipTitle,
} from "../../views/Statistics/SubscriberDetail/livemetrics.utils";
import colors from "./colors";
import colorsDark from "./colorsDark";

const TileContainer = styled.div`
  position: relative;
  min-height: 210px;
  @media print {
    & {
      height: 8cm;
      &.can-compress {
        height: auto;
      }
      width: 100%;
      display: block:
    }
  }
  display: flex;
  flex-direction: column;
  & > h4 {
    text-align: center;
    margin: 5px 0 15px 0;
  }
  & > .graph {
    flex: 1 1 100%;
    .nv-y.nv-axis .nv-axisMaxMin {
      display: none;
    }

    svg {
      height: 190px;
      overflow: visible;

      .nv-axislabel{
        transform: translate(20px, 0) rotate(-90deg);
      }
    }
  }
  &.spread {
    justify-content: space-between;
    padding: 1em;
    align-content: flex-start;
  }
  &.can-compress {
    min-height: 0;
  }
  .input-group {
    max-width: 20ch;
  }

  .border{
    position: absolute;
    background-color: transparent;

    &.top{
      width: 100%;
      height: 70px;
      top: 0;
      left: 0;
    }

    &.right{
      width: 24px;
      height: 100%;
      top: 0;
      right: 0;
    }

    &.bottom{
      width: 100%;
      height: 30px;
      bottom: 0;
      left: 0;
    }

    &.left{
      width: 30px;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
`;

const StyledTooltipContainer = styled.div`
  position: absolute;
  z-index: 10000;
  text-align: left;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 0 5px;
  width: 190px;

  .title {
    color: black;
    margin-bottom: 0px;
  }

  .field-container {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .field {
      display: flex;
      gap: 5px;
      align-items: center;
      justify-content: space-between;

      .left-side {
        display: flex;
        gap: 5px;
        align-items: center;
      }

      .square {
        width: 12px;
        min-width: 12px;
        height: 12px;
        border: 1px solid #999;
        border-radius: 6px;
      }

      .label,
      .value {
        font-family: Arial, sans-serif;
        font-size: 13px;
        margin-bottom: 0px;
        color: black;
      }

      .label {
        font-weight: normal;
      }

      .value {
        text-align: right;
      }
    }
  }
`;

const StyledLegendContainer = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: end;
  padding-right: 25px;
  top: 36px;
  }
`;

const Tooltip = ({
  dataPoint,
  mouseEvent,
  filteredFields,
  yTooltipFormat,
  onMouseEvent,
}) => {
  if (!dataPoint) {
    return null;
  }
  const isRightSide = mouseEvent.mouseX < 175;
  const offset = isRightSide ? 55 : -190;
  const colorsSet = login.isTheme("light") ? colors : colorsDark;

  return (
    <StyledTooltipContainer
      style={{
        top: mouseEvent.mouseY + 45 + "px",
        left: mouseEvent.mouseX + offset + "px",
      }}
      onMouseMove={() => {
        onMouseEvent({
          ...mouseEvent,
          mouseX: mouseEvent.mouseX + offset,
        });
      }}
      className="tooltip-live"
    >
      <label className="title">{getTooltipTitle(mouseEvent.pointXValue)}</label>
      <div className="field-container">
        {filteredFields.map((field, index) => (
          <div className="field">
            <div className="left-side">
              <div
                className="square"
                style={{ backgroundColor: colorsSet[index] }}
              />
              <label className="label"> {field.label} </label>
            </div>
            <label className="value">
              {dataPoint && dataPoint[field.name] != undefined
                ? yTooltipFormat(dataPoint[field.name])
                : ""}
            </label>
          </div>
        ))}
      </div>
    </StyledTooltipContainer>
  );
};

export const FacetGraphLive = forwardRef(
  (
    {
      title,
      units = "",
      fields,
      yAxisFormat = null,
      value,
      resetSignal,
      onMouseEvent,
      mouseEvent,
      yTooltipFormat = (value) => value,
      timeOffset,
      timeWindow,
      ...rest
    },
    ref
  ) => {
    const [items, setItems] = useState([]);
    const [window, setWindow] = useState(0);
    const [filteredFields, setFilteredFields] = useState(fields);
    const [fieldsFilter, setFieldsFilter] = useState(
      new Set(fields.map((field) => field.name))
    );
    const firstValue = useRef(false);
    const dataMap = useRef({});

    useEffect(() => {
      if (resetSignal) {
        dataMap.current = {};
        firstValue.current = false;
        setItems([]);
        setWindow(0);
      }
    }, [resetSignal]);

    useEffect(() => {
      if (!value) {
        return;
      }

      const now = getSystemDate(timeOffset);
      const newValue = { ...value };

      if (!firstValue.current) {
        firstValue.current = newValue;
      }

      const timeFrame =
        newValue.time.getTime() - firstValue.current.time.getTime();

      let { itemsUpdate, window: windowNew  } = getItemsUpdate(
        items,
        now,
        fields,
        timeOffset,
        timeFrame,
        timeWindow,
        newValue,
        window
      );
      setWindow(windowNew);
      const lastValue = itemsUpdate[itemsUpdate.length - 1];
      if (lastValue?.time && newValue?.time && dataMapKey(lastValue.time) !== dataMapKey(newValue.time)) {
        delete dataMap.current[dataMapKey(itemsUpdate[0].time)];
        dataMap.current[dataMapKey(newValue.time)] = newValue;
        setItems([...itemsUpdate.slice(1), newValue]);
      }
    }, [value]);

    useEffect(() => {
      if (fieldsFilter) {
        setFilteredFields(
          fields.filter((field) => fieldsFilter.has(field.name))
        );
      }
    }, [fieldsFilter]);

    function removeTooltip() {
      if (mouseEvent) {
        onMouseEvent(null);
      }
    }

    return (
      <TileContainer className="chart-container">
        <h4 className="chart-title">{title}</h4>
        <div className="graph" ref={ref}>
          <NVLineChart
            items={items}
            xField="time"
            yAxisUnits={units}
            xAxisFormat={d3.timeFormat("%H:%M:%S")}
            yAxisTopGap={0.1}
            yAxisFormat={yAxisFormat}
            fields={filteredFields}
            {...rest}
            onHighlight={(e) => {
              if (e) {
                onMouseEvent(e);
              }
            }}
            tooltipEnabled={false}
            highlight={mouseEvent}
            showLegend={false}
          />
        </div>
        {mouseEvent && (
          <Tooltip
            dataPoint={dataMap.current[dataMapKey(mouseEvent.pointXValue)]}
            mouseEvent={mouseEvent}
            filteredFields={filteredFields}
            yTooltipFormat={yTooltipFormat}
            onMouseEvent={onMouseEvent}
          />
        )}
        {mouseEvent && (
          <>
            <div
              className="border top"
              onMouseEnter={removeTooltip}
              onMouseLeave={removeTooltip}
            ></div>
            <div
              className="border right"
              onMouseEnter={removeTooltip}
              onMouseLeave={removeTooltip}
            ></div>
            <div
              className="border bottom"
              onMouseEnter={removeTooltip}
              onMouseLeave={removeTooltip}
            ></div>
            <div
              className="border left"
              onMouseEnter={removeTooltip}
              onMouseLeave={removeTooltip}
            ></div>
          </>
        )}
        {items.length > 0 && (
          <StyledLegendContainer className="legend-graph-live">
            <Legend
              fields={fields}
              onChange={(filter) => setFieldsFilter(filter)}
              value={fieldsFilter}
            />
          </StyledLegendContainer>
        )}
      </TileContainer>
    );
  }
);

FacetGraphLive.displayName = "FacetGraphLive";