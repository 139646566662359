import React, { useEffect, useState, useRef, useContext } from "react";
import Request from "common/Request";
import NVLineChart from "common/graphs/NVLineChart";
import styled from "styled-components";
import * as d3 from "d3";
import RangeSliderInput from "common/RangeSliderInput";
import ActionsContext from "common/ActionsContext";
import { safeStr } from "common/api";

const TileContainer = styled.div`
  min-height: 10cm;
  display: flex;
  flex-direction: column;
  & > h4 {
    text-align: center;
    margin: 20px 0 0 0;
  }
  & > .graph {
    flex: 1 1 100%;
    min-height: inherit;
    .nv-y.nv-axis .nv-axisMaxMin {
      display: none;
    }
  }
  &.spread {
    justify-content: space-between;
    padding: 1em;
    align-content: flex-start;
  }
  &.can-compress {
    min-height: 2cm;
  }
  .input-group {
    max-width: 20ch;
  }
`;

const GraphHeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 5px;
`;

const parseStatisticsResponse = (
  input,
  subscriberGroupName,
  direction,
  timePeriod,
  metric
) => {
  const [head, ...rows] = input.trim("\n").split("\n");

  let column = "";
  let networkColumn = "";
  switch (metric) {
    case "averageSpeed":
      column = direction === "downlink" ? "DOWN-MBPS" : "UP-MBPS";
      break;
    case "activeFlows":
      column = "FL-ACTIVE";
      break;
    case "flowsCreated":
      column = "FL-CREATED";
      break;
    case "latency":
      column = "SUBS-ACCESS-RTT-MS";
      networkColumn = "NETWORK-ACCESS-RTT-MS";
      break;
    case "retransmissions":
      column = "SUBS-ACCESS-RTX-%";
      networkColumn = "NETWORK-ACCESS-RTX-%";
      break;
    case "congestion":
      column = "CONGESTION-%";
      break;
    case "traffic":
      column = "TRAFFIC-AT-MAXSPEED-%";
      break;
  }
  const translations = {
    [column]: subscriberGroupName,
  };

  if (networkColumn) {
    translations[networkColumn] = "network";
  }

  const skipped = [];

  const keys = head
    .split(/\s+/)
    .map((key) =>
      skipped.includes(key) ? key : translations[key] || asJSAttribute(key)
    );
  return rows.map((row) => {
    const item = Object.fromEntries(timeAndNumbers(row, keys, skipped));
    if (metric === "flowsCreated") {
      item[subscriberGroupName] =
        item[subscriberGroupName] / timePeriodMap[timePeriod];
    }

    return item;
  });
};

export const parseFloatOrNull = (value) =>
  value === "n/a"
    ? null
    : value.endsWith("%") === true
    ? parseFloat((parseFloat(value.trim("%")) / 100).toFixed(4))
    : parseFloat(value);

const asJSAttribute = (input) =>
  input
    .split(/[\s-]+/)
    .map(lowerFirstUpperOthers)
    .join("");

const capitalizeFirstLetter = (word) =>
  word[0].toUpperCase() + word.slice(1).toLowerCase();

const lowerFirstUpperOthers = (word, index) =>
  index === 0 ? word.toLowerCase() : capitalizeFirstLetter(word);

const dateTimeParser = d3.timeParse("%Y-%m-%dT%H:%M:%S");

const timeAndNumbers = (row, keys, skipped = []) =>
  row
    .split(/\s+/)
    .map((value, index) =>
      skipped.length > 0 && skipped.includes(keys[index])
        ? null
        : [
            keys[index],
            index > 0 ? parseFloatOrNull(value) : dateTimeParser(value),
          ]
    )
    .filter((item) => item !== null);

const asItems = (items) => ({ items });

const calcTimeRange = (items) => {
  const [from, to] = d3.extent(items.map((item) => item.time));
  return { from, to };
};

const isInsideRange =
  ({ from, to }, field = "time") =>
  (item) =>
    from <= item[field] && item[field] <= to;

const timePeriodMap = {
  oneDay: 10,
  oneWeek: 10,
  oneMonth: 60,
  threeMonths: 60,
};

const hoursByTimePeriodMap = {
  oneDay: 24,
  oneWeek: 24 * 7,
  oneMonth: 24 * 30,
  threeMonths: 24 * 30 * 3,
};

const metricMap = {
  averageSpeed: "mbps",
  activeFlows: "flows",
  flowsCreated: "flows",
  latency: "latency",
  retransmissions: "retransmission",
  congestion: "congest",
  traffic: "max-speed",
};

const tooltipUnitMap = {
  averageSpeed: "mbps",
  activeFlows: "flows",
  flowsCreated: "flows/minute",
  latency: "ms",
  retransmissions: "%",
  congestion: "%",
  traffic: "%",
};

const yAxisUnitMap = {
  averageSpeed: "Mbps",
  activeFlows: "Flows",
  flowsCreated: "Flows-per-minute",
  latency: "ms",
  retransmissions: "% retransimissions",
  congestion: "% congestion",
  traffic: "% traffic at max speed",
};

const ReloadButton = styled.div`
  grid-column: -2 / span 1;
  text-align: right;
`;

const IconRefresh = styled.i`
  color: #999;
  &:hover {
    color: #000;
  }
  transition: all 0.5s;
`;

const returnView = "viewStatusSubscriberGroups";

export function SubscriberGroupsCompareChart({
  direction,
  subsGroupsToPlot,
  refreshSignal,
  metric,
  timePeriod,
  ipAddress
}) {
  const [request, setRequest] = useState(null);
  const [range, setRange] = useState();
  const [initialRange, setInitialRange] = useState();
  const [showRangeSliderInput, setShowRangeSliderInput] = useState(false);
  const fields = useRef();
  const yAxisUnits = yAxisUnitMap[metric];

  const doLoad = () => {
    if (subsGroupsToPlot === null) { return null }
    setRequest(loadStatistics());
  };

  const openSubscriberGroupDashboard = (subscriberGroup) => {
    views.doKeep(returnView);
    globalNavigate("viewStatusSubscriberGroups", {
      returnView,
      subscriberGroup,
      direction, timePeriod, metric, ipAddress
    });
  };

  useEffect(() => {
    removeTooltips();
    setShowRangeSliderInput(false);
  }, [request]);

  useEffect(() => {
    return setRange(initialRange);
  }, [initialRange]);

  useEffect(() => {
    doLoad();
  }, [metric, direction, timePeriod, subsGroupsToPlot]);

  const loadStatistics = () => {
    const start = Date.now()
    if (subsGroupsToPlot === null ) { 
      return Promise.resolve({fields: [], items: []})
    }
    const groupNames = subsGroupsToPlot;
    
    const networkIndex = groupNames.indexOf('network');
    if (networkIndex === -1 && (metric === "latency" || metric === "retransmissions")) {
      groupNames.push('network')
    }else if(networkIndex !== -1 && !(metric === "latency" || metric === "retransmissions")){
      groupNames.splice(networkIndex, 1)
    }

    const doTimeRangeSettings = (result) => {
      setInitialRange(calcTimeRange(result));
      setShowRangeSliderInput(true);
      return result;
    } 
    const parseResponseOf = subscriberGroupName => response =>
      parseStatisticsResponse(
        response,
        subscriberGroupName,
        direction,
        timePeriod,
        metric
      );
    
    const requestAndParse = (subscriberGroupName) =>
      ifCl.rush(
        `show statistics subscriber-groups name ${
          safeStr(subscriberGroupName)
        } ${
          metricMap[metric]
        } hours ${hoursByTimePeriodMap[timePeriod]} interval ${timePeriodMap[timePeriod]}`)
        .then(parseResponseOf(subscriberGroupName))
        .catch(console.error) 
    
    const combineResults = ([first, ...rest]) =>
      first === undefined
        ? []
        : doTimeRangeSettings(first).map( (current, position) => 
          Object.assign(current, ...rest.map( other => other[position])) 
        )
    const timeThis = result => {
      const took = (Date.now() - start);
      console.log(`processing loadStatistics ${groupNames.length} targets took ${took}ms ` )
      return result;
    }
    return Promise.all(groupNames.map(name => requestAndParse(name)))
      .then( combineResults )
      .then( timeThis )
      .then( items => ({
        items,
        fields: subsGroupsToPlot.map( name => ({name, label: name === 'network' ? 'Whole-network' : name})),
      }));
  }

  function removeTooltips() {
    for (const element of document.getElementsByClassName(
      "nvtooltip xy-tooltip"
    )) {
      element.remove();
    }
  }

  const formatUnit = (metric) => (value) =>
    value === null || value === undefined
      ? "n/a"
      : `${["averageSpeed"].includes(metric) ? value.toFixed(3) : value} ${
          tooltipUnitMap[metric]
        }`;

  return (
    <div className="subscriberGroups-chart">
      <GraphHeaderDiv>
        <small>
          <code>right-click</code> in the lines inside the chart or in the
          labels for each subscriber-group to be inspected in the dashboard
        </small>
        <ReloadButton className="context-menu header-dropdown m-r--5 button-reload-subs-groups-table">
          <a
            onClick={() => {
              doLoad();
            }}
            data-toggle="cardloading"
            data-loading-effect="pulse"
            title="Refresh"
          >
            <IconRefresh className="material-icons">refresh</IconRefresh>
          </a>
        </ReloadButton>
      </GraphHeaderDiv>

      <TileContainer>
        <div className="graph">
          {request === null ? null : (
            <Request during={request}>
              {({ items, fields }) => (
                  <NVLineChart
                    xField="time"
                    items={range ? items.filter(isInsideRange(range)) : items}
                    yAxisUnits={yAxisUnits}
                    xAxisFormat={d3.timeFormat("%m/%d %H:%M")}
                    fields={fields}
                    yAxisTopGap={0.05}
                    yAxisTicksNumber={5}
                    yTooltipFormat={formatUnit(metric)}
                    onSeriesInspect={(key) => openSubscriberGroupDashboard(key)}
                  />
                )
              }
            </Request>
          )}
        </div>
      </TileContainer>
      <TileContainer className="full-width can-compress">
        {showRangeSliderInput && initialRange && (
          <RangeSliderInput
            {...initialRange}
            onChange={(value) => setRange(value)}
          />
        )}
      </TileContainer>
    </div>
  );
}
