// useScreenSize.js
import { useState, useEffect, useRef, useCallback } from 'react';

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = useCallback(() => {
    setScreenSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return screenSize;
};

export const useDoubleClick = (onClick, onDbClick, delay = 300) => {
  const timePassed= useRef(0);
  let timeoutID;
  return (e) => {
   if (e.detail === 1) {
    timeoutID = setTimeout(() => {
       if (Date.now() - timePassed.current >= delay) {
         onClick();
       }
     }, delay)
   }

   if (e.detail === 2) {
     clearTimeout(timeoutID);
     timePassed.current = Date.now();
     onDbClick(e);
   }
 }
}