import * as d3 from "d3";

const littleLeft = (parts) => parts.length > 0 && parts.length < 1;

const fitsInto = (width, block) => block.node().getComputedTextLength() < width;

export function wrapText(text, width, asWords = true, dx = 0) {
  text.each(function () {
    let text = d3.select(this),
      words = text
        .text()
        .split(asWords ? /\s+/ : "")
        .reverse(),
      word,
      line = [],
      lineNumber = 0,
      lineHeight = 1.1, // ems
      x = text.attr("x"),
      y = text.attr("y"),
      dy = 0, //parseFloat(text.attr("dy")),
      tspan = text
        .text(null)
        .append("tspan")
        .attr("x", x)
        .attr("y", y)
        .attr("dy", dy + "em");
    const joiner = asWords ? " " : "";
    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(joiner));
      if (fitsInto(width, tspan) === false) {
        if (littleLeft(words)){
          tspan.text(line.join(joiner));
          line = [];
        } else {
          line.pop();
          tspan.text(line.join(joiner));
          line = [word];
          tspan = text
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", ++lineNumber * lineHeight + dy + "em")
            .attr("dx", lineNumber * dx + "em")
            .text(word);

        }
      }
    }
    //text.attr("style", `transform: translate(0, -${lineNumber / 2}em)`);
  });
}

const addClassTo = (group, className) => group.classed(className, true);

const buildGroupOn = (target, selector) =>
  selector.trim(".").split(".").reduce(addClassTo, target.append("g"));

export const provideGroupOn = (target, selector) => {
  const found = target.select(selector);
  return found.node() === null ? buildGroupOn(target, selector) : found;
};
