import { styled } from "@mui/material/styles";
import SubscriberGroups from "views/Status/SubscriberGroups/component";
import SubscriberGroupDashboard from "views/Statistics/SubscriberGroupDashboard/component";

const MainLayer = styled("div")(({addr, subsId}) => ({
    opacity: (addr || subsId) ? 0:1,
    transition: 'opacity 0.12s ease-out !important'
  }));
  
  const DetailsLayer = styled("div")(() => ({
    position: 'absolute',
  }));
  
  export function SubscriberGroupsAndDashboard({ returnView, ipAddress, addr, subsId, dpiEnabled, direction, timePeriod, metric, subscriberGroup}){
    const params = { returnView, ipAddress, addr, subsId, dpiEnabled, direction, timePeriod, metric, subscriberGroup};
    const showDetailView = !!subscriberGroup;
    return <>
    <MainLayer style={{ opacity:  showDetailView ? 0:1, transition: 'opacity 0.12s ease-out 0.5s'}}>
      <SubscriberGroups {...params}/>
    </MainLayer>
    {showDetailView && <DetailsLayer className="col-lg-12 col-md-12 col-sm-12 col-xs-12"><SubscriberGroupDashboard {...params}/></DetailsLayer>}
    </>
  }