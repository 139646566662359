import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import Request from "common/Request";
import ColumnsGrid from "common/layouts/ColumnsGrid";
import * as d3 from "d3";
import {
  parseSubscriberFlows,
  calcFlowsCreationPerMinute,
  parseSubscriberMaxSpeeds,
  parseSubscriberLatencies,
  parseSubscriberRetransmissions,
  parseTrafficCongestion,
  parseLatencyReduction,
  parseRetransmissionsReduction,
} from "./api";
import { expressTarget, retrieveRange } from "common/api";
import RangeSliderInput from "common/RangeSliderInput";
import NVLineChart from "common/graphs/NVLineChart";
import ActionsContext from "common/ActionsContext";
import SelectInputWithIcon from "common/SelectInputWithIcon";

const SubcriberDetailsContextControlsLayout = styled.div`
  display: flex;
  flex-layout: row;
  width: 100%;
  *:first-child {
    flex: 10% 1 0;
  }
  *:last-child {
    flex: 100% 1 1;
  }
  & .input-group {
    max-width: 20rem;
  }
`;

const TileContainer = styled.div`
  min-height: 10cm;
  @media print {
    & {
      height: 8cm;
      &.can-compress {
        height: auto;
      }
      width: calc(100% + 0.55cm);
      display: block:
    }
  }
  display: flex;
  flex-direction: column;
  & > h4 {
    text-align: center;
    margin: 20px 0 0 0;
  }
  & > .graph {
    flex: 1 1 100%;
    .nv-y.nv-axis .nv-axisMaxMin {
      display: none;
    }
  }
  &.spread {
    justify-content: space-between;
    padding: 1em;
    align-content: flex-start;
  }
  &.can-compress {
    min-height: 0;
  }
  .input-group {
    max-width: 20ch;
  }
`;

const ContextMenuDiv = styled.div`
  display: flex;
  align-items: center;

  i {
    color: #999;
    font-size: 20px;

    &:hover {
      color: #000;
    }
  }
`;

const H4Centered = styled.h4`
  font-size: 18px;
  margin-top: 7px;
  margin-bottom: 7px;
`;

const EVENT_HIGHLIGHT = "nv-line-chart-highlight";

const highlighHandling = (actions, facet, setHighlight) => ({
  enable: () =>
    actions.recv(
      EVENT_HIGHLIGHT,
      ({ target, event }) => target !== facet && setHighlight(event)
    ),
  report: (event) => actions.send(EVENT_HIGHLIGHT, { target: facet, event }),
});

const debounce = (delay, callback) => {
  let setoff = null;
  const waitFor = (delay) => {
    if (setoff !== null) {
      window.clearTimeout(setoff);
    }
    return new Promise((resolve) => {
      setoff = window.setTimeout(resolve, delay);
    });
  };
  return (...params) => waitFor(delay).then(() => callback(...params));
};

const ConnectedLineChart = ({ facet, ...params }) => {
  const [highligh, setHighlight] = useState(null);
  const actions = useContext(ActionsContext);
  const highlightHandler = highlighHandling(actions, facet, setHighlight);
  useEffect(highlightHandler.enable, []);
  return (
    <NVLineChart
      {...params}
      highlight={highligh}
      onHighlight={debounce(100, highlightHandler.report)}
    />
  );
};

const doesNothing = () => {};

const _previousRuns = {};

const runCommand = (command, options) => {
  const previous = _previousRuns[command];
  const currentMoment = new Date().getTime();
  if (previous && currentMoment < previous.moment + 3000) {
    return previous.execution;
  }
  const execution = ifCl.run(command, options);
  _previousRuns[command] = { moment: currentMoment, execution };
  return execution;
};

const noIPv6Mask = { ipv6Mask: false };

export const doStatsRetrieval = ({
  target = null,
  facet = "volume",
  hours = 24,
  interval = 10,
}) =>
  expressTarget(target, noIPv6Mask).then((expressedTarget) =>
    ifCl.run(
      `show statistics subscribers ${facet} ${expressedTarget} hours ${hours} interval ${interval}`
    )
  );

const _hoursScopeChoices = [
  { value: 24, label: "1 Day" },
  { value: 24 * 7, label: "1 Week" },
  { value: 24 * 31, label: "1 Month" },
  { value: 24 * 31 * 3, label: "3 Months" },
];

const SubcriberDetailsContextControls = ({
  range,
  hours,
  onChange = doesNothing,
  children,
}) => {
  const handleSelectChange = ({target}) => {
    onChange({hours: parseInt(target.value)})
  }

  return (
  <>
    <TileContainer className="full-width can-compress no-print historical-metrics-controls">
      <SelectInputWithIcon
          title="Date Range"
          name="Date Range"
          icon="date_range"
          selected={hours || 24}
          onChange={handleSelectChange}
          options={_hoursScopeChoices}
        />
    </TileContainer>
    {children}
    <TileContainer className="full-width can-compress no-print">
      <RangeSliderInput {...range} onChange={onChange} />
    </TileContainer>
  </>
)};

const isInsideRange =
  ({ from, to }, field = "time") =>
  (item) =>
    from <= item[field] && item[field] <= to;

const asItems = (items) => ({ items });

const congestionGraphSpecs = [
  {
    title: "Average reduction in latency with ACM",
    fields: [{ name: "reduction", label: "Reduction-ms" }],
    facet: "congestion-latency",
    yAxisUnits: "milliseconds",
    requestHandling: (request) =>
      request.then(parseLatencyReduction).then(asItems),
  },
  {
    title: "Average reduction in retransmissions with ACM",
    fields: [{ name: "reduction", label: "Reduction-%" }],
    facet: "congestion-retransmission",
    yAxisUnits: "% retransmissions",
    requestHandling: (request) =>
      request.then(parseRetransmissionsReduction).then(asItems),
  },
  {
    title: "Average traffic at high speed and under congestion",
    fields: [
      { name: "maxSpeed", label: "Max-speed-traffic-%" },
      { name: "congestion", label: "Congested-traffic-%" },
      { name: "acm", label: "ACM-%" },
    ],
    facet: "congestion-total",
    yAxisUnits: "% of total traffic",
    requestHandling: (request) =>
      request.then(parseTrafficCongestion).then(asItems),
  },
];

const doCongestionRequest = ({ target, hours = 1, interval = 10 }) =>
  expressTarget(target, noIPv6Mask).then((expressedTarget) =>
    ifCl.run(
      `show statistics subscribers congestion ${expressedTarget} hours ${hours} interval ${interval}`
    )
  );

const CongestionGraphs = ({ target, hours = 24, range, interval }) => {
  const [request, setRequest] = useState(null);
  useEffect(() => {
    if (target === null) {
      return;
    }
    setRequest(doCongestionRequest({ target, hours, interval }));
  }, []);

  return (
    <>
      {congestionGraphSpecs.map(
        ({ title, fields, facet, requestHandling, yAxisUnits }) => (
          <TileContainer key={title} className="chart-container">
            <h4 className="chart-title">{title}</h4>
            <div className="graph">
              {request === null ? null : (
                <Request during={requestHandling(request)}>
                  {({ items }) => (
                    <ConnectedLineChart
                      facet={facet}
                      items={items.filter(isInsideRange(range))}
                      xField="time"
                      yAxisUnits={yAxisUnits}
                      xAxisFormat={d3.timeFormat("%m/%d %H:%M")}
                      yAxisTopGap={0.1}
                      fields={fields}
                    />
                  )}
                </Request>
              )}
            </div>
          </TileContainer>
        )
      )}
    </>
  );
};

const FacetGraph = ({
  facet,
  target,
  title,
  hours = 24,
  range,
  interval,
  units = "",
  fields,
  parse,
  yAxisFormat = null,
  ...rest
}) => {
  const wrapper = useRef(null);
  const [request, setRequest] = useState(null);
  useEffect(() => {
    if (target === null || wrapper.current === null) {
      return;
    }
    setRequest(
      doStatsRetrieval({ facet, target, hours, interval })
        .then(parse)
        .then(asItems)
    );
  }, []);
  return (
    <TileContainer className="chart-container">
      <h4 className="chart-title">{title}</h4>
      <div className="graph" ref={wrapper}>
        {request === null ? null : (
          <Request during={request}>
            {({ items }) => (
              <ConnectedLineChart
                facet={facet}
                items={items.filter(isInsideRange(range))}
                xField="time"
                yAxisUnits={units}
                xAxisFormat={d3.timeFormat("%m/%d %H:%M")}
                yAxisTopGap={0.1}
                yAxisFormat={yAxisFormat}
                fields={fields}
                {...rest}
              />
            )}
          </Request>
        )}
      </div>
    </TileContainer>
  );
};

const parseAndCalcFlowsCreationPerMinute = (response) =>
  calcFlowsCreationPerMinute(parseSubscriberFlows(response));

const formatMillions = (value) =>
  value === null ? 'n/a' :
  value < 1000
    ? value
    : value < 1000000
    ? `${(value / 1000).toFixed(1)}K`
    : `${(value / 1000000).toFixed(1)}M`;

const SubscriberAnalyticsResult = ({
  target,
  range: initialRange,
  interval = 10,
  hours = 24,
  onHoursChange = doesNothing,
}) => {
  const [range, setRange] = useState(initialRange);
  const applyRange = (newRange) => setRange(newRange);
  const applyChange = ({ hours, ...range }) => {
    if (hours !== undefined) {
      onHoursChange(hours);
    } else {
      applyRange(range);
    }
  };

  return (
    <ColumnsGrid gap="1rem" columns={2} minWidth="9cm" className="historical-metrics-content">
      <SubcriberDetailsContextControls
        target={target}
        range={initialRange}
        hours={hours}
        onChange={applyChange}
      >
        <FacetGraph
          target={target}
          facet="speed"
          title="Average Speed"
          units="Mbps"
          fields={[
            { name: "downMbPerSec", label: "Down-Mbps" },
            { name: "upMbPerSec", label: "Up-Mbps" },
          ]}
          parse={parseSubscriberMaxSpeeds}
          range={range}
          hours={hours}
          interval={interval}
        />
        <FacetGraph
          target={target}
          facet="max-speed"
          title="Maximum Speed"
          units="Mbps"
          fields={[
            { name: "downMbPerSec", label: "Down-Mbps" },
            { name: "upMbPerSec", label: "Up-Mbps" },
          ]}
          parse={parseSubscriberMaxSpeeds}
          range={range}
          hours={hours}
          interval={interval}
        />
        <FacetGraph
          target={target}
          facet="latency"
          title="Latency"
          units="milliseconds"
          fields={[
            { name: "subsAccessRttMs", label: "Subs-Access-Rtt-Ms" },
            { name: "networkAccessRttMs", label: "Network-Access-Rtt-Ms" },
          ]}
          parse={parseSubscriberLatencies}
          range={range}
          hours={hours}
          interval={interval}
        />
        <FacetGraph
          target={target}
          facet="retransmission"
          title="Packet retransmissions"
          units="% retransmisions"
          fields={[
            { name: "subsAccessRtx", label: "Subs-Access-Rtx-%" },
            { name: "networkAccessRtx", label: "Network-Access-Rtx-%" },
          ]}
          parse={parseSubscriberRetransmissions}
          range={range}
          hours={hours}
          interval={interval}
        />
        <CongestionGraphs
          target={target}
          range={range}
          hours={hours}
          interval={interval}
        />
        <FacetGraph
          target={target}
          facet="flows"
          title="Flows"
          units="Flows"
          fields={[
            { name: "flCreatedPerMinute", label: "Fl-Created-per-minute" },
            { name: "flActive", label: "Fl-Active" },
          ]}
          parse={parseAndCalcFlowsCreationPerMinute}
          range={range}
          hours={hours}
          interval={interval}
          yAxisFormat={formatMillions}
          yTooltipFormat={d3.format(".0f")}
          yAxisTicksNumber={4}
        />
      </SubcriberDetailsContextControls>
    </ColumnsGrid>
  );
};

const asRange = range => ({range});

const ControlsContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 0 0 20px 0;
  justify-content: space-between;
  align-items: center;
`;

const SubscriberAnalyticContextRequest = ({target, context, onHoursChange=doesNothing}) => {
  const [request, setRequest] = useState(null);
  const doLoad = () => {
    setRequest(context.hours ? retrieveRange(context.hours).then(asRange) : null);
  };

  const targetStr = target ? (target.addr ? target.addr : target.subsId) : "";
  const contextStr = '' + context.hours + context.interval

  useEffect(() => {
    if (targetStr) {
      doLoad();
    }
  }, [contextStr, targetStr]);


  return request === null ? null : (
    <Request during={request}>
      {({range}) => (
        <>
          <ControlsContainer className="historical-metrics-header">
            <H4Centered className="subtitle"> Historical Metrics </H4Centered>
            <ContextMenuDiv className="context-menu">
              <a
                onClick={doLoad}
                data-toggle="cardloading"
                data-loading-effect="pulse"
                title="Refresh"
              >
                <i className="material-icons">
                  refresh
                </i>
              </a>
            </ContextMenuDiv>
          </ControlsContainer>
          <SubscriberAnalyticsResult
            range={range}
            {...context}
            target={target}
            onHoursChange={onHoursChange}
          />
        </>
      )}
    </Request>
  );

}

const _defaultContext = {
  hours: 24,
  interval: 10,
};

const intervalForHours = (hours, interval = 10) =>
  hours > 7 * 24 ? 60 : interval;

const SubscriberAnalyticContext = ({ target }) => {
  const [context, setContext] = useState(_defaultContext);
  const setHoursInContext = (hours) =>
    setContext({ hours, interval: intervalForHours(hours) });
  return <SubscriberAnalyticContextRequest
    target={target} context={context}
    onHoursChange={setHoursInContext}
  />
};
export default SubscriberAnalyticContext;
