import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import Table from "./table";
export { atoi, itoa } from "./table";

const TableContainer = styled.div``;

const tabulatedToArray = function (row) {
  return row.trim().split(/\s+/);
};

export const createPaginatorWithContent = (content, columns, filters = []) => {
  const [header, ...rows] = content.trim("\n").split("\n");
  return new Table.Paginator(
    columns,
    rows,
    filters,
    header.trim("s").split(/\s+/)
  );
};

const providePaginator = (content = undefined, columns = [], filters = []) => {
  if (content === undefined) {
    throw Error("Table requires a content or a valid paginator");
  }
  return createPaginatorWithContent(content, columns, filters);
};

const translateToIndex = (order, columns) =>
  order.map(([name, direction]) => [
    columns.findIndex(
      (column) => column.name === name || column.label === name
    ),
    direction,
  ]);

const DataTable = ({
  content = "",
  columns = [],
  order = [],
  pageLength = 50,
  paginator = undefined,
  exportAsCSV = false,
  filters = [],
  drawCallback = undefined,
  page=0,
  children
}) => {
  const [tableRef, tableContainerRef, paginatorInstance] = [
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  useEffect(() => {
    if (tableRef.current === null) {
      return;
    }
    const htmlOnly = false;
    paginator = paginator || providePaginator(content, columns, filters);
    paginatorInstance.current = paginator;
    const provider = (...params) => paginator.request(...params);
    const options = {
      htmlOnly,
      provider,
      pageLength,
      order: translateToIndex(order, columns),
      drawCallback,
    };
    const table = new Table(tableRef.current, columns, options);
    table.render();
    $(tableRef.current).DataTable().page(page).draw('page')
    exportAsCSV === true && table.enableCSVExport(paginator);

    if (tableContainerRef.current) {
      tableContainerRef.current.style.height = "unset";
      tableContainerRef.current.style.width = "unset";
    }

    return () => {
      if(tableContainerRef.current){
        tableContainerRef.current.style.height = tableContainerRef.current.clientHeight + 'px';
        tableContainerRef.current.style.width = tableContainerRef.current.getElementsByTagName('table')[0].clientWidth + 'px';
      }
      table.clear()
    };
  }, [tableRef, columns]);

  return (
    <TableContainer ref={tableContainerRef} className="table-container">
      <table className="table" ref={tableRef}></table>
    </TableContainer>
  );
};
export const Column = Table.Column;

export default DataTable;
