/*globals views*/
import React, { useEffect, useState } from "react";
import { checkLicense } from "common/api";
import Request from "common/Request";
import styled from "styled-components";

const Centered = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  button {
    margin: 1.5em;
  }
`;
const DefaultNotAllowed = ({ returnView = null }) => (
  <Centered>
    <h4>License Not valid</h4>
    {returnView === null ? null : (
      <button
        type="button"
        className="btn btn-link waves-effect"
        onClick={() => globalNavigate(returnView)}
      >
        Return
      </button>
    )}
  </Centered>
);

const doRequestStatements = () => ifCl.run("show system alarms");

const CheckLicenseFirst = ({
  children,
  NotAllowed = DefaultNotAllowed,
  ...args
}) => {
  const [request, setRequest] = useState(null);
  const doLoad = () => {
    setRequest(
      doRequestStatements()
        .then(checkLicense)
        .then( valid  => ({valid}) )
        .catch(console.error)
    );
  };
  useEffect(() => {
    doLoad();
  }, []);
  return request === null ? null : (
    <Request during={request}>
      {({ valid }) => (valid === true ? children : <NotAllowed {...args} />)}
    </Request>
  );
};

export default CheckLicenseFirst;
