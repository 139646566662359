import React, { useState, useContext, useEffect, useRef } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";
import styled from "styled-components";
import Canvas from "common/graphs/Canvas";
import Axis, { remaining } from "common/graphs/Axis";
import { BoxPlotWrapper } from "./BoxPlotGroup";
import * as d3 from "d3";
import BoxPlot, { DEFAULT_MARGIN } from "common/graphs/BoxPlot";

const addGap = (coefficient, values) => {
  const [min, max] = d3.extent(values);
  const gap = ((max - min) * coefficient) / 2;
  return [min - gap, max + gap];
};

const givenRange = ([start, end]) => {
  const point = (ratio = 0.5) => start + (end - start) * ratio;
  const endingPart = (ratio = 0.5) => [point(ratio), end];
  const beginingPart = (ratio = 0.5) => [start, point(ratio)];
  return { point, endingPart, beginingPart };
};
export const StyledG = styled.g`
  rect.frame {
    stroke: currentColor;
    & + path.domain {
      stroke: none;
    }
  }
  .units {
    fill: currentColor;
    font: normal 10px Arial, sans-serif;
    text-anchor: middle;
  }
  .warning-zone .value {
    font: normal 10px Arial, sans-serif;
  }
  .plot {
    fill: white;
    transition: fill 1s, stroke 1s;
    .warning-zone .value {
      text-anchor: start;
    }
    .cardinal line {
      fill: currentColor;
    }
    .bracket {
      text {
        fill: currentColor;
        dominant-baseline: central;
        text-anchor: start;
      }
      path {
        &.q1-median {
          stroke: var(--is-lower);
          fill: none;
        }
        &.median-q3 {
          stroke: var(--is-higher);
          fill: none;
        }
      }
    }
    .cardinal {
      line.median,
      text.median {
        stroke: currentColor;
      }
      text {
        stroke: none !important;
        fill: currentColor;
        dominant-baseline: alphabetic;
        text-anchor: end;
        transform: translate(0, -0.2em);
        &.hanging {
          dominant-baseline: hanging;
          transform: translate(0, 0.2em);
        }
      }
    }
  }
`;

function makeCurlyBrace(x1, y1, x2, y2, w = 16, q = 0.6) {
  //Calculate unit vector
  let dx = x1 - x2;
  let dy = y1 - y2;
  const len = Math.sqrt(dx * dx + dy * dy);
  dx = dx / len;
  dy = dy / len;

  //Calculate Control Points of path,
  const qx1 = x1 + q * w * dy;
  const qy1 = y1 - q * w * dx;
  const qx2 = x1 - 0.25 * len * dx + (1 - q) * w * dy;
  const qy2 = y1 - 0.25 * len * dy - (1 - q) * w * dx;
  const tx1 = x1 - 0.5 * len * dx + w * dy;
  const ty1 = y1 - 0.5 * len * dy - w * dx;
  const qx3 = x2 + q * w * dy;
  const qy3 = y2 - q * w * dx;
  const qx4 = x1 - 0.75 * len * dx + (1 - q) * w * dy;
  const qy4 = y1 - 0.75 * len * dy - (1 - q) * w * dx;

  return (
    "M " +
    x1 +
    " " +
    y1 +
    " Q " +
    qx1 +
    " " +
    qy1 +
    " " +
    qx2 +
    " " +
    qy2 +
    " T " +
    tx1 +
    " " +
    ty1 +
    " M " +
    x2 +
    " " +
    y2 +
    " Q " +
    qx3 +
    " " +
    qy3 +
    " " +
    qx4 +
    " " +
    qy4 +
    " T " +
    tx1 +
    " " +
    ty1
  );
}

const formaDecimal = d3.format(".1f");

const quartilesBrackets = [
  { from: "min", to: "q1", label: "25%", classed: "min" },
  { from: "min", to: "median", label: "50%", classed: "q1-median" },
  { from: "min", to: "q3", label: "75%", classed: "median-q3" },
  { from: "min", to: "max", label: "100%", classed: "max" },
];

const quartilesCardinals = [
  { name: "max", label: "Maximun" },
  { name: "median", label: "Median" },
  { name: "min", label: "Minimun" },
];

const thresholdCardinals = [
  { name: "max", label: "Maximun" },
  { name: "min", label: "Minimun" },
];

const getThresholdBrackets = (inverted) => [
  {
    ...(inverted
      ? {
          from: "threshold",
          to: "max",
        }
      : {
          from: "min",
          to: "threshold",
        }),

    label: "Warning threshold",
    classed: "warning-zone",
  },
];

const Description = ({
  canvas,
  data = {},
  axis = {},
  xFrom = "x",
  yFrom = "y",
  dimensions = null,
  margin = DEFAULT_MARGIN,
  precision = 2,
  units = undefined,
  format = (d) => (d % 1 ? formaDecimal(d) : d),
  label,
  brackets = quartilesBrackets,
  cardinals = quartilesCardinals,
  ...scaleParams
}) => {
  const chart = useRef(null);
  label =
    label !== undefined
      ? label
      : (d) => (d.value === null ? "--" : d.value.toFixed(precision));
  useEffect(() => {
    if (dimensions === null) {
      return;
    }
    const { width, height } = remaining(dimensions, margin);
    if (width < 1 || height < 1) {
      return;
    }

    const xAxis = axis[xFrom];
    const yAxis = axis[yFrom];
    const xScale = xAxis.scale;
    const yScale = yAxis.scale;

    const [left, right] = xScale.range();
    const center = (right - left) / 2;
    const thickness = d3.max([(right - left) / 4, 20]);

    const main = d3.select(chart.current);
    const plot = main.append("g").attr("class", "plot");
    const overX = givenRange(xScale.range());

    const newCardinals = plot
      .selectAll(".cardinal")
      .data(cardinals)
      .enter()
      .append("g")
      .attr("class", "cardinal");
    newCardinals
      .append("line")
      .attr("class", ({ name }) => name)
      .attr("x1", ({ name }) => overX.point(name === "median" ? 0.75 : 0.5))
      .attr("y1", ({ name }) => yScale(data[name]))
      .attr("x2", right)
      .attr("y2", ({ name }) => yScale(data[name]));
    newCardinals
      .append("text")
      .attr(
        "class",
        ({ name }, index) => `cardinal ${name} ${index === 0 ? "hanging" : ""}`
      )
      .text(({ label }) => label)
      .attr("x", right)
      .attr("y", ({ name }) => yScale(data[name]));

    const bracketsPosition = d3
      .scaleBand()
      .domain(brackets.map((_, i) => i))
      .range(overX.beginingPart(0.6))
      .paddingInner(0.6);
    const newBrackets = plot
      .selectAll(".bracket")
      .data(brackets)
      .enter()
      .append("g")
      .attr("class", "bracket");
    newBrackets
      .append("path")
      .attr("d", ({ from, to }, index) =>
        makeCurlyBrace(
          bracketsPosition(index),
          yScale(data[from]),
          bracketsPosition(index),
          yScale(data[to])
        )
      )
      .attr("class", ({ classed }) => classed)
      .attr("stroke", "black");

    newBrackets
      .append("text")
      .text((d) => d.label)
      .attr("y", ({ from, to }) => (yScale(data[from]) + yScale(data[to])) / 2)
      .attr(
        "x",
        (_, index) => bracketsPosition(index) + bracketsPosition.bandwidth()
      );

    return () => {
      main.selectAll(".plot").remove();
      main.selectAll(".axis").remove();
    };
  }, [chart, canvas, dimensions, axis]);

  return <StyledG ref={chart}></StyledG>;
};

const PlotAndExplanation = styled.div`
  display: flex;
  flex-direction: row;
  .demo.group-boxplot {
    flex: 4cm 0 0;
  }
  .description {
    flex: 100% 1 1;
  }
`;

const thresholdData = (data, inverted) => ({
  ...data,
  inverted,
  threshold: givenRange([data.min, data.max]).point(inverted ? 0.8 : 0.2),
});

const BoxPlotDemo = ({
  data = {
    min: 10,
    max: 1000,
    median: 500,
    q3: 700,
    q1: 200,
  },
  margin = DEFAULT_MARGIN,
  inverted = false,
  colorizeQuartiles = false,
  units = undefined,
  format = undefined,
  yGap = 0.1 /*10%*/,
}) => (
  <PlotAndExplanation>
    <BoxPlotWrapper
      className={`demo group-boxplot ${
        inverted === true ? "invert-colors" : ""
      } ${colorizeQuartiles ? "colorize-quartiles" : "colorize-warnings"}`}
    >
      <Canvas>
        <Axis
          margin={{ ...margin, right: 0 }}
          data={colorizeQuartiles ? data : thresholdData(data, inverted)}
          are={[
            {
              name: "x",
              type: "band",
              bandWidth: "30px",
              along: "width",
              value: (d) => d,
              values: [1],
            },
            {
              name: "y",
              along: "height",
              values: addGap(yGap, [data.min, data.max, data.threshold]),
            },
          ]}
        >
          <BoxPlot
            units={units}
            format={format}
            frameless={true}
            widthRatio={0.6}
          />
        </Axis>
      </Canvas>
    </BoxPlotWrapper>
    <BoxPlotWrapper
      className={`description group-boxplot ${
        inverted === true ? "invert-colors" : ""
      } ${colorizeQuartiles ? "colorize-quartiles" : "colorize-warnings"}`}
    >
      <Canvas>
        <Axis
          margin={{ ...margin, left: 5 }}
          data={colorizeQuartiles ? data : thresholdData(data, inverted)}
          are={[
            {
              name: "x",
              type: "band",
              bandWidth: "30px",
              along: "width",
              value: (d) => d,
              values: [1],
            },
            {
              name: "y",
              along: "height",
              values: addGap(yGap, [data.min, data.max, data.threshold]),
            },
          ]}
        >
          {colorizeQuartiles === true ? (
            <Description
              {...{ units, format, inverted }}
              brackets={quartilesBrackets}
              cardinals={quartilesCardinals}
            />
          ) : (
            <Description
              {...{ units, format, inverted }}
              brackets={getThresholdBrackets(inverted)}
              cardinals={thresholdCardinals}
            />
          )}
        </Axis>
      </Canvas>
    </BoxPlotWrapper>
  </PlotAndExplanation>
);

const StatisticsHelpModal = () => {
  const [open, setOpen] = useState(false);
  const [settings, setSettings] = useState({});
  const actions = useContext(ActionsContext);
  useEffect(() => {
    return actions.recv("open-statistics-help", (params = {}) => {
      setSettings(params);
      setOpen(true);
    });
  }, []);

  const doClose = () => {
    setSettings({});
    setOpen(false);
  };
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title={`SUBSCRIBER STATISTICS BOX-PLOTS: ${
        settings.colorizeQuartiles ? "DISTRIBUTION" : "THRESHOLDS"
      }`}
      onClose={doClose}
      content={() => (
        <>
          <p>
            {settings.colorizeQuartiles
              ? `This chart is a boxplot and briefly describes the distribution of a
            set of measurements.`
              : `This chart is a boxplot and briefly describes the distribution of a
            set of measurements and its presence inside warning a threshold.`}
          </p>
          <BoxPlotDemo {...settings} />
        </>
      )}
      onClose={doClose}
    />
  );
};
export default StatisticsHelpModal;
