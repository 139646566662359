import React, { useContext, useState, useEffect } from "react";
import ActionsContext from "common/ActionsContext";
import Request from "common/Request";
import {
  parseShapingValues,
  validAsRateInKBPS,
  composeShapingUpdateCommand,
} from "./api";
import styled from "styled-components";
import UpdateValueModal from "./Update";
import {safeStr} from "common/api";

const EditClientButton = ({ onClick = doesNothing }) => (
  <a className="waves-effect borderless action" onClick={onClick}>
    <i className="material-icons">edit</i>
  </a>
);

const doesNothing = () => {};

const ShowValueLayout = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between; 
  h5 {
    margin-right: .5em;
  }
  .value {
    display: flex;
    flex-flow: row;
    align-items: center;
  }
  a {
    margin-left: .25em;
  }
`;
const noResponseIsExpected = (response) => {
  if (response !== undefined && response.length > 0) {
    throw response;
  }
  return true;
};

export const doUpdateShaping = (params) =>
  Promise.resolve(composeShapingUpdateCommand(params))
    .then((command) => ifCl.run(command))
    .then(noResponseIsExpected);

export const validate = ({ value, field }) => ({
  value: validAsRateInKBPS(value),
  field,
});

const applyOn =
  (subscriberGroup, onSaveDone = doesNothing) =>
  ({ field, value }) =>
    doUpdateShaping({ subscriberGroup, direction: field, value }).then(
      onSaveDone
    );

const ShowValue = ({
  field,
  label,
  value = undefined,
  disabled = false,
  onClickEdit = doesNothing,
  units = 'Mbps',
}) => (
  <ShowValueLayout>
    <h5>{label}:</h5>
    <span className="value">
      {value === undefined ? "n/a" : `${value} ${units}`}
      <EditClientButton
        disabled={disabled}
        onClick={() => onClickEdit(field, value)}
      />
    </span>
  </ShowValueLayout>
);

const ShapingSettingsValues = ({
  upRate,
  downRate,
  disabled = false,
  subscriberGroup,
  onClickEdit = { doesNothing },
  onSaveDone = doesNothing,
}) => (
  <>
    <div className="flex-panel-column col-lg-3 col-md-5 col-sm-12 padding-l-0 padding-r-0 margin-0">
      <ShowValue
        value={downRate}
        field="downlink"
        disabled={disabled}
        onClickEdit={onClickEdit}
        label="Downlink rate limit"
      />
    </div>
    <div className="flex-panel-column col-lg-1 col-md-2 col-sm-0 padding-l-0 padding-r-0 margin-0"></div>
    <div className="flex-panel-column col-lg-3 col-md-5 col-sm-12 padding-l-0 padding-r-0 margin-0">
      <ShowValue
        value={upRate}
        field="uplink"
        disabled={disabled}
        onClickEdit={onClickEdit}
        label="Uplink rate limit"
      />
    </div>
    <UpdateValueModal
      doValidate={validate}
      doSave={applyOn(subscriberGroup, onSaveDone)}
    />
  </>
);

const retrieveValues = (subscriberGroup) =>
  ifCl
    .run(`show api subscriber-group name ${safeStr(subscriberGroup)} shaping`)
    .then(parseShapingValues)
    .catch((error) => console.error(error) || { disabled: true });

const ShapingSettings = ({ subscriberGroup }) => {
  const [request, setRequest] = useState(null);
  const actions = useContext(ActionsContext);
  const doLoad = () => {
    setRequest(retrieveValues(subscriberGroup));
  };
  useEffect(() => doLoad() || actions.recv("do-load", doLoad), []);
  const onClickEdit = (field, initial) =>
    actions.send("edit-shaping-value", { field, initial });
  const onSaveDone = () => actions.send("do-load");
  return (
    <>
      {request === null ? null : (
        <Request during={request}>
          {({ disabled = false, ...values }) =>
            disabled === true ? null : (
              <ShapingSettingsValues
                {...values}
                subscriberGroup={subscriberGroup}
                onClickEdit={onClickEdit}
                onSaveDone={onSaveDone}
              />
            )
          }
        </Request>
      )}
    </>
  );
};
export default ShapingSettings;
