import { useEffect, useState } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  useParams,
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
import ActionableView from "./common/ActionableView";
import VerifiedSubcriberDetail from "views/Statistics/SubscriberDetail/component";
import {SubscriberGroupsAndDashboard} from "views/Status/SubscriberGroups/SubscriberGroupsAndDashboard";
import {SubscribersAndDetails} from "views/Status/Subscribers/SubscribersAndDetails";
import SubscriberGroupDashboard from "views/Statistics/SubscriberGroupDashboard/component";
import BtaTraffic from "views/Statistics/Throughput/BtaTraffic/component";
import Interfaces from "views/Statistics/Throughput/Interfaces/component";
import SubscriberGroupEdit from "views/Status/SubscriberGroupsEdit/component";

function toKeyValuePairs(input) {
  const result = {};
  for (let [key, value] of input.entries()) {
    result[key] = value;
  }

  return result;
}

const router = createBrowserRouter([
  {
    path: "/viewIfaceStkStats",
    element: <RouteAdapter Component={Interfaces} />,
  },
  {
    path: "/viewBtaStatsTraffic",
    element: <RouteAdapter Component={BtaTraffic} />,
  },
  {
    path: "/viewStatusSubscribers",
    element: <RouteAdapter Component={SubscribersAndDetails} />,
  },
  {
    path: "/viewSubsDetail",
    element: <RouteAdapter Component={VerifiedSubcriberDetail} />,
  },
  {
    path: "/viewStatusSubscriberGroups",
    element: <RouteAdapter Component={SubscriberGroupsAndDashboard} />,
  },
  {
    path: "/viewSubscriberGroupDashboard",
    element: <RouteAdapter Component={SubscriberGroupDashboard} />,
  },
  {
    path: "/viewStatusSubscriberGroupsEdit",
    element: <RouteAdapter Component={SubscriberGroupEdit} />,
  },
  {
    path: "/:view?",
    element: <RouteAdapter />,
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}

function RouteAdapter({ Component }) {
  const [ready, setReady] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();

  function filterParams(input) {
    const output = {};
    for (const [key, value] of Object.entries(input)) {
      if (value !== undefined && value !== null && key !== "dpiEnabled") {
        output[key] = value;
      }
    }

    return output;
  }

  function navigateWithQueryParams(path, queryParams) {
    if(location.pathname === '/viewDashboard'){
      window.unloadDashboardView();
    }
    
    navigate({
      pathname: "/" + path,
      search: createSearchParams(
        queryParams ? filterParams(queryParams) : undefined
      ).toString(),
    });
  }

  function navigateBack() {
    navigate(-1);
  }

  function updateUI() {
    $("#navigationBar").show();
    $("#sectSidebars").show();
    $("#sectContent").hide();
    $("#viewLogin").hide();
    window.views.setMenuActive("#menu" + location.pathname.substring(5));
  }

  useEffect(() => {
    window.globalNavigate = navigateWithQueryParams;
    window.globalNavigateBack = navigateBack;
  }, []);

  useEffect(() => {
    setReady(false);

    const timeout = window.subsStatsMetrics === undefined ? 500 : 0;

    setTimeout(() => {
      const isLogged = window.login.isLogged();
      if (location.pathname !== "/viewLogin" && !isLogged) {
        return navigate({
          pathname: "/viewLogin",
          search: createSearchParams({
            url: location.pathname.slice(1),
          }).toString(),
        });
      } else if (location.pathname === "/" && location.hash === '' && isLogged) {
        return navigate("/viewDashboard");
      }

      if (Component) {
        setReady(true);
        updateUI();
      } else if (params.view && (params.view === "viewLogin" || isLogged)) {
        const dpiFunctionality = window.functionality.isEnabled("dpi-per-user");
        window.views.toggle(params.view, {
          ...toKeyValuePairs(searchParams),
          dpiEnabled: dpiFunctionality,
        });
      }else if(location.hash !== ''){
        location.href = location.href.replace('#', '');
      }
    }, timeout);
  }, [params.view]);

  useEffect(() => {
    updateUI();
  }, [Component]);

  if (!ready || !Component) {
    return null;
  }

  const dpiEnabled = window.functionality.isEnabled("dpi-per-user");

  return (
    <ActionableView
      Component={Component}
      {...toKeyValuePairs(searchParams)}
      dpiEnabled={dpiEnabled}
    />
  );
}
