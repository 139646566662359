/* global nv, NO_DATA_AVAILABLE*/
import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import * as d3 from "d3";
import { tooltipContentGeneratorForUnits } from "common/graphs/NVStackedAreaChart";
import { mayExpandLinearScale } from "common/graphs/NVLineChart";
import Loading from "common/Loading";

const WrapperDIV = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  .row {
    margin: 0 !important;
    position: absolute;
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > svg {
    width: 100%;
    height: 100%;
    min-height: 500px;
   
    .nv-background rect {
      stroke: var(--chart-inner-border-color, #000);
      stroke-opacity: 0.75;
    }
    .nvd3 .nv-axis path.domain {
      stroke-opacity: 0;
    }
    .nv-background rect {
      pointer-events: all;
    }
`;
const givenExtent = (min = null, max = null) => ({
  annotate: (value) => {
    if (value === null) {
      return value;
    }

    min = min === null || value < min ? value : min;
    max = max === null || value > max ? value : max;
    return value;
  },
  range: () => [min, max],
});

const splitByFields = ([xField, ...fields], items, range = [0, null]) => {
  const extent = givenExtent(...range);
  const lanes = fields.map(({ name, label, color = undefined, area }) => ({
    key: label,
    values: items.map((item) => [item[xField], extent.annotate(item[name])]),
    ...(color === undefined ? {} : { color }),
    area,
  }));
  return [lanes, extent.range()];
};

const Chart = ({
  margin = { top: 20, right: 40, bottom: 30, left: 60 },
  items,
  fields,
  fixedYRange,
  yAxisFormat,
  yTooltipFormat,
  threshold,
  hasLimit,
}) => {
  const [wrapper, canvas] = [useRef(null), useRef(null)];
  const [loading, setLoading] = useState(false);
  let chart = null;
  let _initialDimmensions = null;
  let resizeObserver = null;
  const xField = "time";
  const tooltipGen = tooltipContentGeneratorForUnits("Mbps", yAxisFormat, true, false);
  const render = (target) => {
    if(items.length!==0){
      setLoading(true);  
    }
    nv.addGraph(function () {
      if (chart === null) {
        chart = nv.models
          .lineChart()
          .margin(margin)
          .x((d) => d[0])
          .y((d) => d[1])
          .duration(0)
          .useInteractiveGuideline(true) // This affects tooltip behavior
          .noData(NO_DATA_AVAILABLE)
          .duration(0)
          .yScale(
            mayExpandLinearScale(d3.scaleLinear(), {
              topGap: 0.05,
            })
          )
          .xScale(d3.scaleTime())
          .showLegend(true);
        chart.interactiveLayer.tooltip.enabled(true);
        chart.interactiveLayer.tooltip.hideDelay(0);
        chart.interactiveLayer.tooltip.gravity("w");
        chart.interactiveLayer.tooltip.contentGenerator(tooltipGen);
        chart.xAxis.tickFormat(d3.timeFormat("%m/%d %H:%M")).ticks(8);
        chart.yAxis.tickFormat(yAxisFormat);
        chart.yAxis.axisLabel("Mbps");
        chart.yAxis.ticks(5);
        chart.forceY(fixedYRange);
        chart.dispatch.on("renderEnd", () => {
          setLoading(false);
        });
        if (yTooltipFormat !== null) {
          chart.interactiveLayer.tooltip.valueFormatter(yTooltipFormat);
        }
      }
      const [datum, [yMin, yMax]] = splitByFields([xField, ...fields], items, [
        null,
        0,
      ]);
      d3.select(canvas.current).datum(datum);
      const [_yDomainMin, yDomainMax] = chart.yAxis.domain();
      d3.select(canvas.current).call(chart);
      if (resizeObserver === null && chart) {
        resizeObserver = new ResizeObserver(() => chart.update());
        if (target) {
          resizeObserver.observe(target);
        }
      }

      if (hasLimit && threshold) {
        const nvChart = d3.select(canvas.current).select(".nvd3.nv-lineChart");
        const thresholdVal = chart.yAxis.scale()(threshold);
        const xPos = chart.xAxis.range()[1];
        const licLimit = nvChart
        .append("line")
        .style("stroke", 'var(--signal-red)')
        .style("stroke-width", 1)
        .style("opacity", 0.6)
        .attr("x1", chart.xAxis.range()[0])
        .attr("y1", thresholdVal)
        .attr("x2", xPos)
        .attr("y2", thresholdVal);

        nvChart.append('text')
            .text('License')
            .style('stroke', 'var(--signal-red)')
            .style('font-size', '10px')
            .style('opacity', 0.6)
            .attr('x', (xPos-50))
            .attr('y', (thresholdVal -6));
      }

      d3.select(canvas.current).select(".nv-background rect").attr("rx", 9);

      const main = d3.select(canvas.current);

      return chart;
    });
  };
  useEffect(() => {
    const target = wrapper.current;
    if (items) {
      return render(target);
    } else {
      () => {};
    }
    return () => {
      resizeObserver && resizeObserver.unobserve(target);
    };
  }, [items, fields]);

  return (
    <WrapperDIV ref={wrapper}>
      {loading ? <Loading /> : null}
      <svg ref={canvas}></svg>
    </WrapperDIV>
  );
};

export default Chart;
