import React from "react";
import FilteringControls from "./FilteringControls";
import TimeEvolutionMetricsChart from "./Chart";
import styled from "styled-components";
import Request from "common/Request";

const ResultsLayout = styled.div`
  flex: 100%;
  overflow: hidden; /*list will scroll*/
`;

const InnerTimeEvolutionMetrics = ({
  direction,
  timePeriod,
  metric,
  refreshSignal,
  entity,
  subscribersToPlot,
}) => {
  return (
    <ResultsLayout>
      <TimeEvolutionMetricsChart
        direction={direction}
        timePeriod={timePeriod}
        metric={metric}
        entity={entity}
        subscribersToPlot={subscribersToPlot}
        refreshSignal={refreshSignal}
      />
    </ResultsLayout>
  );
};

const TimeEvolutionMetrics = ({
  request,
  direction,
  subscribersToPlot,
}) => (
  <Request during={request}>
    {() => (
      <FilteringControls>
        {(settings) => (
          <InnerTimeEvolutionMetrics
            direction={direction}
            subscribersToPlot={subscribersToPlot}
            {...settings}
          />
        )}
      </FilteringControls>
    )}
  </Request>
);

export default TimeEvolutionMetrics;
