import React from "react";
import styled from "styled-components";

const TextInputDiv = styled.div`
  .suffix {
    display: table-cell;
    padding: 6px 0;
  }
`;

const TextInput = ({
  onChange,
  name,
  label = "Text",
  type = "text",
  disabled = false,
  className = "",
  placeholder = null,
  defaultValue,
  icon = undefined,
  autocomplete = "off",
  suffix = undefined,
  addon = undefined,
}) => (
  <TextInputDiv className={`input-group ${className}`}>
    <span className="input-group-addon padding-t-0">
      {icon !== undefined ? (
        <div className="icon-label">
          <i className="material-icons">{icon}</i>
        </div>
      ) : null}
      {icon !== undefined ? (
        <div className="label-icon">{label}</div>
      ) : (
        <div className="no-class">{label}</div>
      )}
    </span>
    <div className="form-line">
      <input
        type={type}
        name={name}
        onKeyUp={onChange}
        disabled={disabled}
        defaultValue={defaultValue}
        placeholder={placeholder}
        className="form-control"
        autoComplete={autocomplete}
      />
      {suffix !== undefined
        && (<span className="suffix">{suffix}</span>)
        || null
      }
    </div>
      {addon !== undefined
        && (<span className="input-group-addon">{addon}</span>)
        || null
      }
  </TextInputDiv>
);

export default TextInput;
