import styled from "styled-components";
import { useDoubleClick } from "common/hooks";

const StyledContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  .field {
    display: inline-flex;
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 0 0 10px;
    gap: 5px;
    align-items: center;
    cursor: pointer;

    .square {
      width: 12px;
      min-width: 12px;
      height: 12px;
      border: 2px solid;
      border-radius: 6px;
    }

    label {
      user-select: none;
      margin-bottom: 0;
      font-size: 13px;
      font-weight: normal;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

function LegendItem({ color, label, isSelected, onSelect }) {
  const field = { label, name: label, color };
  const onClick = () => onSelect(field, "single");
  const onDoubleClick = () => onSelect(field, "double");
  const handleClick = useDoubleClick(
    onClick,
    onDoubleClick
  );

  return (
    <div className="field" onClick={handleClick}>
      <div
        className="square"
        style={{
          backgroundColor: isSelected ? color : "transparent",
          borderColor: color,
        }}
      ></div>
      <label> {label}</label>
    </div>
  );
}

function DPIAnalyticsLiveLegend({ fields, onChange, value, rest }) {
  function handleSelect(field, selection) {
    if (selection === "single") {
      if (value.size === 1) {
        const newFilterSet = new Set(fields.map((field) => field.name));
        onChange(newFilterSet);
      } else {
        const newFilterSet = new Set(value);
        if (newFilterSet.has(field.name)) {
          newFilterSet.delete(field.name);
        } else {
          newFilterSet.add(field.name);
        }
        onChange(newFilterSet);
      }
    } else {
      const newFilterSet = new Set([field.name]);
      onChange(newFilterSet);
    }
  }

  const fieldRest =
    rest !== false
      ? { label: "rest", name: "rest", color: rest["color"] }
      : null;

  return (
    <StyledContainer>
      {fields.map((field) => {
        const { name, label, color } = field;
        if (field.name === "rest") {
          return null;
        }
        return (
          <LegendItem
            label={label}
            color={color}
            isSelected={value.has(name)}
            onSelect={handleSelect}
          />
        );
      })}
      {fieldRest ? (
        <LegendItem
          label="rest"
          color={fieldRest.color}
          isSelected={value.has("rest")}
          onSelect={handleSelect}
        />
      ) : null}
    </StyledContainer>
  );
}

export default DPIAnalyticsLiveLegend;
