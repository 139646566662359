import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";

export const CenteredMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 100%;
  min-height: 155px;
`;

export const NoEnoughtData = () => (
  <CenteredMessage>Not enough data.</CenteredMessage>
);
