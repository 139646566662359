import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import * as d3 from "d3";

import ArrangeInColumns from "common/layouts/ArrangeInColumns";
import SelectInputWithIcon from "common/SelectInputWithIcon";
import ActionsContext from "common/ActionsContext";
import Request from "common/Request";
import { retrieveRange, isInsideRange, getSampleSize } from "common/api";
import RangeSliderInput from "common/RangeSliderInput";
import { useScreenSize } from "common/hooks";
import {
  PERIOD_TO_HOURS,
  PERIOD_TO_LINES,
  periodScopeChoices,
} from "common/constants";
import Chart from "./Chart";

import { parseStatsBtaData, getInRangeItems } from "./api";

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const SelectWrapper = styled.div`
  width: 150px;
`;

const ChartContainer = styled.div`
  min-height: 500px;
  margin-top: 20px;
  svg.nvd3-svg .nv-axis path.domain {
    stroke-opacity: 0;
  }
  .nv-y.nv-axis .nv-axisMaxMin {
    display: none;
  }
  .nv-y.nv-axis .tick.zero line {
    stroke: transparent !important;
  }
  .nvd3 .nv-groups path.nv-line {
    stroke-width: 1.1;
  }
`;

const RangeContainer = styled.div`
  margin: 20px 0;
`;

const defaultContext = "Day";

const ULTP = 1.25;

export const useStatsBtaTrafficData = (
  context = defaultContext,
  firstRequest = null
) => {
  const [request, setRequest] = useState(firstRequest);
  const [initialRange, setInitialRange] = useState();
  const doRequest = (context) => {
    const hours = PERIOD_TO_HOURS[context];
    return retrieveRange(hours).then(({ from, to }) => {
      setInitialRange({ from, to });
      return ifCl
        .run(`show statistics bta lines ${PERIOD_TO_LINES[context]}`)
        .then((response) => {
          return parseStatsBtaData(response, context, from, to);
        });
    });
  };

  const doLoad = () => {
    setRequest(doRequest(context));
  };

  useEffect(() => {
    doLoad();
  }, [JSON.stringify(context)]);

  return { request, doLoad, initialRange };
};

const BtaTrafficReport = () => {
  const { width: windowWidth } = useScreenSize();
  const actions = useContext(ActionsContext);
  const [period, setPeriod] = useState("Day");
  const { request, doLoad, initialRange } = useStatsBtaTrafficData(
    period,
    null
  );
  const [range, setRange] = useState();
  const POINTS_PER_TILE_CHART = windowWidth / 2;

  let lic = license.get("bta");
  let threshold;
  if (lic === undefined) lic = license.get("bta-base");
  if (lic === undefined) lic = license.get("bta-core");
  if (lic && lic.limit != "n/a") {
    threshold = Number(lic.limit);
  }

  useEffect(() => {
    setRange(undefined);
    doLoad();

    return actions.recv("do-load", doLoad);
  }, [period]);

  return (
    <Wrapper>
      <ArrangeInColumns rowGap="0">
        <SelectWrapper>
          <SelectInputWithIcon
            title="Date Range"
            name="Date Range"
            icon="date_range"
            selected={period}
            onChange={({ target }) => setPeriod(target.value)}
            options={periodScopeChoices}
          />
        </SelectWrapper>
      </ArrangeInColumns>
      <ChartContainer>
        <Request during={request}>
          {({ items: initItems, fields, limits: initLimits }) => {
            const selectedRange = range || initialRange;
            const sampleSize = getSampleSize(
              selectedRange,
              POINTS_PER_TILE_CHART,
              period
            );
            const { items, limits } = getInRangeItems({
              items: initItems,
              fields,
              sampleSize,
              range,
              initialRange,
              initLimits,
            });

            let yMax;
            let hasLimit = false;

            if (
              !limits.isZero &&
              threshold &&
              threshold < limits.max * ULTP &&
              threshold > limits.max * 0.9
            ) {
              yMax = limits.max * ULTP;
            } else {
              yMax = limits.max;
            }

            if (!limits.isZero && threshold && threshold <= yMax * ULTP) {
              hasLimit = true;
            }

            return (
              <Chart
                hasLimit={hasLimit}
                threshold={threshold}
                items={items}
                yAxisFormat={function (d) {
                  if (d == 0) {
                    return d3.format(".0f")(d);
                  } else if (d >= 10.0) {
                    return d3.format(".0f")(d);
                  } else if (d >= 1.0) {
                    return d3.format(".1f")(d);
                  } else {
                    return d3.format(".2f")(d);
                  }
                }}
                xAxisFormat={d3.timeFormat("%m/%d %H:%M")}
                fields={fields.map((field) => {
                  return { name: field, label: field };
                })}
                yAxisTopGap={0.05}
                yAxisTicksNumber={5}
                xAxisTicksNumber={8}
                yTooltipFormat={(d) => d3.format(".2f")(d) + " Mbps"}
                fixedYRange={
                  limits.isZeroOrNull
                    ? [0, 1]
                    : threshold
                    ? [0, yMax]
                    : [0, null]
                }
              />
            );
          }}
        </Request>
      </ChartContainer>
      <RangeContainer>
        {initialRange && (
          <RangeSliderInput
            {...initialRange}
            onChange={(value) => setRange(value)}
          />
        )}
      </RangeContainer>
    </Wrapper>
  );
};

export default BtaTrafficReport;
