import React, { useEffect, useRef } from "react";
import StyledG from "./DescriptionStyle";
import * as d3 from "d3";
import { wrapText, givenRange, makeCurlyBrace } from "./common";
import { remaining } from "common/graphs/Axis";

const decimalFormat = d3.format(".1f");

const quartilesBrackets = [
  {
    from: "min",
    to: "q1",
    label: "1st quartile (lowest 25% of values)",
    classed: "min",
  },
  { from: "q1", to: "median", label: "2nd quartile", classed: "q1-median" },
  { from: "median", to: "q3", label: "3rd quartile", classed: "median-q3" },
  {
    from: "q3",
    to: "max",
    label: `4th quartile (highest 25% of values)`,
    classed: "max",
  },
];

const quartilesCardinals = [];

const QuartilesDescription = ({
  canvas,
  data = {},
  axis = {},
  xFrom = "x",
  yFrom = "y",
  dimensions = null,
  margin = DEFAULT_MARGIN,
  precision = 2,
  units = undefined,
  format = (d) => (d % 1 ? formaDecimal(d) : d),
  label,
  brackets = quartilesBrackets,
  cardinals = quartilesCardinals,
  ...scaleParams
}) => {
  const chart = useRef(null);
  label =
    label !== undefined
      ? label
      : (d) => (d.value === null ? "--" : d.value.toFixed(precision));
  useEffect(() => {
    if (dimensions === null) {
      return;
    }
    const { width, height } = remaining(dimensions, margin);
    if (width < 1 || height < 1) {
      return;
    }

    const xAxis = axis[xFrom];
    const yAxis = axis[yFrom];
    const xScale = xAxis.scale;
    const yScale = yAxis.scale;

    const [left, right] = xScale.range();
    const center = (right - left) / 2;
    const thickness = d3.max([(right - left) / 4, 20]);

    const main = d3.select(chart.current);
    const plot = main.append("g").attr("class", "plot");
    const overX = givenRange(xScale.range());

    const bracketsPosition = d3
      .scaleBand()
      .domain(brackets.map((_, i) => i))
      .range(overX.beginingPart(0.6))
      .paddingInner(0.6);
    const newBrackets = plot
      .selectAll(".bracket")
      .data(brackets)
      .enter()
      .append("g")
      .attr("class", ({ classed }) => `bracket ${classed}`);
    newBrackets
      .append("path")
      .attr("d", ({ from, to }, index) =>
        makeCurlyBrace(
          xScale(1),
          yScale(data[from]),
          xScale(1),
          yScale(data[to])
        )
      )
      .attr("class", ({ classed }) => classed);

    const [_, rangeEnd] = xScale.range();
    newBrackets
      .append("text")
      .text((d) => d.label)
      .attr("y", ({ from, to }) => (yScale(data[from]) + yScale(data[to])) / 2)
      .attr("x", 30)
      .call(wrapText, rangeEnd - 30);

    return () => {
      main.selectAll(".plot").remove();
      main.selectAll(".axis").remove();
    };
  }, [chart, canvas, dimensions, axis]);

  return <StyledG ref={chart}></StyledG>;
};

export default QuartilesDescription;
