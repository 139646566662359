import { parseDate } from "../utils";

function getOptionsInterfaces(names) {
  if (names.indexOf("Access") > -1) {
    let ifNamesOrdered = names.filter(
      (name) => name != "Access" && name != "Internet"
    );
    ifNamesOrdered.unshift("Internet");
    ifNamesOrdered.unshift("Access");
    return ifNamesOrdered;
  } else {
    return names;
  }
}

function getEmptyDatasets(names, options) {
  const emptyDatasets = names.reduce((acc, value) => {
    acc[value] = { items: [], isZeroOrNull: true };
    return acc;
  }, {});
  return {
    names,
    dataSets: emptyDatasets,
    options: ["All", ...options],
  };
}

export const parseStatsInterfacesData = (
  response,
  period,
  extPortName,
  initialRange
) => {
  if (response === "") {
    return {
      names: [],
      dataSets: {},
      options: ["All"],
    };
  }
  const rows = response.trim(/\s/).split("\n");
  const { from: beginRange, to: endRange } = initialRange;
  const { namesTotal, dataSets } = rows.reduce(
    (acc, value) => {
      const attrs = value.split(/\s+/);
      const time = parseDate(attrs[0]);

      if (time === null) {
        if (!acc.hdr) {
          const names = attrs.reduce((namesAcc, namesValue) => {
            if (namesValue) {
              const nName = !extPortName[namesValue]
                ? namesValue
                : extPortName[namesValue];
              namesAcc.push(nName);
            }
            return namesAcc;
          }, []);
          acc.names = names;
          acc.hdr = true;
          acc.namesTotal = new Set([...[...acc.namesTotal], ...names]);
        } else {
          acc["hdr"] = false;
        }
      } else {
        if (beginRange < time && time < endRange) {
          const { names } = acc;
          const emptyNames = [...acc.namesTotal].filter(
            (name) => !names.includes(name)
          );

          for (let j = 0; j < names.length; j++) {
            const Tx =
              attrs[j * 2 + 1] === "n/a" ? null : Number(attrs[j * 2 + 1]);
            const Rx =
              attrs[j * 2 + 2] === "n/a" ? null : Number(attrs[j * 2 + 2]);
            const isZeroOrNull =
              Tx === null || (Tx === 0 && Rx === null) || Rx === 0;
            const newEntry = { Tx, Rx, time };
            if (!acc.dataSets[names[j]]) {
              acc.dataSets[names[j]] = {
                items: [...acc.emptyDataset, newEntry],
                isZeroOrNull,
              };
            } else {
              acc.dataSets[names[j]].items.push(newEntry);
              if (!isZeroOrNull) {
                acc.dataSets[names[j]].isZeroOrNull = false;
              }
            }
          }

          const empty = { Tx: null, Rx: null, time };
          acc.emptyDataset.push(empty);

          emptyNames.forEach((name) => {
            acc.dataSets[name].items.push(empty);
          });
        }
      }
      return acc;
    },
    {
      hdr: false,
      names: [],
      namesTotal: new Set([]),
      dataSets: {},
      emptyDataset: [],
    }
  );

  const options = getOptionsInterfaces([...namesTotal]);

  return {
    names: [...namesTotal],
    dataSets: dataSets || {},
    options: ["All", ...options],
  };
};

export const parseWire = (response) => {
  const [header, ...rows] = response.trim(/\s/).split("\n");
  return rows.reduce((acc, value) => {
    const attrs = value.split(/\s+/);
    acc[attrs[0]] = attrs[0] + "-access";
    acc[attrs[2]] = attrs[2] + "-net";
    return acc;
  }, {});
};

function getMaxValue(item) {
  const itemCheck = { ...item };
  delete itemCheck.time;
  const maxValue = Math.max(...Object.values(itemCheck));
  return maxValue;
}

export function getInRangeItems({
  items,
  sampleSize,
  range,
  initialRange,
  fields,
  initZeroOrNull,
}) {
  if (!initialRange){
    return undefined;
  }
  if (!items || items.length === 0 ) {
    return { items: [], isZeroOrNull: true };
  }

  let inrangeItems = [];
  let maxItems;
  let isZeroOrNull = true;

  const selectedRange = range ? range : initialRange;
  const checkLimits = range === undefined || initZeroOrNull ? false : true;
  const { from, to } = selectedRange;

  for (let i = 0; i < items.length; i += 1) {
    const { time } = items[i];
    if (from <= time && time <= to) {
      if (!maxItems) {
        maxItems = { ...items[i] };
        if (checkLimits) {
          const maxValue = getMaxValue(maxItems);
          if (maxValue !== null && maxValue > 0) {
            isZeroOrNull = false;
          }
        }
      }
      if (i % sampleSize === 0) {
        inrangeItems.push({ ...maxItems, time });
        maxItems = { ...items[i] };
        if (checkLimits) {
          const maxValue = getMaxValue(maxItems);
          if (maxValue !== null && maxValue > 0) {
            isZeroOrNull = false;
          }
        }
      } else {
        const newMax = fields.reduce((acc, value) => {
          const newValue = items[i][value];
          if (newValue > acc[value]) {
            acc[value] = newValue;
            if (checkLimits) {
              if (isZeroOrNull && newValue !== 0 && newValue !== null) {
                isZeroOrNull = false;
              }
            }
          }
          return acc;
        }, maxItems);
        maxItems = newMax;
      }
    }
  }

  if (inrangeItems.length === 0) {
    return { items: [], isZeroOrNull: true };
  }

  if (
    inrangeItems[inrangeItems.length - 1]?.time &&
    items[items.length - 1]?.time &&
    inrangeItems[inrangeItems.length - 1].time !== items[items.length - 1]?.time
  ) {
    const { time } = items[items.length - 1];
    if (from <= time && time <= to) {
      inrangeItems.push(items[items.length - 1]);
    }
  }

  let extendedItems = [...inrangeItems];
  if (extendedItems.length !== 0) {
    while (extendedItems[0].time >= from) {
      const emptyElement = fields.reduce((acc, value) => {
        acc[value] = null;
        return acc;
      }, {});
      const time = new Date(
        Date.parse(extendedItems[0].time) - 5 * 60000 * sampleSize
      );
      extendedItems.unshift({ ...emptyElement, time });
    }
  }

  const limits = checkLimits ? isZeroOrNull : initZeroOrNull;
  return { items: extendedItems, isZeroOrNull: limits };
}
