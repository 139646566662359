import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const CarrouselContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  scroll-behavior: "smooth";
  overflow-x: scroll;
  overscroll-behavior-x: contain;
  scroll-snap-txpe: x proximity;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  min-height: ${props => props.minHeight === undefined ? 'unset' : props.minHeight};
  & > .slide {
    width: 100%;
    height: 100%;
    flex: 0 0 100%;
    display: flex;
    flex-direction: column;
    transition: margin-left 1s;
    max-height: 100vh;
    &.passed {
      margin-left: -100%;
    }
    &:not(.passed) + .slide {
      max-height: 0;
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Carrousel = ({ slide = 0, children, ...settings }) => {
  const carrousel = useRef(null);
  const updatePosition = () => {
    if (carrousel.current === null) {
      return;
    }
    const members = Array.from(carrousel.current.children);
    members.forEach(
      (member, idx) =>
        (member.className = `slide ${slide > idx ? "passed" : ""}`)
    );
  };

  useEffect(() => updatePosition(), [slide]);
  return <CarrouselContainer ref={carrousel} {...settings}>{children}</CarrouselContainer>;
};

export default Carrousel;
