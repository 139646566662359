import {format as d3Format} from 'd3';
const formats = {
  decimals: (precision=0) => {
    const format = d3Format(`.${precision}f`);
    return d => d === null ? 'n/a' : format(d)
  }
};


export default formats;
