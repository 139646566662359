import * as d3 from "d3";

export function getKeysSeries(header) {
  const [, ...keys] = header.split(/\s+/);
  return keys.map((key) => {
    const chunks = key.split("-").map((item) => {
      return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
    });
    return chunks.join("-");
  });
}

export const parseDate = d3.timeParse("%Y-%m-%dT%H:%M:%S");