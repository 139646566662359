/*globals login*/
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Loading from "common/Loading";
import {
  doSubcriberInformationRetrieval,
  parseSubscriberInformation,
} from "./livemetrics.utils";


const DialAndValue = styled.div`
  .overlay-dial {
    margin-top: -60px;
    height: 60px;
    position: relative;
    background: none;
  }
  .value {
    font-size: 1.15em;
    color: rgb(85, 85, 85);
  }
  .overlay-dial.value {
    text-align: center;
    line-height: 60px;
    vertical-align: middle;
  }
  .dial-input-wrapper {
    height: 60px;
    overflow: hidden;
    input {
      display: none;
    }
  }
  .scale-info {
    font-size: 10px;
    margin-top: -1.1em;
    margin-bottom: 1.2em;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    label {
      font-size: 10px;
      font-weight: lighter;
    }
  }
`;

const DialWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

const DialsBox = styled.div`
  width: 100%;
  padding: 22px 20px 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media only screen and (max-width: 1250px) {
    display: grid;
    grid-template-columns: repeat(4, 25%);
  }

  @media only screen and (max-width: 550px) {
    display: grid;
    grid-template-columns: repeat(2, 50%);
  }

  @media only screen and (max-width: 400px) {
    display: grid;
    grid-template-columns: repeat(1, 100%);
  }
`;
const precisionFor = value =>
  value === 0 || value === 0.000 ? 0 :
  value < 1 ? 3 :
  value < 10 ? 2 :
  value < 100 ? 1 :
  0

const useScaleMax = (givenMax=0, value) => {
  const [max, setMax] = useState(givenMax);
  const maxLowerAt = useRef();
  function getMax(value) {
    if (isNaN(Number(value)) || Number(value) <= 1) {
      return 1;
    }

    const [integer, _] = value.toString().split(".");
    return Math.pow(10, integer.length);
  }
  useEffect(() => {
    const maxUpdate = getMax(value);
    if (maxUpdate > max) {
      maxLowerAt.current = null;
      setMax(maxUpdate);
    } else if (maxUpdate < max) {
      if (!maxLowerAt.current) {
        maxLowerAt.current = new Date().getTime();
      }

      const timeDiff = new Date().getTime() - maxLowerAt.current;
      const shouldUpdateMax = timeDiff > 10 * 1000 || isNaN(Number(value));
      if (shouldUpdateMax) {
        maxLowerAt.current = null;
        setMax(maxUpdate);
      }
    } else {
      maxLowerAt.current = null;
    }
  }, [value]);
  return [max];
}

const represent = (value, precision=undefined) =>
  value === null ? 'n/a'
  : Number(value).toFixed(
    precision === undefined ? precisionFor(value) : precision
  )


function Dial({ id, value, repr=represent, unit, min, max: givenMax }) {
  const inputRef = useRef();
  const max = useScaleMax(givenMax, value);
  useEffect(() => {
    if (inputRef.current) {
      initialize();
    }
  }, [inputRef.current]);

  useEffect(() => {
    if (inputRef.current && value !== undefined) {
      if (isNaN(Number(value))) {
        $(inputRef.current).val(0).trigger("change");
        $(inputRef.current).val(value);
      } else if (value <= max) {
        $(inputRef.current).val(value).trigger("change");
      }
    }
  }, [value]);

  useEffect(() => {
    $(inputRef.current).trigger("configure", {
      max: max,
    });
    $(inputRef.current).val(value).trigger("change");
    $(inputRef.current).val(value);
  }, [max]);

  function initialize() {
    $(inputRef.current)
      .knob({
        change: function () {},
        release: function () {},
        cancel: function () {},
        format: function (value) {
          return value;
        },
      })
      .children()
      .off("mousewheel mousemove click mousedown mouseup");
  }

  const isDark = login.isTheme("dark");
  const borderColor = "#006ab7";
  const borderWidth = 0.2;
  const showValue = false ;
  const backgroundColor = isDark ? "#3A3A3E" : "#F5F5F5";
  return (
    <DialAndValue backgroundColor={backgroundColor}>
      <div className="dial-input-wrapper">
        <input
          id={id}
          ref={inputRef}
          type="text"
          className="knob"
          value={value}
          data-width="80"
          data-height="80"
          data-fgColor={borderColor}
          data-bgColor={backgroundColor}
          data-thickness={borderWidth}
          data-anglearc="180"
          data-angleoffset="-90"
          data-min={min}
          data-max={max}
          data-step="0.01"
          data-displayInput={showValue}
          readOnly
        />
      </div>
      <div className="value overlay-dial">{repr(value)}</div>
      <span className="scale-info">
        <label className="min-value">{repr(min)}</label>
        <label className="units">{unit || ""}</label>
        <label className="max-value">
          {repr(max)}
        </label>
      </span>
    </DialAndValue>
  );
}

function ShowDial({ key, label, useShortValues = false, ...params }) {
  return (
    <DialWrapper className="dial-wrapper">
      <label> {label || key} </label>
      <Dial
        id="first"
        {...params}
        useShortValues={useShortValues}
      />
    </DialWrapper>
  );
}
const speedScale = [
  [1, 'Mbps'],
  [1000, 'Gbps'],
  [1000000, 'Tbps'],
]

const mostSuitableScaleFor = value => ([limit, _unit]) =>
  limit < value;

const selectScale = (value, scale=[[1, '']]) =>
  scale.findLast(mostSuitableScaleFor(value)) || scale[0];

const valueInScale = (value, scale=[], toString=represent) => {
  const [base, unit] = selectScale(value, scale);
  const repr = value => toString(value / base)
  return {
    min: 0,
    max: value,
    value,
    unit,
    repr
  }
}
const speedValueInScale = (key, data) => {
  const value = data[key] || null;
  return value !== null ? valueInScale(value, speedScale)
  : {
    min: 0,
    max: 1,
    value,
    unit: 'ms',
    repr: represent,
  }
}

const timingScale = [
  [1, 'ms'],
  [1000, 's'],
]

const timingValueInScale = (key, data) => {
  const value = data[key] || null;
  return value !== null ? valueInScale(value, timingScale)
  : {
    min: 0,
    max: 1,
    value,
    unit: 'ms',
    repr: represent,
  }
}
const flowsValueScale = [
  [1, ''],
  [1000, 'K'],
  [1000000, 'M'],
  [1000000000, 'G'],
  [1000000000000, 'T'],
]

const representFlows = (value, precision=0) => {
  const [floor, unit] = selectScale(value, flowsValueScale);
  return `${represent(value/floor, precision)} ${unit}`
};

const flowsValueInScale = (key, data) => {
  const value = data[key];
  return {
    min: 0,
    max: value === null ? 1 : value,
    value,
    unit: 'flows',
    repr: representFlows,
  }
}

const ratioValueInScale = (key, data) => {
  const value = data[key];
  return {
    min: 0,
    max: 100,
    value,
    unit: '%',
    repr: represent,
  }
}

function LiveMetricsDials({ target, setLiveMode }) {
  const [items, setItems] = useState(null);
  const [load, setLoad] = useState(true);

    const doLoad =() => {
      setLoad(true);
      const epocTimePrevious = items
        ? Number(items["EpocTime"])
        : undefined;
      const flowsCreatedPrevious = items
        ? Number(items["Flows-created"])
        : undefined;
      doSubcriberInformationRetrieval(target)
        .then(
          response => parseSubscriberInformation(
            response,
            epocTimePrevious,
            flowsCreatedPrevious
          )
        )
        .then(setItems)
        .finally( () => setLoad(false))
    }
  useEffect(() => {
    setLiveMode(undefined);
    doLoad();
  }, [JSON.stringify(target)]);

  if (load) {
    return <Loading />;
  }

  if (!items) {
    return null;
  }
  return (
    <DialsBox>
      <ShowDial
        label="Downlink rate"
        {...speedValueInScale("Mbps-down",items)}
      />
      <ShowDial
        label="Uplink rate"
        {...speedValueInScale("Mbps-up",items)}
      />
      <ShowDial
        label="Access latency"
        {...timingValueInScale("Rtt-down(ms)", items)}
      />
      <ShowDial
        label="Inet latency"
        {...timingValueInScale("Rtt-up(ms)", items)}
      />
      <ShowDial
        label="Access Rtx"
        {...ratioValueInScale("Rtx-down", items)}
      />
      <ShowDial
        label="Inet Rtx" 
        {...ratioValueInScale("Rtx-up", items)}
     />
      <ShowDial
        label="Active Flows"
        {...flowsValueInScale("Flows-active",items)}
      />
      <ShowDial
        label="Flows since start"
        {...flowsValueInScale("Flows-created",items)}
      />
    </DialsBox>
  );
}

export default LiveMetricsDials;
