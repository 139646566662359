import React, { useState, useEffect, useRef, useContext } from "react";
import { InlineInputDiv } from "./common";
import { validateIPv4IPv6 } from "common/api";

const validateIPv4IPv6Range = (input) =>
  validateIPv4IPv6(input.replace(/\/.*$/, ""));

const isValidIPValue = (value) =>
  value.length === 0 || validateIPv4IPv6Range(value) === true;

const AddressInput = ({
  value,
  name = "value",
  className = "",
  onChange = doesNothing,
}) => {
  const input = useRef(null);
  const [error, setError] = useState(false);
  const updateAddr = () => {
    const newAddress = (input.current !== null && input.current.value) || "";
    if (isValidIPValue(newAddress)) {
      onChange({ target: input.current });
    }
  };
  const handleKeyPressed = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event.preventDefault();
      error === false && updateAddr();
    }
  };
  const handleKeyReleased = (event) => {
    const newAddress = (input.current !== null && input.current.value) || "";
    if (isValidIPValue(newAddress)) {
      setError(false);
    } else {
      setError("Value is not a valid IP value");
    }
  };
  useEffect(() => {
    if (input.current === null) {
      return;
    }
    const target = input.current;
    target.addEventListener("keydown", handleKeyPressed);
    target.addEventListener("keyup", handleKeyReleased);
    return () => {
      target.removeEventListener("keydown", handleKeyPressed);
      target.removeEventListener("keyup", handleKeyReleased);
    };
  }, [input]);

  return (
    <InlineInputDiv className={className ? "wide" : `wide ${className}`}>
      <div className="input-section" style={{'flex': '100%', display: 'flex', 'flexFlow': 'row nowrap'}}>
        <div className="input-group" style={{flexGrow: '1'}}>
          <span className="input-group-addon">
            <i className="material-icons" title="Address range">
              devices
            </i>
          </span>
          <div className="form-line">
            <input
              type="text"
              ref={input}
              name={name}
              defaultValue={value}
              className="form-control"
              placeholder="Ex: 10.1.0.0/16 or 2001:db8:80::/48"
            />
          </div>
        </div>
        <div className="input-group-add" >
          <button
            id="subsMetricsSubnetBtn"
            className="btn btn-link waves-effect"
            onClick={updateAddr}
            disabled={error !== false}
          >
            Apply
          </button>
        </div>
      </div>
      {error !== false && <div className="error break">{error}</div>}
    </InlineInputDiv>
  );
};

export default AddressInput;
