import {safeStr} from "common/api";

const intoShapingValues = (settings, [field, value]) =>
  field === null
    ? settings
    : {
        ...settings,
        [field]: (value === "n/a" || value ==="undefined") ? undefined : parseInt(value) / 1000,
      };

export const defaultShapingValues = {
  upRate: undefined,
  downRate: undefined,
};

const shapingFieldTrasnlations = {
  "Uplink rate": "upRate",
  "Downlink rate": "downRate",
};

const toFieldAndValue = (row) => {
  const [fieldStr, value] = row.split(/:\s+/);
  return [shapingFieldTrasnlations[fieldStr] || null, value];
};

export const parseShapingValues = (input) =>
  input
    .split("\n")
    .map(toFieldAndValue)
    .reduce(intoShapingValues, defaultShapingValues);

const valid = (label, value, rule) => {
  try {
    return rule(value);
  } catch (cause) {
    throw `${label} ${cause}`;
  }
};

const onlyNumbersAndDot = /^[0-9]+(\.[0-9]+)?$/;

const validRateInMBPS = (value) => {
  if (value === undefined || value.length === 0) {
    throw "can not be empty";
  }
  if (onlyNumbersAndDot.test(value) !== true) {
    throw "only accepts numbers and a dot";
  }
  const parsed = parseFloat(value);
  if (isNaN(parsed)) {
    throw "is not a number";
  }
  return parsed;
};

const toKBPS = (value) => (value * 1000).toFixed(0);

export const validAsRateInKBPS = (input) =>
  input === undefined || input.length === 0
    ? "disabled"
    : toKBPS(valid("Rate limit", input, validRateInMBPS));

export const composeShapingUpdateCommand = ({
  value,
  direction,
  subscriberGroup,
}) =>
  `api subscriber-group ${safeStr(subscriberGroup)} shaping ${direction} rate ${validAsRateInKBPS(
    value
  )}`;
