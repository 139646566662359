import React, { useState, useContext } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const BtaTrafficHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  actions.recv("open-help", function () {
    setOpen(true);
  });
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="Optimization/Shaping Throughput"
      onClose={doClose}
      content={() => (
        <>
          <p>Throughput over time, in Mbps, including all interfaces and both uplink and downlink traffic:</p>
        <ul>
          <li><b>Tot-Rcv</b>: Total received throughput.</li>
          <li><b>Tot-Snt</b>: Total sent throughput.</li>
          <li><b>Tcpo-Rcv</b>: TCP throughput received from TCP connections being optimized.</li>
          <li><b>Tcpo-Snt</b>: TCP throughput sent from TCP connections being optimized.</li>
          <li><b>Tcp-Trck</b>: TCP throughput from TCP connections being tracked (but not optimized).</li>
          <li><b>Tcp-Fwd</b>: TCP throughput from TCP connections not being tracked nor optimized.</li>
          <li><b>Udp-Snt</b>: UDP throughput from UDP flows.</li>
          <li><b>Other-Snt</b>: Throughput from all non-TCP and non-UDP traffic.</li>
          <li><b>Shaped</b>: Traffic actually shaped by Subscriber Rate policies or by per application Shaping.</li>
        </ul>
        <p>A horizontal <span class="col-red">red line</span> will indicate the license limit if the total traffic is close to or above that limit.</p>
        <p>Chart selectors:</p>
        <ul>
          <li><i class="material-icons valign-icon">date_range</i> Selects the range of time values shown starting from now: 1 day, 1 week or 1 month. The slider at the bottom of the graph allows refining the range of date values shown: a shorter interval by dragging any of the dated labels and, once done, by dragging the central part</li>
        </ul>
        <p>Click on the coloured dots on top-right corner of the graph to optionally select and de-select which types of throughput are shown.</p>
        <p>Double-click on the coloured dots on top-right corner of the graph to optionally isolate the selected type of throughput.</p>
        <p>Click on the <i class="material-icons valign-icon">refresh</i> icon to refresh the throughput values.</p>
        </>
      )}
      onClose={doClose}
    />
  );
};
export default BtaTrafficHelpModal;