/* globals functionality, views, components, showModalConfirm, showModalError*/
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import ActionsContext from "common/ActionsContext";
import Request from "common/Request";
import DataTable, { Column } from "common/DataTable";
import { isIPv4IPv6Range, isValidIPValue, safeStr } from "common/api";

const createPlaceholder = () =>
  `ADDRESS`;

const ensurePlaceholder = () => (error) => {
  return { response: createPlaceholder() };
};

function getType(input){
  if(isIPv4IPv6Range(input)){
    return 'IP-ADDR-RANGE';
  }else if(isValidIPValue(input)){
    return 'IP-ADDRESS';
  }else if(input === 'notag' || (!isNaN(input) && Number(input) >=0 && Number(input) <= 4095)){
    return 'VLAN-ID';
  }else{
    return 'SUBSCRIBER-ID';
  }
}

const doIpAddressesRequest = (subscriberGroup) =>
  Promise.all([
    ifCl.run(`show api subscriber-group name ${safeStr(subscriberGroup)} subscriber`), 
    ifCl.run(`show api subscriber-group name ${safeStr(subscriberGroup)} subscriber-range`),
    ifCl.run(`show api subscriber-group name ${safeStr(subscriberGroup)} subscriber-id`),
    ifCl.run(`show api subscriber-group name ${safeStr(subscriberGroup)} vlan`)
  ])
  .then(([response1, response2, response3, response4]) => { 
    const response = response1 + 
    response2.replace('RANGE\n', '') + 
    response3.replace('SUBSCRIBER-ID\n', '') + 
    response4.replace(/\s+VLAN\n/, '')

    const responseWithTypes = 'TYPE VALUE\n' + response.trim('\n').split('\n').slice(1).map(value => `${getType(value)} ${value}`).join('\n');

    return { response:responseWithTypes}
  })
  .catch(ensurePlaceholder());

const ResultsLayout = styled.div`
  margin-right: 5px;
  flex: 100%;
  overflow: hidden; /*list will scroll*/
`;

const TableContainer = styled.div`
  width: 100%;
  overflow: auto;
  --is-highest: #3d85c6;
  --is-higher: #6aa84f;
  --is-lower: #e69138;
  --is-lowest: #cc0000;
  --warning: #cc0000;
  .inverted {
    --is-lowest: #3d85c6;
    --is-lower: #6aa84f;
    --is-higher: #e69138;
    --is-highest: #cc0000;
  }
  .dataTable tbody td {
    transition: color 1s, background-color: 1s;
  }
  &.colorize-warnings .dataTable tbody td {
    &.warning {
      color: white;
      background: var(--warning);
    }
  }
`;

const ListValues = ({ request, subscriberGroup }) => {
  const actions = useContext(ActionsContext);
  const reloadWhenDone = () => actions.send("do-load");

  const openSubscriberDetails = (target) => {
    const returnTo = 'viewStatusSubscriberGroupsEdit';
    views.doKeep(returnTo);
    globalNavigate("viewSubsDetail", {returnView: returnTo, ...target});
  };
  
  return <TableContainer
    className={"colorize-warnings"}
  >
    <Request during={request}>
      {({response}) => (
        <DataTable
          pageLength={25}
          exportAsCSV={true}
          content={response}
          columns={[
            Column.Text({
              label: "TYPE",
              idx: 0,
              title: "Type"
            }),
            Column.Text({
              label: "VALUE",
              idx: 1,
              title: "Value",
              cellClassName: (value) => ['IP-ADDRESS', 'SUBSCRIBER-ID'].includes(getType(value)) ?'hyperlink-text':'',
              onClick: (value) => {
                const type = getType(value);
                if(type === 'IP-ADDRESS'){
                  openSubscriberDetails({ addr: value })
                }else if(type === 'SUBSCRIBER-ID'){
                  openSubscriberDetails({ subsId: value })
                }
              },
            }),
            Column.Actions({
              label: "ACTIONS",
              idx: 1,
              colClassName: "text-center action",
              cellClassName: "hides-content-to-operators",
              are: [
                {
                  icon: "delete",
                  label: "Delete",
                  onClick: (value) => {
                    showModalConfirm(
                      `Delete ${value} from subscriber group?`,
                      "",
                      "delete_forever",
                      () => {
                        let clearCmd = '';
                        switch(getType(value)){
                          case 'IP-ADDRESS':
                            clearCmd = `clear api subscriber ${value} subscriber-group ${safeStr(subscriberGroup)}`
                            break;
                          case 'SUBSCRIBER-ID':
                            clearCmd = `clear api subscriber-group ${safeStr(subscriberGroup)} subscriber-id ${value}`
                            break;
                          case 'VLAN-ID':
                            clearCmd = `clear api subscriber-group ${safeStr(subscriberGroup)} vlan ${value}`
                            break;
                          case 'IP-ADDR-RANGE':
                            clearCmd = `clear api subscriber-group ${safeStr(subscriberGroup)} subscriber-range ${value}`
                            break;
                        }

                        ifCl
                          .run(clearCmd)
                          .then(reloadWhenDone)
                          .catch((error) => showModalError(error));
                      }
                    );
                  },
                },
              ],
            }),
          ]}
        />
      )}
    </Request>
  </TableContainer>
};

export const ValuesTable = ({subscriberGroup}) => {
  const [request, setRequest] = useState(null);
  const actions = useContext(ActionsContext);

  const doLoad = () => setRequest(doIpAddressesRequest(subscriberGroup));

  useEffect(() => {
    doLoad();
    return actions.recv("do-load", doLoad);
  }, []);
  
  return request === null ? null : (
      <ResultsLayout>
        <ListValues request={request} subscriberGroup={subscriberGroup} />
      </ResultsLayout>
  );
};
