import React, { useState, useEffect, useContext } from "react";
import ActionsContext from "../ActionsContext";
import NVLineChart from "./NVLineChart";

const EVENT_HIGHLIGHT = "nv-line-chart-highlight";

const highlighHandling = (actions, facet, setHighlight) => ({
  enable: () =>
    actions.recv(
      EVENT_HIGHLIGHT,
      ({ target, event }) => target !== facet && setHighlight(event)
    ),
  report: (event) => {
    actions.send(EVENT_HIGHLIGHT, { target: "placeholder", event });
  },
});

const debounce = (delay, callback) => {
  let setoff = null;
  const waitFor = (delay) => {
    if (setoff !== null) {
      window.clearTimeout(setoff);
    }
    return new Promise((resolve) => {
      setoff = window.setTimeout(resolve, delay);
    });
  };
  return (...params) => waitFor(delay).then(() => callback(...params));
};

export const ConnectedLineChart = ({ facet, ...params }) => {
  const [highligh, setHighlight] = useState(null);
  const actions = useContext(ActionsContext);

  const highlightHandler = highlighHandling(actions, facet, setHighlight);
  useEffect(highlightHandler.enable, []);
  return (
    <NVLineChart
      {...params}
      highlight={highligh}
      onHighlight={debounce(100, highlightHandler.report)}
    />
  );
};
