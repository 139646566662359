import React, { useState, useEffect, useRef } from "react";
import colors from "common/graphs/colors";
import colorsDark from "common/graphs/colorsDark";
import { getSystemDate as cliGetSystemDate, expressTarget } from "common/api";

export function getSystemDate(timeOffset) {
  const now = new Date();
  now.setHours(now.getHours() + timeOffset);
  now.setMilliseconds(500);
  return now;
}

export function dataMapKey(date) {
  return `${twoDigits(date.getHours())}${twoDigits(
    date.getMinutes()
  )}${twoDigits(date.getSeconds())}`;
}

export function twoDigits(value) {
  return value >= 10 ? value : "0" + value;
}

export function getTooltipTitle(date) {
  if (!date) {
    return "";
  }
  return `${twoDigits(date.getHours())}:${twoDigits(
    date.getMinutes()
  )}:${twoDigits(date.getSeconds())}`;
}

export async function calculateTimeOffset() {
  const systemDateString = await cliGetSystemDate();
  const systemDate = new Date(
    systemDateString.split(" ").splice(0, 5).join(" ")
  );
  return systemDate.getHours() - new Date().getHours();
}

export function useTimeOffset() {
  const [timeOffset, setTimeOffset] = useState();
  useEffect(() => {
    calculateTimeOffset().then((result) => {
      setTimeOffset(result);
    });
  }, []);

  return { timeOffset };
}

export const doSubcriberInformationRetrieval = (target) =>
  expressTarget(target).then( expressedTarget =>
    ifCl.run(`show subscriber ${expressedTarget} web`)
  )

export async function getLiveFlowsData({ targetCmd, direction, catNr }) {
  // show flow all TARGET web all|uplink|downlink lines NUM categories NUM
  return ifCl.run(
    `show flow all ${targetCmd} web ${direction} lines 15 categories ${catNr}`
  );
}

export const parseSpeedResponse = (response) => {
  const lines = response.trim("\n").split("\n");
  const data = {};
  for (let line of lines) {
    const [field, value] = line.split(/:\s+/);
    data[field] = value.replace("%", "");
  }
  return data;
};

export const parseSubscriberData = (
  data,
  epocTimePrevious,
  flowsCreatedPrevious
) => {
  const epocTime = Number(data["EpocTime"]);
  const flowsCreated = Number(data["Flows-created"]);
  if (epocTimePrevious) {
    data["Flows-created-per-min"] = Math.max(
      Math.round(
        ((flowsCreated - flowsCreatedPrevious) /
          (epocTime - epocTimePrevious)) *
          1000 *
          60
      ),
      0
    );
  } else {
    data["Flows-created-per-min"] = 0;
  }

  return data;
};

export const parseDPIsResponse = (response) => {
  if (!response) {
    return {};
  }
  const lines = response.trim("\n").split("\n");
  return lines.reduce((acc, value) => {
    const [field, fieldvalue] = value?.split(/[ ,]+/);
    if (!(field === "DPI" && fieldvalue === "MBPS")) {
      acc[field] = fieldvalue;
    }
    return acc;
  }, {});
};

export const parseSubscriberInformation = (
  input,
  epocTimePrevious,
  flowsCreatedPrevious
) => {
  const lines = input.trim("\n").split("\n");
  const data = {};
  for (let line of lines) {
    const [field, value] = line.split(/:\s+/);
    data[field] = value === 'n/a' ? null
      : Number(value.replace("%", ""))
  }

  const epocTime = Number(data["EpocTime"]);
  const flowsCreated = Number(data["Flows-created"]);
  if (epocTimePrevious) {
    data["Flows-created-per-min"] = Math.max(
      Math.round(
        ((flowsCreated - flowsCreatedPrevious) /
          (epocTime - epocTimePrevious)) *
          1000 *
          60
      ),
      0
    );
  } else {
    data["Flows-created-per-min"] = 0;
  }

  return data;
};

export function prepend(nMinutes, items, fields) {
  if (!items[0]?.time?.getTime()) {
    return [...items];
  }
  const fill = [];
  const start = items[0].time.getTime() - nMinutes * 60 * 1000;
  for (let i = start; i < items[0].time.getTime(); i += 1000) {
    const time = new Date(i);
    time.setMilliseconds(500);
    const newItem = { time: new Date(i) };
    for (let field of fields) {
      newItem[field.name] = 0;
    }
    fill.push(newItem);
  }
  return [...fill, ...items];
}

function getColorMapStart(dpis) {
  const colorsSet = login.isTheme("light") ? colors : colorsDark;

  return [...new Set(dpis)].reduce((acc, value, index) => {
    acc.colorIndex = index % colorsSet.length;
    acc[value] = colorsSet[acc.colorIndex];
    return acc;
  }, {});
}

export function useColorMap({ response, liveMode, dpiFields }) {
  const [colorMap, setColorMap] = useState(undefined);

  useEffect(() => {
    if (response) {
      const lines = response.split("\n");
      lines.shift();
      const dpis = lines.map((line) => line.split(" ").pop());

      if (liveMode) {
        const newColorMap = Object.entries(dpiFields).reduce(
          (acc, [key, fieldAtt]) => {
            acc[key] = fieldAtt.color;
            return acc;
          },
          {}
        );
        setColorMap(newColorMap);
      } else if (!liveMode) {
        setColorMap(getColorMapStart(dpis));
      }
    }
  }, [response]);

  return [colorMap, setColorMap];
}

export function getItemsUpdate(
  items,
  now,
  fields,
  timeOffset,
  timeFrame,
  timeWindow,
  newValue,
  window
) {
  let itemsUpdate = [];
  let newWindow = window;
  if (timeWindow === 2) {
    if (newWindow === 0 || items.length === 0) {
      itemsUpdate = createNewItems(now, fields);
      newWindow = 1;
    } else if (timeFrame > 1 * 60 * 1000 && newWindow === 1) {
      itemsUpdate = prepend(1, items, fields);
      newWindow = 2;
    } else if (timeFrame < 1 * 60 * 1000 && newWindow === 1) {
      itemsUpdate = getItemsUpdateCorrected(
        newValue,
        items,
        itemsUpdate,
        1 * 60 * 1000 + 1000
      );
    } else {
      itemsUpdate = getItemsUpdateCorrected(
        newValue,
        items,
        itemsUpdate,
        2 * 60 * 1000 + 1000
      );
    }
    return { itemsUpdate, window: newWindow };
  }
  if (timeWindow === 5) {
    if (newWindow === 0 || items.length === 0) {
      itemsUpdate = createNewItems(now, fields);
      newWindow = 1;
    } else if (timeFrame > 1 * 60 * 1000 && newWindow === 1) {
      itemsUpdate = prepend(1, items, fields);
      newWindow = 2;
    } else if (timeFrame < 1 * 60 * 1000 && newWindow === 1) {
      itemsUpdate = getItemsUpdateCorrected(
        newValue,
        items,
        itemsUpdate,
        1 * 60 * 1000 + 1000
      );
    } else if (timeFrame > 2 * 60 * 1000 && newWindow === 2) {
      itemsUpdate = prepend(1, items, fields);
      newWindow = 3;
    } else if (timeFrame < 2 * 60 * 1000 && newWindow === 2) {
      itemsUpdate = getItemsUpdateCorrected(
        newValue,
        items,
        itemsUpdate,
        2 * 60 * 1000 + 1000
      );
    } else if (timeFrame > 3 * 60 * 1000 && newWindow === 3) {
      itemsUpdate = prepend(2, items, fields);
      newWindow = 5;
    } else if (timeFrame < 3 * 60 * 1000 && newWindow === 3) {
      itemsUpdate = getItemsUpdateCorrected(
        newValue,
        items,
        itemsUpdate,
        3 * 60 * 1000 + 1000
      );
    } else {
      itemsUpdate = getItemsUpdateCorrected(
        newValue,
        items,
        itemsUpdate,
        5 * 60 * 1000 + 1000
      );
    }
    return { itemsUpdate, window: newWindow };
  }
  if (newWindow === 0 || items.length === 0) {
    itemsUpdate = createNewItems(now, fields);
    newWindow = 1;
  } else if (timeFrame > 1 * 60 * 1000 && newWindow === 1) {
    itemsUpdate = prepend(1, items, fields);
    newWindow = 2;
  } else if (timeFrame < 1 * 60 * 1000 && newWindow === 1) {
    itemsUpdate = getItemsUpdateCorrected(
      newValue,
      items,
      itemsUpdate,
      1 * 60 * 1000 + 1000
    );
  } else if (timeFrame > 2 * 60 * 1000 && newWindow === 2) {
    itemsUpdate = prepend(1, items, fields);
    newWindow = 3;
  } else if (timeFrame < 2 * 60 * 1000 && newWindow === 2) {
    itemsUpdate = getItemsUpdateCorrected(
      newValue,
      items,
      itemsUpdate,
      2 * 60 * 1000 + 1000
    );
  } else if (timeFrame > 3 * 60 * 1000 && newWindow === 3) {
    itemsUpdate = prepend(2, items, fields);
    newWindow = 5;
  } else if (timeFrame < 3 * 60 * 1000 && newWindow === 3) {
    itemsUpdate = getItemsUpdateCorrected(
      newValue,
      items,
      itemsUpdate,
      3 * 60 * 1000 + 1000
    );
  } else if (timeFrame > 5 * 60 * 1000 && newWindow === 5) {
    itemsUpdate = prepend(5, items, fields);
    newWindow = 10;
  } else if (timeFrame < 5 * 60 * 1000 && newWindow === 5) {
    itemsUpdate = getItemsUpdateCorrected(
      newValue,
      items,
      itemsUpdate,
      5 * 60 * 1000 + 1000
    );
  } else {
    itemsUpdate = getItemsUpdateCorrected(
      newValue,
      items,
      itemsUpdate,
      10 * 60 * 1000 + 1000
    );
  }
  return { itemsUpdate, window: newWindow };
}

function getItemsUpdateCorrected(newValue, items, itemsUpdate, diffValue) {
  const endTime = newValue?.time?.getTime();
  const startTime = items[0]?.time?.getTime();
  if (!endTime || !startTime) {
    return items;
  }
  if (endTime - startTime > diffValue) {
    let j = items.length - 1;
    let newItems = [];
    while (j >= 0 && endTime - items[j].time.getTime() <= diffValue) {
      if (endTime - items[j].time.getTime() <= diffValue) {
        newItems.unshift(items[j]);
      }
      j--;
    }
    itemsUpdate = newItems;
  } else {
    itemsUpdate = items;
  }
  return itemsUpdate;
}

function createNewItems(now, fields) {
  let newItems = [];
  const start = now.getTime() - 1 * 60 * 1000;
  for (let i = start; i < now.getTime(); i += 1000) {
    const time = new Date(i);
    time.setMilliseconds(500);
    const newItem = { time };
    for (let field of fields) {
      newItem[field.name] = 0;
    }
    newItems.push(newItem);
  }
  return newItems;
}

export const useChartSizes = () => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = React.useCallback(() => {
    setScreenSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return screenSize;
};

export const MARGIN_FACET_CHART = { top: 20, right: 25, bottom: 30, left: 30 };
export const MARGIN_DPI_CHART = { top: 20, right: 45, bottom: 30, left: 50 };
