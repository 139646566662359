import React, { useState, useEffect, useRef, useContext } from "react";
import { InlineInputDiv } from "./common";
import {
  buildThresholdsUpdate,
  parseThresholds,
  clearConfigAttempt,
  checkNothingPending,
} from "../api";
import ActionsContext from "common/ActionsContext";
import Request from "common/Request";
import NotForOperators from "common/NotForOperators";
import styled from "styled-components";

const doRequestMeanThreshold = () =>
  ifCl
    .run("show subscriber metrics-thresholds")
    .then(parseThresholds)
    .then((thresholds) => ({ value: thresholds["MEAN-Mbps"] }));

const MeanThresholdInputWrapper = styled.div`
  flex: 3ch 1 0;
  flex-grow: 1;
  input {
    margin-left: 1px;
  }
  & + .apply-button {
    flex: 6ch 0 0;
  }
`

const MeanThresholdInput = ({ defaultValue = 0, name = "value" }) => {
  const actions = useContext(ActionsContext);
  const input = useRef(null);
  const [error, setError] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const notifyAndRevert = (error) => {
    setError(String(error));
    clearConfigAttempt();
  };

  const doApply = () => {
    const previous = {};
    const settings = { "MEAN-Mbps": value.toFixed(3) };
    checkNothingPending()
      .then(() => ifCl.run(buildThresholdsUpdate(settings, previous), true))
      .then(() => {
        actions.send("do-load");
      })
      .catch(notifyAndRevert);
  };
  const handleInputChange = (event) => {
    const value = parseFloat(event.target.value);
    if (isNaN(value) === true) {
      setError("Must specify a float number higher than zero.");
      return;
    }
    setError(false);
    setValue(value);
  };
  const handleKeyPressed = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event.preventDefault();
      if (error !== false || value === null || isNaN(value)) {
        return;
      }
      doApply();
    }
  };
  useEffect(() => {
    if (input.current === null) {
      return;
    }
    const target = input.current;
    target.addEventListener("keydown", handleKeyPressed);
    return () => {
      target.removeEventListener("keydown", handleKeyPressed);
    };
  }, [input.current]);
  return (
    <>
      <span className="as-label" style={{whiteSpace: 'nowrap'}}>MEAN-Mbps warning threshold:</span>
      <MeanThresholdInputWrapper className="input-group">
        <div className="form-line">
          <input
            type="number"
            name={name}
            defaultValue={defaultValue}
            ref={input}
            onChange={handleInputChange}
            className="form-control align-right"
          />
        </div>
      </MeanThresholdInputWrapper>
      <div className="apply-button input-group-add">
        <NotForOperators>
          <button
            className="btn btn-link waves-effect"
            onClick={doApply}
            disabled={error !== false || value === defaultValue || isNaN(value)}
          >
            Apply
          </button>
        </NotForOperators>
      </div>
      {error !== false && <div className="error break">{error}</div>}
    </>
  );
};

const RequestMeanThresholdInput = ({ name = "value" }) => {
  const actions = useContext(ActionsContext);
  const doLoad = () => setRequest(doRequestMeanThreshold());
  const [requestMeanThreshold, setRequest] = useState(null);
  useEffect(() => doLoad() || actions.recv("do-load", () => doLoad()), []);
  return (
    <InlineInputDiv className="input-group">
      {requestMeanThreshold === null ? null : (
        <Request during={requestMeanThreshold}>
          {({ value }) => (
            <MeanThresholdInput name={name} defaultValue={value} />
          )}
        </Request>
      )}
    </InlineInputDiv>
  );
};

export default RequestMeanThresholdInput;
