/*globals login, views, components, functionality, rulesFlow, rulesRate, rulesMonitor, showModalError */
import React from "react";
import styled from "styled-components";
import Request from "common/Request";
import { parsePoliciesIntoSets } from "../api";
import SelectableTable, { Column } from "common/SelectableTable";
import { safeStr } from "common/api";

const TableContainer = styled.div`
position: relative;
  width: 100%;
  overflow: auto;
  --is-highest: #3d85c6;
  --is-higher: #6aa84f;
  --is-lower: #e69138;
  --is-lowest: #cc0000;
  --warning: #cc0000;
  .inverted {
    --is-lowest: #3d85c6;
    --is-lower: #6aa84f;
    --is-higher: #e69138;
    --is-highest: #cc0000;
  }
  .dataTable tbody td {
    transition: color 1s, background-color: 1s;
  }
  &.colorize-warnings .dataTable tbody td {
    &.warning {
      color: white;
      background: var(--warning);
    }
  }
  &.colorize-quartiles .dataTable tbody td {
    &.min-q1 {
      color: white;
      background: var(--is-lowest);
    }
    &.q1-median {
      color: white;
      background: var(--is-lower);
    }
    &.median-q3 {
      color: white;
      background: var(--is-higher);
    }
    &.q3-max {
      color: white;
      background: var(--is-highest);
    }
  }
`;

const StyledRemoveAllLink = styled.a`
  margin-top: 0;
`;

let page = 0;
let numLines = 25;
const returnTo = "viewStatusSubscribers";
const notifyIfPolicyNotFound = (error) => {
  const notFound = error.match(/%ERR-ENOENT: Cannot find "(.*)" policy/);
  if (notFound !== null) {
    const [_add, policyName] = notFound;
    const message = `Assigned policy ${policyName} is not defined. \
    A policy with this name needs to be configured, or the subscriber will be assigned\
    a configured policy according to the existing rules`;
    showModalError("Error:", message);
  }
  throw error;
};

const openPolicy = (name) => {
  const policiesInSets = ifCl.run("show policy").then(parsePoliciesIntoSets);
  //that.dTableNoClear = true;
  policiesInSets.then(({ types, sources }) => {
    views.doKeep(returnTo);
    ifCl
      .run(`show policy ${safeStr(name)}`)
      //.run(`show policy ${name}`)
      .catch(notifyIfPolicyNotFound)
      .then((response) => {
        const policy = types.rate.has(name)
          ? rulesRate.data.policies.parsePolicy(response)
          : types.flow.has(name)
          ? rulesFlow.data.policies.parsePolicy(response)
          : rulesMonitor.data.policies.parsePolicy(response);

        return sources.static.has(name) !== true
          ? rulesRate.data.policies.showDynPolicy(policy, response)
          : types.rate.has(name)
          ? rulesRate.data.policies.editPolicy(policy, returnTo)
          : types.flow.has(name)
          ? rulesFlow.data.policies.editPolicy(policy, returnTo)
          : rulesMonitor.data.policies.editPolicy(policy, returnTo);
      });
  });
};

const openSubscriberDetails = (target, _row) => {
  views.doKeep(returnTo);
  globalNavigate("viewStatusSubscribers", {...target, returnView: returnTo})
};

const maxMbpsCellClassName = (
  _value,
  [
    _addr,
    _lifetime,
    _block,
    _policy,
    _total,
    _flows,
    _cur,
    mean,
    value,
    limit,
    ..._rest
  ],
  column
) =>
  limit === null
    ? "un-limited"
    : column.threshold === undefined || column.threshold === null
    ? "no-threshold"
    : column.threshold.calculated === true
    ? "automatic-threshold"
    : value === null
    ? "n-a"
    : column.threshold.inWarning((value / limit) * 100, mean) +
      ` limit-${limit}`;

export function getColumns() {
  const columns = [
    Column.IPAddress({
      label: "IP-ADDR",
      idx: 0,
      cellClassName: "hyperlink-text",
      title: "Subscriber’s IP address",
      onClick: (addr, _row) => openSubscriberDetails({ addr }),
    }),
    Column.Text({
      label: "SUBS-ID",
      idx: 1,
      cellClassName: "hyperlink-text",
      title: "Subscriber ID",
      onClick: (subsId, [_addr, ..._row], _trigger) =>
        openSubscriberDetails({ subsId }),
    }),
    Column.Text({
      label: "BLOCK",
      title: login.isPlatform('gs-kr')
        ? "Blocking from quota"
        : "Blocking from billing configuration or quota",
      idx: 2,
      colClassName: "align-left",
    }),
    Column.Text({
      label: "RATE-POLICY",
      title: "Name of the subscriber's rate police",
      idx: 12,
      cellClassName: "hyperlink-text",
      colClassName: "align-left",
      onClick: (policy, _row, _trigger) => openPolicy(policy),
    }),
    Column.NumberOrNA({
      label: "MBYTES",
      title:
        "Total up or down traffic volume of this subscriber since it became active (see active lifetime field)",
      idx: 5,
      precision: 0,
      colClassName: "align-right",
    }),
    Column.Number({
      label: "FLOWS",
      title:
        "Total number of currently active traffic flows (TCP connections, UDP or IP flows)",
      idx: 3,
      precision: 0,
      colClassName: "align-right",
    }),
    Column.NumberOrNA({
      label: "CURR-Mbps",
      title: "Current speed in Mbps",
      idx: 6,
      precision: 1,
      colClassName: "align-right",
    }),
    Column.NumberOrNA({
      label: "MEAN-Mbps",
      title:
        "Moving average of the mean speed (calculated every 10 minutes) in Mbps.",
      idx: 7,
      precision: 3,
      colClassName: "align-right",
    }),
    Column.NumberOrNA({
      label: "MAX-Mbps",
      title: "maximum speed in Mbps during the previous 24 hours",
      idx: 8,
      precision: 1,
      thousands: false,
      colClassName: "align-right",
      cellClassName: maxMbpsCellClassName,
    }),
    Column.HiddenNumberOrNA({
      label: "LIM-Mbps",
      idx: 9,
      precision: 1,
    }),
    Column.NumberOrNA({
      label: "RTT-ms",
      title:
        "Moving average of latency, in milliseconds. If downlink, latency from this node to subs, if uplink, latency from this node to Internet server",
      idx: 10,
      precision: 1,
      colClassName: "align-right",
    }),
    Column.NumberOrNA({
      label: "RTX",
      title:
        "Moving average of the percentage of TCP retransmissions (which reflect packet losses in this direction)",

      idx: 11,
      percent: true,
      precision: 1,
      colClassName: "align-right",
    }),
    Column.NumberOrNA({
      label: "MAX-SPEED-%",
      field: "MAX-SPEED",
      title:
        "Moving average of the percentage of traffic sent with a speed close to the maximum",
      idx: 13,
      percent: true,
      colClassName: "align-right",
    }),
    Column.NumberOrNA({
      label: "CONGESTION",
      field: "CONGEST",
      title: "Moving average of percentage of traffic suffering congestion",
      idx: 14,
      percent: true,
      colClassName: "align-right",
    }),
    Column.NumberOrNA({
      label: "WARN",
      title: "Number of warnings",
      idx: 15,
      percent: false,
      colClassName: "align-right",
    }),
    Column.Time({
      label: "LIFETIME",
      title: "Duration so far of this subscriber session",
      idx: 16,
      colClassName: "align-right",
    }),
  ];
  return columns;
}

const mergeCellClassNames = (cellClassName, classify) =>
  cellClassName === undefined && classify === undefined
    ? undefined
    : cellClassName === undefined
    ? (...args) => `not-merged ${classify(...args)}`
    : typeof cellClassName === "function"
    ? (...args) =>
        `merged-classes ${cellClassName(...args)} ${classify(...args)}`
    : `merged-classes ${cellClassName}`;

const filterOutWarning = (classify) =>
  typeof classify === "function"
    ? (...args) => classify(...args).replace(/warning/, "")
    : classify;

const provideValueAndMeanTo =
  (classify) =>
  (
    value,
    [_addr, _subsId, _block, _policy, _total, _active, _curMbps, mean, ...rest],
    _column
  ) =>
    classify(value, mean);

const extendColumnWithContext = (
  { label, cellClassName, ...column },
  { threshold = {}, classify, ...context }
) => ({
  threshold,
  ...context,
  cellClassName:
    label === "MAX-Mbps"
      ? mergeCellClassNames(
          cellClassName,
          filterOutWarning(provideValueAndMeanTo(classify))
        )
      : mergeCellClassNames(cellClassName, provideValueAndMeanTo(classify)),
});

const addQuartileClassification = (statistics, columns) => {
  const { groups } = statistics;
  const contextByField = Object.fromEntries(
    groups.map(({ field, classify, quartiles, threshold }) => [
      field,
      { classify, quartiles, threshold },
    ])
  );
  return columns.map((column) => ({
    ...column,
    ...(column.field in contextByField
      ? extendColumnWithContext(column, contextByField[column.field])
      : {}),
  }));
};

const doesNothing = () => {};

const ListSubscribers = ({
  request,
  colorizeQuartiles = false,
  onPlotSelection = doesNothing,
}) => {
  return (
    <TableContainer
      className={colorizeQuartiles ? "colorize-quartiles" : "colorize-warnings"}
      id="subscribersTable"
    >
      <Request during={request}>
        {({ paginator, statistics }) => (
          <>         
            <SelectableTable
              selectionField={"plot"}
              selectionLabel={"PLOT"}
              asObject={([addr, subsId]) => ({addr, subsId})}
              onSelection={onPlotSelection}
              selectFirst={10}
              pageLength={numLines}
              page={page}
              exportAsCSV={true}
              paginator={paginator}
              columns={
                statistics === undefined
                  ? paginator.getColumns()
                  : addQuartileClassification(
                      statistics,
                      paginator.getColumns()
                    )
              }
            />
          </>
        )}
      </Request>
    </TableContainer>
  );
};

export default ListSubscribers;
