/*globals views*/
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Request from "common/Request";
import ColumnsGrid from "common/layouts/ColumnsGrid";
import * as d3 from "d3";
import {
  parseSystemDate,
  parseSubscriberVolume,
  parseSubscriberFlows,
  calcFlowsCreationPerMinute,
  parseSubscriberMaxSpeeds,
  parseSubscriberLatencies,
  parseSubscriberRetransmissions,
  parseSubscriberCongestion,
} from "./api";
import { safeStr } from 'common/api';
import RangeSliderInput from "common/RangeSliderInput";
import { FacetGraph } from "common/graphs/FacetGraph";
import { ConnectedLineChart } from "common/graphs/ConnectedLineChart";
import formats from "common/graphs/formats";
import SelectInputWithIcon from "common/SelectInputWithIcon";

const doesNothing = () => {};

export const doStatsRetrieval = ({
  facet = "volume",
  hours = 24,
  interval = 10,
  subscriberGroup,
}) =>
  ifCl.run(
    `show statistics subscriber-groups name ${safeStr(subscriberGroup)} ${facet} hours ${hours} interval ${interval}`
  );

const _hoursScopeChoices = [
  { value: 24, label: "1 Day" },
  { value: 24 * 7, label: "1 Week" },
  { value: 24 * 30, label: "1 Month" },
  { value: 24 * 30 * 3, label: "3 Months" },
];

const TileContainer = styled.div`
  min-height: 10cm;
  @media print {
    & {
      height: 8cm;
      &.can-compress {
        height: auto;
      }
      width: 100%;
      display: block:
    }
  }
  display: flex;
  flex-direction: column;
  & > h4 {
    text-align: center;
    margin: 20px 0 0 0;
  }
  & > .graph {
    flex: 1 1 100%;
    .nv-y.nv-axis .nv-axisMaxMin {
      display: none;
    }
  }
  &.spread {
    justify-content: space-between;
    padding: 1em;
    align-content: flex-start;
  }
  &.can-compress {
    min-height: 0;
  }
.input-group {
    max-width: 20ch;
  }
`;

const TileContainerControl = styled.div`
  min-height: 10cm;
  @media print {
    & {
      height: 8cm;
      &.can-compress {
        height: auto;
      }
      width: 100%;
      display: block:
    }
  }
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  & > h4 {
    text-align: center;
    margin: 20px 0 0 0;
  }
  & > .graph {
    flex: 1 1 100%;
    .nv-y.nv-axis .nv-axisMaxMin {
      display: none;
    }
  }
  &.spread {
    justify-content: space-between;
    padding: 1em;
    align-content: flex-start;
  }
  &.can-compress {
    min-height: 0;
  }
  .input-group {
    margin-bottom: 0px;
  }
`;

const StyledFlexDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const returnView = "viewSubscriberGroupDashboard";

const ContextControls = ({
  range,
  hours,
  onChange = doesNothing,
  children,
  subscriberGroup,
  settingsSubscriberGroups
}) => {
  const StyledButton = styled.button`
    display: flex;
    align-items: center;

    .material-icons {
      padding-right: 10px;
    }
  `;
  const handleSelectChange = (event) => {
    const hours = parseInt(event.target.value);
    onChange({ hours });
  }

  return (
    <>
      <TileContainerControl className="full-width can-compress">
        <SelectInputWithIcon
          title="Date Range"
          name="Date Range"
          icon="date_range"
          selected={hours}
          onChange={handleSelectChange}
          options={_hoursScopeChoices}
        />
        <StyledFlexDiv>
          <div className="input-group">
            <StyledButton
              type="button"
              className="btn btn-link waves-effect"
              onClick={() => {
                views.doKeep(returnView);
                globalNavigate("viewStatusSubscribers", {
                  returnView,
                  subscriberGroup,
                });
              }}
            >
              <span className="input-group-addon" title="Date Range">
                <i className="material-icons">multiline_chart</i>
              </span>
              See main subscribers in group
            </StyledButton>
          </div>
          <div className="input-group">
            <StyledButton
              type="button"
              className="btn btn-link waves-effect"
              onClick={() => {
                globalNavigate("viewStatusSubscriberGroupsEdit", {
                  returnView: "viewSubscriberGroupDashboard",
                  subscriberGroup,
                  ...settingsSubscriberGroups
                });
              }}
            >
              <span className="input-group-addon" title="Date Range">
                <i className="material-icons">edit</i>
              </span>
              Edit subscriber group
            </StyledButton>
          </div>
        </StyledFlexDiv>
      </TileContainerControl>
      {children}
      <TileContainer className="full-width can-compress">
        <RangeSliderInput {...range} onChange={onChange} />
      </TileContainer>
    </>
  );
};

const calcTimeRange = (items) => {
  const [from, to] = d3.extent(items.map((item) => item.time));
  return { from, to };
};

const isInsideRange =
  ({ from, to }, field = "time") =>
  (item) =>
    from <= item[field] && item[field] <= to;

const SpeedGraph = ({ items, range }) => (
  <TileContainer className="chart-container">
    <h4 className="chart-title">Average speed</h4>
    <div className="graph">
      <ConnectedLineChart
        facet="speed"
        items={items.filter(isInsideRange(range))}
        yAxisUnits="Mbps"
        xAxisFormat={d3.timeFormat("%m/%d %H:%M")}
        yTooltipFormat={formats.decimals(4)}
        xField="time"
        yAxisTopGap={0.1}
        yAxisTicksNumber={5}
        fields={[
          { name: "downMbps", label: "Down-Mbps" },
          { name: "upMbps", label: "Up-Mbps" },
        ]}
      />
    </div>
  </TileContainer>
);

const parseAndCalcFlowsCreationPerMinute = (response) =>
  calcFlowsCreationPerMinute(parseSubscriberFlows(response));

const formatMillions = (value) =>
  value < 1000
    ? value
    : value < 1000000
    ? `${(value / 1000).toFixed(1)}K`
    : `${(value / 1000000).toFixed(1)}M`;

const Dashboard = ({
  target,
  volumes,
  systemDate,
  interval = 10,
  hours = 24,
  onHoursChange = doesNothing,
  subscriberGroup,
  settingsSubscriberGroups
}) => {
  const initialRange = calcTimeRange(volumes);
  const [range, setRange] = useState(initialRange);
  const applyRange = (newRange) => setRange(newRange);
  const applyChange = ({ hours, ...range }) => {
    if (hours !== undefined) {
      onHoursChange(hours);
    } else {
      applyRange(range);
    }
  };

  return (
    <ColumnsGrid gap="1rem" columns={2} minWidth="9cm" rowGap="0">
      <ContextControls
        target={target}
        className="full-width"
        systemDate={systemDate}
        range={initialRange}
        hours={hours}
        onChange={applyChange}
        subscriberGroup={subscriberGroup}
        settingsSubscriberGroups={settingsSubscriberGroups}
      >
        <SpeedGraph items={volumes} range={range} />

        <FacetGraph
          command={`show statistics subscriber-groups name ${safeStr(subscriberGroup)} flows hours ${hours} interval 10`}
          target={target}
          facet="flows"
          title="Flows"
          units="Flows"
          fields={[
            { name: "flCreatedPerMinute", label: "Fl-Created-per-minute" },
            { name: "flActive", label: "Fl-Active" },
          ]}
          parse={parseAndCalcFlowsCreationPerMinute}
          range={range}
          hours={hours}
          interval={interval}
          yAxisFormat={formatMillions}
          yTooltipFormat={formats.decimals(0)}
          yAxisTicksNumber={4}
          shouldReloadOnCommandChange={false}
        />

        <FacetGraph
          command={`show statistics subscriber-groups name ${safeStr(subscriberGroup)} latency hours ${hours} interval 10`}
          target={target}
          facet="latency"
          title="Latency"
          units="milliseconds"
          fields={[
            { name: "subsAccessRttMs", label: "Subs-Access-Rtt-Ms" },
            { name: "networkAccessRttMs", label: "Network-Access-Rtt-Ms" },
          ]}
          parse={parseSubscriberLatencies}
          range={range}
          shouldReloadOnCommandChange={false}
        />

        <FacetGraph
          command={`show statistics subscriber-groups name ${safeStr(subscriberGroup)} retransmission hours ${hours} interval 10`}
          target={target}
          facet="retransmission"
          title="Packet retransmissions"
          units="% retransmisions"
          fields={[
            { name: "subsAccessRtx", label: "Subs-Access-Rtx-%" },
            { name: "networkAccessRtx", label: "Network-Access-Rtx-%" },
          ]}
          parse={parseSubscriberRetransmissions}
          range={range}
          shouldReloadOnCommandChange={false}
        />

        <FacetGraph
          command={`show statistics subscriber-groups name ${safeStr(subscriberGroup)} congest hours ${hours} interval 10`}
          target={target}
          facet="congest"
          title="Congestion"
          units="% congestion"
          fields={[{ name: "congestion", label: "Congestion-%" }]}
          parse={parseSubscriberCongestion}
          range={range}
          shouldReloadOnCommandChange={false}
        />

        <FacetGraph
          command={`show statistics subscriber-groups name ${safeStr(subscriberGroup)} max-speed hours ${hours} interval 10`}
          target={target}
          facet="max-speed"
          title="% Traffic at Max Speed"
          units="% traffic"
          fields={[{ name: "maxSpeed", label: "% Traffic at Max Speed" }]}
          parse={parseSubscriberMaxSpeeds}
          range={range}
          shouldReloadOnCommandChange={false}
        />
      </ContextControls>
    </ColumnsGrid>
  );
};

const systemTimeRetrieval = () =>
  ifCl.run("show system date").then(parseSystemDate);

const retrieval = ({ subscriberGroup, target, hours, interval }) =>
  Promise.all([
    systemTimeRetrieval(),
    doStatsRetrieval({
      facet: "mbps",
      target,
      hours,
      interval,
      subscriberGroup,
    })
      .then(parseSubscriberVolume)
      .catch((error) => console.error(error) || { volumes: [] }),
  ]).then(([systemDate, volumes]) => ({ systemDate, volumes }));

const _defaultContext = {
  hours: 24,
  interval: 10,
};

const hoursByTimePeriodMap = {
  oneDay: 24,
  oneWeek: 24 * 7,
  oneMonth: 24 * 30,
  threeMonths: 24 * 30 * 3,
};

const intervalForHours = (hours, interval = 10) =>
  hours > 7 * 24 ? 60 : interval;

const SubscriberGroupDashboard = ({target, children, subscriberGroup,  settingsSubscriberGroups}) => {
  const [request, setRequest] = useState(null);
  const initContext = {..._defaultContext, hours: hoursByTimePeriodMap[settingsSubscriberGroups.timePeriod]};
  const [context, setContext] = useState(initContext);

  const setHoursInContext = (hours) =>
    setContext({ hours, interval: intervalForHours(hours) });
  const doLoad = () => {
    setRequest(
      retrieval({ subscriberGroup, facet: "mbps", target, ...context })
    );
  };

  const targetStr = target ? (target.addr ? target.addr : target.subsId) : "";
  const contextStr = '' + context.hours + context.interval;

  useEffect(() => {
    setRequest(null);
    doLoad();
  }, [targetStr, contextStr]);

  return request === null ? null : (
    <Request during={request}>
      {(result) => (
        <Dashboard
          {...result}
          {...context}
          target={target}
          onHoursChange={setHoursInContext}
          subscriberGroup={subscriberGroup}
          settingsSubscriberGroups={settingsSubscriberGroups}
        />
      )}
    </Request>
  );
};
export default SubscriberGroupDashboard;
