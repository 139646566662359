import { InvalidFieldValue } from "common/SettingsView";

export const parseNames = (input) => {
  const [_head, ...lines] = input.trim("\n").split("\n");
  const names = lines.map((line) => line.replace(/\s.*$/, ""));
  return { names };
};

//const _defaultGroups = ['MAX-Mbps', 'RTT-ms', 'AVG-RTX', 'AVG-CONG', 'AVG-MAX'];
const _defaultGroups = ["MBPS-MAX", "MS-RTT", "RTX-AVG", "CONG-AVG", "MAX-AVG"];

const notNull = (entry) => entry !== null;

const mergeIntoSets = ({ types, sources }, row) => {
  const [name, type, source] = row.split(/\s+/);
  types = {
    ...types,
    [type]: type in types ? types[type].add(name) : new Set([name]),
  };
  sources = {
    ...sources,
    [source]: source in sources ? sources[source].add(name) : new Set([name]),
  };
  return { sources, types };
};

const initialPolicySets = () => ({
  types: { rate: new Set(), flow: new Set() },
  sources: { static: new Set() },
});

export const parsePoliciesIntoSets = (response) => {
  const lines = response.trim("\n").split("\n");
  const [_head, ...rows] = lines;
  return rows.reduce(mergeIntoSets, initialPolicySets());
};

const asThresholdField = (row) =>
  row
    .split(/:\s+/)
    .map((part, index) => (index === 0 ? part : parseFloat(part)));

const nonZero = ([_field, value]) => value !== 0;

export const parseThresholds = (response) => {
  const rows = response.trim("\n").split("\n");
  return Object.fromEntries(
    rows.map((row) => asThresholdField(row)).filter(nonZero)
  );
};

const valuesAsFloat = ([field, value]) => {
  if (value === undefined || value.length === 0) {
    throw new InvalidFieldValue(field, `value "${value}" cannot be empty`);
  }
  const result = parseFloat(value);
  if (isNaN(result) === true) {
    throw new InvalidFieldValue(
      field,
      `value "${value}" is not a valid number`
    );
  }
  return [field, result];
};

const thoseDifferentFrom =
  (previous) =>
  ([field, value]) =>
    previous[field] !== value;

const asUpdateCommand = ([field, value]) =>
  `metrics threshold ${field.toLowerCase()} ${value}`;

export const buildThresholdsUpdate = (settings, previous = {}) => {
  const updates = thresholdsUpdates(settings, previous);
  return updates.length === 0
    ? ""
    : `configure
pkteng
${thresholdsUpdates(settings, previous)}
commit
`; // Not nice indent but more performant
};

const thresholdsUpdates = (settings, previous = {}) =>
  Object.entries(settings)
    .map(valuesAsFloat)
    .filter(thoseDifferentFrom(previous))
    .map(asUpdateCommand)
    .join("\n");

export const clearConfigAttempt = () =>
  ifCl.run("configure\nclear config changes\n", /*is batch*/ true);

const CANT_PROCEED_ERROR =
  "Cannot proceed while there are pending config changes." +
  " Try again later.";

export const checkNothingPending = () =>
  ifCl.run("show config diff").then((response) => {
    if (response.length > 0) {
      throw new Error(CANT_PROCEED_ERROR);
    }
  });
