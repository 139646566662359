import {useRef} from 'react';

const useFirstTime = () => {
  const isFirstTime = useRef(true);
  const check = () => {
    if (isFirstTime.current) {
      isFirstTime.current = false;
      return true;
    } 
    return false;
  }
  const reset = () => isFirstTime.current = true;
  check.reset = reset;
  return check;
}

export default useFirstTime;
