import React, { useReducer, useRef, useEffect } from "react";
import Datatable, { Column } from "./DataTable";
import styled from "styled-components";

const doesNothing = () => {};

const matchingTo = (target) => {
  const targetRepr = Object.entries(target).toString();
  return (current) => targetRepr === Object.entries(current).toString();
};

const selectionReducer = ({ ready, list = [] }, action) => {
  switch (action.type) {
    case "clear": {
      return {
        ready,
        list: [],
      };
    }
    case "fill": {
      return {
        ready,
        list: [...list, ...action.elements],
      };
    }
    case "ready": {
      return {
        ready: true,
        list,
      };
    }
    case "toggle": {
      const { target } = action;
      const foundAt = list.findIndex(matchingTo(target));
      return {
        ready,
        list:
          foundAt > -1
            ? list.filter((elem, idx) => idx !== foundAt)
            : [...list, target],
      };
    }
    case "select": {
      const { target } = action;
      const foundAt = list.findIndex(matchingTo(target));
      return {
        ready,
        list: foundAt > -1 ? list : [...list, target],
      };
    }
  }
  throw Error("Unknown action: " + action.type);
};

const asValue = (value) => ({ value });
const PageRowsSelectionWrapper = styled.div``;

const getDataTable = (tableBody) => $(tableBody.parentElement).DataTable();

const PersistentDatatable = React.memo(Datatable, (_prev, _next) => true);

const isOnTheFirst = limit => {
  let count = 0
  return () => {
    count += 1;
    return count <= limit;
  } 
} 

const PageRowsSelection = ({
  selectionClass,
  asObject = asValue,
  selected = [],
  select = doesNothing,
  clear = doesNothing,
  ready = doesNothing,
  selectFirst = false,
  children,
}) => {
  const wrapper = useRef(null);
  const selectedRef = useRef(selected);

  const isSelected = (target) =>
    selectedRef.current.find(matchingTo(target)) !== undefined;

  const updateIcon = (row, selected) => {
    const selectionCheck = row.querySelector(
      `.action.selection .${selectionClass} i`
    );
    if (selectionCheck === null) {
      return;
    }
    selectionCheck.innerText = selected
      ? "check_box"
      : "check_box_outline_blank";
  };

  const enableHeader = () => {
    const tableHead = wrapper.current.querySelector("thead");
    const header = tableHead.querySelector(`tr th.${selectionClass}`);
    header.innerHTML =
      header.innerHTML +
      `
      <a class="action ${selectionClass}-all" title="de-select all"
        data-toggle="tooltip" data-placement="top">
        <i class="material-icons actions-icon-color">remove_circle_outline</i>
      </a>`;
    const control = header.querySelector(`a.${selectionClass}-all`);
    control.addEventListener("click", clear);
  };

  const forAllRowsAndDataDo = (callback) => {
    const tableBody = wrapper.current.querySelector("tbody");
    const datatable = getDataTable(tableBody);
    const getRowDataRow = (row) => datatable.row(row).data();
    tableBody
      .querySelectorAll("tr")
      .forEach((row) => {
        const data = getRowDataRow(row);
        data !== undefined && callback(row, asObject(data.__source__ || data));
      });
  };

  const updateRows = () => {
    forAllRowsAndDataDo((row, data) => updateIcon(row, isSelected(data)));
  };

  const selectCurrentRows = (selection) => {
    const thisToo = selection === true
      ? () => true //first whole page
      : isOnTheFirst(selectFirst) //first N elements
    forAllRowsAndDataDo((row, data) => thisToo() && select(data));
  };

  useEffect(() => {
    if (wrapper.current === null) {
      return;
    }
    selectedRef.current = selected;
    updateRows();
  }, [selected]);

  useEffect(() => {
    if (wrapper.current === null) {
      return;
    }
    const tableBody = wrapper.current.querySelector("tbody");
    const datatable = getDataTable(tableBody);
    datatable.on("draw", updateRows);
    selectFirst !== false
      ? selectCurrentRows(selectFirst) // will trigger update later
      : updateRows();
    enableHeader();
    ready();
  }, [wrapper]);

  return (
    <>
      <PageRowsSelectionWrapper ref={wrapper}>
        {children}
      </PageRowsSelectionWrapper>
    </>
  );
};

const SelectionColumn = ({
  field,
  label,
  onCellRender = doesNothing,
  idx = 0,
  ...rest
}) => ({
  ...Column.Value(
    () => `
        <a class="action selection-${field}" title="${label}"
          data-toggle="tooltip" data-placement="top">
          <i class="material-icons actions-icon-color">check_box_outline_blank</i>
        </a>
      `,
    {
      idx,
      label,
      ...rest,
    }
  ),
  exportable: false,
  idx,
  onCellRender,
});


const SelectableTable = ({
  asObject = asValue,
  columns = [],
  onSelection = doesNothing,
  selectionLabel,
  selectionField,
  selectFirst = false,
  paginator = undefined,
  ...params
}) => {
  const [selection, dispatch] = useReducer(selectionReducer, {
    ready: false,
    selected: [],
  });
  const toggle = (target) => dispatch({ type: "toggle", target });
  const select = (target) => dispatch({ type: "select", target });
  const clear = (target) => dispatch({ type: "clear", target });
  const ready = () => dispatch({ type: "ready" });
  React.useEffect(() => {
    selection.ready === true && onSelection(selection.list);
  }, [selection]);

  const selectionClass = `selection-${selectionField}`;
  const selectionColumn = SelectionColumn({
    idx: 0,
    colClassName: `text-center action selection ${selectionClass}`,
    label: selectionLabel,
    field: selectionField,
    onClick: (_value, row) => toggle(asObject(row)),
  });
  const allColumns = [...columns, selectionColumn];
  return (
    <PageRowsSelection
      selectionClass={selectionClass}
      asObject={asObject}
      selected={selection.list}
      select={select}
      clear={clear}
      ready={ready}
      selectFirst={selectFirst}
      {...params}
    >
      <PersistentDatatable
        columns={allColumns}
        paginator={paginator //parsed paginator data has to be extended
          ? paginator.injectColumn(selectionColumn, null)
          : undefined}
        {...params}
      />
    </PageRowsSelection>
  );
};

export default SelectableTable;

export { Column };
