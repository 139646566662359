import * as d3 from "d3";
import { expressTarget } from "common/api";

export const parseSystemDate = (input) => new Date(input);

export const parseFloatOrNull = (value) =>
  value === "n/a"
    ? null
    : value.endsWith("%") === true
    ? parseFloat((parseFloat(value.trim("%")) / 100).toFixed(4))
    : parseFloat(value);

const fixedPrecision = (value, decimals = 4) =>
  parseFloat(value.toFixed(decimals));

const addVolumeField = (volume) => (share) => ({
  ...share,
  volume: fixedPrecision(volume * share.share, 4),
});

const combineVolumeWithShare = (volume, share) => ({
  ...volume,
  services: share.services.map(addVolumeField(volume.volume)),
});

const projectShareInVolume = (volumes, share) =>
  volumes.length === 0
    ? []
    : share.length === 0
    ? volumes
    : extractAndCombine(volumes, share);

const extractAndCombine = ([current, ...rest], [share, ...nextShare]) =>
  share.time.getTime() === current.time.getTime()
    ? [
        combineVolumeWithShare(current, share),
        ...projectShareInVolume(rest, nextShare),
      ]
    : [current, ...projectShareInVolume(rest, [share, ...nextShare])];

const addSpeedField = (speed) => (share) => ({
  ...share,
  speed: fixedPrecision(speed * share.share, 4),
});

const combineSpeedWithShare = (hourlySpeed, share) => ({
  ...hourlySpeed,
  services: share.services.map(addSpeedField(hourlySpeed.speed)),
});

const projectShareInSpeed = (speeds, share) =>
  speeds.length === 0
    ? []
    : share.length === 0
    ? speeds
    : extractAndCombineSpeed(speeds, share);

const extractAndCombineSpeed = ([current, ...rest], [share, ...nextShare]) =>
  share.time.getTime() === current.time.getTime()
    ? [
        combineSpeedWithShare(current, share),
        ...projectShareInSpeed(rest, nextShare),
      ]
    : [current, ...projectShareInSpeed(rest, [share, ...nextShare])];

const serviceAndLatency = (row) => {
  const [service, latency] = row.split(/\s+/);
  return [
    ["service", service],
    ["latency", parseFloatOrNull(latency)],
  ];
};

export const parseServiceLatencies = (input) => {
  const [_head, ...rows] = input.trim("\n").split("\n");
  return rows.map((row) => Object.fromEntries(serviceAndLatency(row)));
};

const latenciesByRTT = (row) => {
  const [upRttBin, share] = row.split(/\s+/);
  return [
    ["bin", upRttBin],
    ["share", fixedPrecision(parseFloatOrNull(share) / 100, 4)],
  ];
};

export const parseLatenciesByRTT = (input) => {
  if (input.length === 0) {
    return [];
  }
  const [_head, ...rows] = input.trim("\n").split("\n");
  return rows.map((row) => Object.fromEntries(latenciesByRTT(row)));
};

const latenciesByTime = (row, [_time, ...bins]) => {
  const [time, ...binValues] = row.split(/\s+/);
  const values = bins.map((bin, index) => ({
    bin,
    share: fixedPrecision(parseFloatOrNull(binValues[index]), 4),
  }));
  return [
    ["time", parseSystemDate(time)],
    ["values", values],
  ];
};

export const parseLatenciesByTime = (input) => {
  const [head, ...rows] = input.trim("\n").split("\n");
  const keys = head.split(/\s+/);
  return rows.map((row) => Object.fromEntries(latenciesByTime(row, keys)));
};

const latencyTargetOptions = {
  noTarget: "all-subs",
  subsIdKeyword: "subscriber-id",
  addressKeyword: "ip-access",
};
export const expressLatencyTarget = (target) =>
  expressTarget(target, latencyTargetOptions);

export const expressDestination = (destination) =>
  destination === null ? "all-internet" : `ip-internet ${destination}`;
