import React from "react";

const Loading = () => (
  <div className="row" style={{ margin: "20px 50%" }}>
    <div className="preloader pl-size-xl">
      <div className="spinner-layer pl-black">
        <div className="circle-clipper left">
          <div className="circle"></div>
        </div>
        <div className="circle-clipper right">
          <div className="circle"></div>
        </div>
      </div>
    </div>
  </div>
);

export default Loading;
