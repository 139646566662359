/* global login */
import React, { useEffect, useState, createContext } from "react";

const ProfileContext = createContext(null);

const userAndProfile = (row) => {
  const [name, profile] = row.trim(" ").split(/\s+/);
  return [name, profile];
};

export const findUserProfile = (user) => (input) => {
  const [_head, ...rows] = input.trim("\n").split("\n");
  const [_name, profile] =
    rows.map(userAndProfile).find(([name, _profile]) => user === name) || [];
  return profile || "unknown";
};

const doRequestUserProfile = (userName) =>
  ifCl.run("show users").then(findUserProfile(userName));

export const asProfileParams = (profile) => ({
  isOperator: profile === "operator",
  isAdmin: profile === "admin",
});

export const ProfileContextProvider = ({ children }) => {
  const [profile, setProfile] = useState({});
  useEffect(() => {
    doRequestUserProfile(login.user).then(asProfileParams).then(setProfile);
  }, []);
  return (
    <ProfileContext.Provider value={profile}>
      {profile === null ? null : children}
    </ProfileContext.Provider>
  );
};

export default ProfileContext;
