import React, { useEffect, useRef } from "react";
import Canvas from "./Canvas";
import * as d3 from "d3";
import styled from "styled-components";
import Axis, { remaining } from "./Axis";
import { wrapText, provideGroupOn } from "common/graphs/common";
///https://d3-graph-gallery.com/graph/barplot_grouped_basicWide.html

const PlotLayout = styled.div`
  display: flex;
  flex: 1 1 100%;
  min-height: ${(props) => props.minHeight || "8cm"};
  width: 100%;
  .bar-plot {
    display: flex;
    flex: 100% 1 1;
    .x.axis text {
      transform: translate(0, 0.5em) rotate(45deg);
      transform-origin: top left;
      text-anchor: start;
    }
    &,
    & > svg {
      height: auto;
    }
  }
`;

const _DEFAULT_MARGIN = { top: 20, right: 0, bottom: 60, left: 40 };

const BarPlot = ({
  items = [],
  grid = false,
  margin = _DEFAULT_MARGIN,
  allYValues = undefined,
  valueLabel,
  yAxisLabel,
  minHeight = "8cm",
  showRoundCorners = false,
  ...settings
}) => {
  return (
    <PlotLayout minHeight={minHeight}>
      <Canvas className="bar-plot">
        <Axis
          margin={margin}
          data={items}
          are={[
            {
              name: "x",
              type: "band",
              value: (d) => d.key,
              along: "width",
            },
            {
              name: "y",
              value: (d) => d.value,
              values: allYValues,
              along: "height",
            },
          ]}
        >
          <Bars
            items={items}
            valueLabel={valueLabel}
            yAxisLabel={yAxisLabel}
            margin={margin}
            grid={grid}
            showRoundCorners={showRoundCorners}
            {...settings}
          />
        </Axis>
      </Canvas>
    </PlotLayout>
  );
};
const StyledG = styled.g`
  & .value-label {
    font-size: 0.9em;
    fill: currentcolor;
    text-anchor: middle;
  }
  & .value-label,
  & .value-label tspan {
    dominant-baseline: auto;
    transform: translate(0, -0.25em);
    &.hanging {
      dominant-baseline: text-before-edge;
      transform: translate(0, 0.25em);
      color: white;
    }
  }
  .grid .tick line {
    fill: none;
    stroke-opacity: 0.25;
    shape-rendering: crispEdges;
  }
  .axis .domain,
  .axis line.border {
    fill: none;
    stroke: currentcolor;
    shape-rendering: crispEdges;
  }
  text.clickable tspan {
    text-decoration: underline;
  }
`;

const adaptedXScale = (
  axis,
  { paddingInner = 0.2, paddingOuter = 0.2, align = 0.5, round = true }
) => {
  const scale = axis.scale
    .copy()
    .paddingInner(paddingInner)
    .paddingOuter(paddingOuter)
    .align(align)
    .round(round);
  const scaledValue = (d) => scale(axis.value(d));
  return {
    ...axis,
    scaledValue,
    scale,
  };
};

const calcDimensions = (x, y) => {
  const [x1, x2] = x.scale.range();
  const [y1, y2] = y.scale.range();
  return [x2 - x1, y1 - y2];
};

const renderGrid = (main, x, y, ticks = 5) => {
  //Draw a grid
  //
  const yAxis = d3.axisLeft(y.scale).ticks(ticks);
  const [width, _height] = calcDimensions(x, y);

  const yAxisGrid = yAxis.tickSize(-width).tickFormat("");
  const yAxisGroup = provideGroupOn(main, ".y.grid");
  yAxisGroup.call(yAxisGrid);
};

const renderAxis = (
  main,
  x,
  y,
  ticks = 5,
  valueLabel,
  margin = _DEFAULT_MARGIN
) => {
  // add the X Axis
  const [width, height] = calcDimensions(x, y);
  const xAxisGroup = provideGroupOn(main, ".x.axis");
  xAxisGroup
    .attr("transform", "translate(0," + height + ")")
    .call(d3.axisBottom(x.scale).ticks())
    .selectAll("text")
    .attr("key", (d) => d.key)
    .call(wrapText, margin.bottom * 1.3 - 8, false /*as words*/, /*dx*/ -1);

  // add the Y Axis
  const yAxisGroup = provideGroupOn(main, ".y.axis");
  yAxisGroup
    .call(
      d3
        .axisLeft(y.scale)
        .ticks(ticks)
        .tickFormat((value) => valueLabel({ value }))
    )
    .selectAll("text")
    .style("text-anchor", "end")
    .attr("dx", "-.8em")
    .attr("dy", ".15em");
  xAxisGroup
    .append("line")
    .classed("border", true)
    .attr("x1", 0)
    .attr("x2", 0)
    .attr("y1", 0)
    .attr("y2", height)
    .attr("style", `transform: translate(${width}px, -${height}px)`);
};

const defautValueLabel =
  (precision = 2) =>
  (d) =>
    d.value === null || d.value === undefined
      ? "--"
      : d.value.toFixed(precision);

const Bars = ({
  canvas,
  axis = {},
  grid = false,
  xFrom = "x",
  yFrom = "y",
  dimensions = null,
  margin = _DEFAULT_MARGIN,
  valueLabel = defautValueLabel(),
  yAxisLabel = undefined,
  onClick = undefined,
  items = [],
  showRoundCorners = false,
  ...scaleParams
}) => {
  const chart = useRef(null);
  useEffect(() => {
    if (dimensions === null) {
      return;
    }
    const { width, height } = remaining(dimensions, margin);
    if (width < 1 || height < 1) {
      return;
    }
    const xAxis = adaptedXScale(axis[xFrom], scaleParams);
    const yAxis = axis[yFrom];

    // Position main container.
    const main = d3.select(chart.current);
    main.selectAll("*").remove();
    main.attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    if (grid === true) {
      renderGrid(main, xAxis, yAxis);
    }
    // Put groups into main container
    // And bars inside those groups
    const bars = main.selectAll(".bar").data(items);
    const newBars = bars
      .enter()
      .append("rect")
      .attr("fill", "#006AB7")
      .attr("class", (d) => `bar ${d.key}`)
      .attr("rx",5);
    bars
      .merge(newBars)
      .attr("rx",5)
      .attr("x", (d) => xAxis.scaledValue(d))
      .attr("width", xAxis.scale.bandwidth())
      .attr("y", (d) => (d.y = yAxis.scaledValue(d)))
      .attr("height", (d) => height - d.y)

    if (showRoundCorners) {
      const topRoundedCorners = main
        .selectAll("rect.bar .top-corner")
        .data(items);
      const newTopRoundedCorners = topRoundedCorners
        .enter()
        .append("rect")
        .attr("fill", "#006AB7")
        .attr("rx", 0)
        .attr("class", "top-corner");

      topRoundedCorners
        .merge(newTopRoundedCorners)
        .attr("x", (d) => xAxis.scaledValue(d))
        .attr("width", xAxis.scale.bandwidth())
        .attr("y", (d) => {
          const heightTopBar =
            height - yAxis.scaledValue(d) < 5
              ? height - yAxis.scaledValue(d)
              : 5;
          return (d.y = height - heightTopBar);
        })
        .attr("height", (d) => {
          return height - yAxis.scaledValue(d) < 5
            ? height - yAxis.scaledValue(d)
            : 5;
        });
    }

    // Finally add that bars some value
    const valueLabels = main.selectAll(".value-label").data(items);
    const newValueLabels = valueLabels
      .enter()
      .append("text")
      .attr("class", (d) => `value-label ${d.key}`);
    valueLabels.exit().remove();
    valueLabels
      .merge(newValueLabels)
      .text(valueLabel)
      .attr("x", (d) => xAxis.scaledValue(d) + xAxis.scale.bandwidth() / 2)
      .attr("y", (d) => (d.value === null ? height : yAxis.scaledValue(d)))
      .classed('hanging', (d) => yAxis.scaledValue(d) < 10)

    if (grid === true) {
      const label = yAxisLabel || valueLabel;
      renderAxis(main, xAxis, yAxis, 5 /*ticks*/, label, margin);
    }
    if (onClick !== undefined) {
      const handleBarClick = function (event) {
        event.cancelBubble = true;
        const [data] = d3.select(this).data();
        onClick(data);
      };
      const handleAxisLabelClick = function (event) {
        event.cancelBubble = true;
        const [key] = d3.select(this).data();
        onClick({ key });
      };
      main
        .selectAll(".bar")
        .on("click", handleBarClick)
        .classed("clickable", true);
      main
        .selectAll(".x.axis .tick text")
        .on("click", handleAxisLabelClick)
        .classed("clickable", true);
      main.selectAll(".x.axis .tick text, .bar").classed("clickable", true);
    }

    return () => {};
  }, [chart, canvas, dimensions, axis]);

  return <StyledG ref={chart}></StyledG>;
};

export default BarPlot;
