import React, { useState, useContext, useEffect } from "react";
import Modal from "common/Modal.jsx";
import BoxPlotDemo from "./BoxPlotDemo";
import styled from "styled-components";
import ActionsContext from "common/ActionsContext";

const ListWithIcons = styled.ul`
  & li span,
  i.material-icons {
    line-height: 24px;
    vertical-align: middle;
  }
  i.material-icons {
    margin-right: 0.2em;
  }
`;

const SubscriberDetailHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  useEffect(() => {
    return actions.recv("open-help", function () {
      setOpen(true);
    });
  }, []);

  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }
  return (
    <Modal
      title="SUBSCRIBER QoE METRICS"
      onClose={doClose}
      content={() => (
        <>
          <p>
            This page shows a table with data for all active subscribers, and
            displays the distribution of the main QoE metrics in 5 boxplots.
            Boxplots are graphs that tell us how values are distributed in
            quartiles, together with the minimum, the maximum and the median
            values.
          </p>
          <p>
            A warning threshold can be set for each key QoE metric, which will
            color in red values beyond the threshold. Alternatively, if colorize
            by quartile is selected, values will be colored according to the
            quartile of the distribution they fall into.
          </p>
          <p>
            <BoxPlotDemo />
          </p>
          <p>
            Click on the IP Address or the Subscriber ID fields to get more
            detailed historical information.
          </p>
          <p>The subscriber data we display can be filtered by:</p>
          <ListWithIcons>
            <li>
              <span>
                <i className="material-icons">format_line_spacing</i>{" "}
                <b>Number</b>: Selects the total number of subscribers to load
                into the table (ordered by total volume).
              </span>
            </li>
            <li>
              <span>
                <i className="material-icons">import_export</i>
                <b>Direction</b>: Show downlink or uplink metrics. .
              </span>
            </li>
            <li>
              <span>
                <i className="material-icons">folder_open</i>
                <b>Policy</b>: Show only subscribers with the specified rate
                policy.
              </span>
            </li>
            <li>
              <span>
                <i className="material-icons">group</i>
                <b>Subscriber Group</b>: Show only those subscribers who belong to a
                subscriber group.
              </span>
            </li>
            <li>
              <span>
                <b>MEAN-Mbps warning threshold</b>: Entries with MEAN-Mbps below
                this number will not show a warning.
              </span>
            </li>
            <li>
              <span>
                <i className="material-icons" title="Address range">
                  devices
                </i>
                <b>Address</b>
              </span>
              : Show only subscribers in an address range or with a given IP
              address.
            </li>
            <li>
              <span>
                <b>Colorize</b>: It is possible to choose between showing
                entries with warnings in red or colorizing them by the quartiles
                they fall into.
              </span>
            </li>
          </ListWithIcons>
          <p>
            When "Show time-evolution of metrics" is switched on, a chart will
            show one of eight possible metrics over time (from 1 day to 3
            months) for the subscribers selected. The eight possible metrics are
            Average Speed, Maximum Speed for the last 24 hours/since last
            subs-id/policy change, Latency, Retransmissions, Active flows, Flows
            created per minute, % Traffic at max speed, and % traffic under
            congestion. The subscribers to can be selected with the tick marks
            in the "Plot" column. A maximum of 20 subscribers can be plotted.
            The subscribers can be specified by IP address od by subscriber-id.
            When different subscribers are selected/deselected, click on the
            reload button on the top right cornet of the chart to redraw the
            chart.
          </p>
        </>
      )}
      onClose={doClose}
    />
  );
};
export default SubscriberDetailHelpModal;
