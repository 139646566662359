import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import * as d3 from "d3";

const timeFormat = d3.timeFormat("%Y-%m-%d %H:%M");

const StyledRangedDiv = styled.div`
  padding: 0 2.5em;
`;

const RangeSliderInput = ({ from, to, className = "", onChange }) => {
  const input = useRef(null);
  const slider = useRef(null);
  let change = 0;
  useEffect(() => {
    if (input.current === null || slider.current !== null) {
      return;
    }
    const sliderElement = $(input.current);
    sliderElement.ionRangeSlider({
      type: "double",
      grid: true,
      min: from.valueOf(),
      max: to.valueOf(),
      //step: (end - begin) / 10,
      hide_min_max: true,
      keyboard: false,
      from: from.valueOf(),
      to: to.valueOf(),
      drag_interval: true,
      prettify: (seconds) => timeFormat(new Date(seconds)),
      onFinish: function ({ from, to }) {
        const newRange = {
          from: new Date(from),
          to: new Date(to),
          change: change++,
        };
        onChange(newRange);
      },
    });
    slider.current = sliderElement.data("ionRangeSlider");
    return () => slider.current !== null && slider.current.destroy();
  }, [input]);
  useEffect(
    () =>
      slider.current === null
        ? null
        : slider.current.update({
            min: from.valueOf(),
            max: to.valueOf(),
            from: from.valueOf(),
            to: to.valueOf(),
          }),
    [from, to]
  );
  return (
    <StyledRangedDiv className={`${className} range-slider-container`}>
      <input ref={input} />
    </StyledRangedDiv>
  );
};

export default RangeSliderInput;
