import React, { useState } from "react";
import styled from "styled-components";
import ColumnsGrid from "common/layouts/ColumnsGrid";
import SelectInputWithIcon from "common/SelectInputWithIcon";

const _defaultSettings = {
  timePeriod: "oneDay",
  metric: "averageSpeed",
  entity: "ip-address"
};

const doesNothing = () => {};

const timePeriodOptions = [
  { label: "1 Day", value: "oneDay" },
  { label: "1 Week", value: "oneWeek" },
  { label: "1 Month", value: "oneMonth" },
  { label: "3 Months", value: "threeMonths" },
];

const metricOptions = [
  { label: "Average Speed", value: "averageSpeed" },
  { label: "Maximum speed", value: "maxSpeed" },
  { label: "Latency", value: "latency" },
  { label: "Retransmissions", value: "retransmissions" },
  { label: "Active Flows", value: "activeFlows" },
  { label: "Flows Created Per Minute", value: "flowsCreated" },
  { label: "% Traffic at Max Speed", value: "traffic" },
  { label: "% Traffic in Congestion", value: "congestion" },
];
const entityOptions = [
  { label: "IP Address", value: "ip-address" },
  { label: "Subscriber ID", value: "subscriber-id" },
];

const ReloadButton = styled.div`
  grid-column: -2 / span 1; 
  text-align: right;
`;

const IconRefresh = styled.i`
  color: #999;
  &:hover {
    color: #000;
  }
  transition: all 0.5s;
`;

const ControlsAndResultsLayout = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 20px;
`;

const FilteringControls = ({ defaults = {}, children, onEntityChanged = doesNothing }) => {
  defaults = {
    ..._defaultSettings,
    ...defaults,
  };
  const [settings, setSettings] = useState(defaults);
  const onChange = (event) => {
    const { target } = event;
    const value =
      target.type !== "checkbox"
        ? target.value
        : target.value === "on" && target.checked === true;
    setSettings((settings) => ({
      ...settings,
      [target.name]: value,
    }));
  };
  return (
    <ControlsAndResultsLayout>
      <ColumnsGrid columns={6} minWidth="15rem" rowGap="0">
        <SelectInputWithIcon
          title="Time Period"
          name="timePeriod"
          icon="date_range"
          selected={settings.timePeriod}
          onChange={onChange}
          options={timePeriodOptions}
        />
        <SelectInputWithIcon
          title="metric"
          name="metric"
          icon="insert_chart"
          selected={settings.metric}
          onChange={onChange}
          options={metricOptions}
        />
        <SelectInputWithIcon
          title="entity"
          name="entity"
          icon="person"
          selected={settings.entity}
          onChange={(e) => {
            onChange(e);
            onEntityChanged()
          }}
          options={entityOptions}
        />
        <ReloadButton className="context-menu header-dropdown m-r--5 button-reload-subs-groups-table">
          <a
            onClick={() => {
              setSettings((settings) => ({
                ...settings,
                refreshSignal: !settings.refreshSignal,
              }));
            }}
            data-toggle="cardloading"
            data-loading-effect="pulse"
            title="Refresh"
          >
            <IconRefresh className="material-icons">refresh</IconRefresh>
          </a>
        </ReloadButton>
      </ColumnsGrid>
      <div className="row margin-0">
        {children(settings)}
      </div>
      </ControlsAndResultsLayout>
  );
};
export default FilteringControls;
