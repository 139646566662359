export interface Callbacks {
  [actionName: string]: Function[];
}

class PubSub {
  callbacks: Callbacks;

  constructor() {
    this.callbacks = {} as Callbacks;
  }

  recv(action: string, handler: Function, replace?: boolean) {
    const handlers = this.callbacks[action] || [];

    if(replace){
      this.callbacks[action] = [handler];
    }else{
      this.callbacks[action] = [...handlers, handler];
    }
    const actions = this;
    return () => {
      actions.unsubscribe(action, handler);
    };
  }
  unsubscribe(action: string, handler: Function) {
    console.log('unsubscribe', action, handler);
    const handlers = this.callbacks[action] || [];
    const omitHandler = (handler: Function, handlers: Function[]) =>
      handlers === undefined
        ? undefined
        : handlers.filter((current) => current !== handler);
    this.callbacks = {
      ...this.callbacks,
      [action]: omitHandler(handler, this.callbacks[action]),
    } as Callbacks;
  }
  send(action: string, ...params: any[]) {
    const handlers = this.callbacks[action] || [];
    handlers.forEach((handler) => handler(...params));
  }
  clear(){
    console.warn('actions clear');
    for (let action in this.callbacks) {
        this.callbacks.hasOwnProperty(action) && delete this.callbacks[action]
    }
  }
}

export default PubSub;
