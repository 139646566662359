function mapSubscriberGroupsResponse(response){
    return response.trim("\n").split("\n").slice(1).map(line => line.split(/\s+/)[0])
}

export async function getSubscriberGroups(){
    return ifCl
        .run("show api subscriber-group")
        .then((response) => { 
            return mapSubscriberGroupsResponse(response)
        })
}