import React, { useState, useEffect, useContext } from "react";
import ActionsContext from "common/ActionsContext";

const TargetContext = ({ subscriberGroup, children }) => {
  const actions = useContext(ActionsContext);
  const [target, setTarget] = useState({ subscriberGroup });
  const updateTarget = (newTarget) => {
    if (newTarget === null) {
      return;
    }
    setTarget({ subscriberGroup: newTarget.subscriberGroup });
  };
  const reAssign = () => {
    const currentTarget = { ...target };
    setTarget(null);
    setTimeout(() => setTarget(currentTarget), 0);
  };
  useEffect(
    () =>{
      if(subscriberGroup){
        updateTarget({ subscriberGroup })
      }
    }, []
  );
  useEffect(
    () =>
      actions.recv("do-load", (newTarget) =>
        newTarget === undefined ? reAssign() : updateTarget(newTarget)
      ),
    [subscriberGroup]
  );
  return (
    <>
      {target === null
        ? null
        : children.map((child, idx) =>
            React.isValidElement(child)
              ? React.cloneElement(child, {
                  key: child.props.name,
                  target: target,
                })
              : child
          )}
    </>
  );
};

export default TargetContext;
