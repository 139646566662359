import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Request from "common/Request";
import {ConnectedLineChart} from "./ConnectedLineChart";

const isInsideRange =
  ({ from, to }, field = "time") =>
  (item) =>
    from <= item[field] && item[field] <= to;

const TileContainer = styled.div`
  min-height: 10cm;
  @media print {
    & {
      height: 8cm;
      &.can-compress {
        height: auto;
      }
      width: 100%;
      display: block:
    }
  }
  display: flex;
  flex-direction: column;
  & > h4 {
    text-align: center;
    margin: 20px 0 0 0;
  }
  & > .graph {
    flex: 1 1 100%;
    .nv-y.nv-axis .nv-axisMaxMin {
      display: none;
    }
  }
  &.spread {
    justify-content: space-between;
    padding: 1em;
    align-content: flex-start;
  }
  &.can-compress {
    min-height: 0;
  }
  .input-group {
    max-width: 20ch;
  }
`;

export const FacetGraph = ({
    command,
    facet,
    target,
    title,
    range,
    units = "",
    fields,
    parse,
    yAxisFormat=null,
    shouldReloadOnCommandChange=true,
    ...rest
  }) => {
    const wrapper = useRef(null);
    const [request, setRequest] = useState(null);
    const firstTime = useRef(true);

    useEffect(() => {
      if ((!shouldReloadOnCommandChange && !firstTime.current) || target === null || wrapper.current === null) {
        return;
      }
      setRequest(ifCl.run(command).then(parse).then((items) => ({ items })));
      firstTime.current = false;
      
    }, [command]);

    return (
      <TileContainer className="chart-container">
        <h4 className="chart-title">{title} </h4>
        <div className="graph" ref={wrapper}>
          {request === null ? null : (
            <Request during={request}>
              {({ items }) => (
                <ConnectedLineChart
                  facet={facet}
                  items={items.filter(isInsideRange(range))}
                  xField="time"
                  yAxisUnits={units}
                  xAxisFormat={d3.timeFormat("%m/%d %H:%M")}
                  yAxisTopGap={.1}
                  yAxisFormat={yAxisFormat}
                  fields={fields}
                  {...rest}
                />
              )}
            </Request>
          )}
        </div>
      </TileContainer>
    );
  };
