import React, { useState, useContext } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const InterfacesHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  actions.recv("open-help", function () {
    setOpen(true);
  });
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="Network Interface Ports Throughput Over Time"
      onClose={doClose}
      content={() => (
        <>
         <p>Sent and received traffic throughput over time for network interface ports.</p>
        <ul>
          <li><b>Access Ports</b>: Access interface ports.</li>
          <li><b>Internet Ports</b>: Internet interface ports.</li>
        </ul>
        <p>Chart selectors:</p>
        <ul>
          <li><i class="material-icons valign-icon">date_range</i> Selects the range of time values shown starting from now: 1 day, 1 week or 1 month. The slider at the bottom of the graph allows refining the range of date values shown: a shorter interval by dragging any of the dated labels and, once done, by dragging the central part.</li>
          <li><i class="material-icons valign-icon">device_hub</i> Interface port name. "Internet" will show the combined throughput of all interface ports on Internet side and "Access" for all interface ports on the access side.</li>
          <li><i class="material-icons valign-icon">import_export</i> Selects the direction of traffic (Tx or Rx).</li>
        </ul>
        <p>Click on the coloured dots on the top-right corner of the graph to optionally select or de-select which throughput is shown (Tx or Rx).</p>
        <p>Double-click on the coloured dots on the top-right corner of the graph to optionally isolate the selected throughput (Tx or Rx).</p>
        <p>Click on the <i class="material-icons valign-icon">refresh</i> icon to refresh throughput information.</p>
        </>
      )}
      onClose={doClose}
    />
  );
};
export default InterfacesHelpModal;