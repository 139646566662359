import React, { useState, useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import Canvas from "common/graphs/Canvas";
import Axis, { remaining } from "common/graphs/Axis";
import * as d3 from "d3";
import { givenRange, makeCurlyBrace } from "./common";
import BoxPlot from "common/graphs/BoxPlot";
import { BoxPlotWrapper } from "../../Report/Statistics/BoxPlotGroup";
import ThresholdsDescription from "./ThresholdsDescription";
import QuartilesDescription from "./QuartilesDescription";
import { DEFAULT_MARGIN } from "common/graphs/BoxPlot";

const addGap = (coefficient, values) => {
  const [min, max] = d3.extent(values);
  const gap = ((max - min) * coefficient) / 2;
  return [min - gap, max + gap];
};

const PlotAndExplanation = styled.div`
  margin: 1.5em 0;
  display: flex;

  flex-direction: row;
  & > div {
    flex: 25% 1 1;
    &.demo.group-boxplot {
      flex: 2.5cm 0 0;
    }
  }
  text.align-left,
  text.aling-left tspan {
    text-anchor: end;
  }
`;

const thresholdData = (data, inverted) => ({
  ...data,
  inverted,
  threshold: givenRange([data.min, data.max]).point(inverted ? 0.66 : 0.33),
});

const axisFor = (data, yGap = 0.1) => [
  {
    name: "x",
    type: "band",
    bandWidth: "30px",
    along: "width",
    value: (d) => d,
    values: [1],
  },
  {
    name: "y",
    along: "height",
    values: addGap(yGap, [data.min, data.max, data.threshold]),
  },
];

const BoxPlotDemo = ({
  data = {
    min: 10,
    max: 1000,
    median: 400,
    q3: 600,
    q1: 200,
  },
  margin = DEFAULT_MARGIN,
  inverted = true,
  colorizeQuartiles = false,
  units = undefined,
  format = undefined,
  yGap = 0.1 /*10%*/,
}) => (
  <PlotAndExplanation>
    <BoxPlotWrapper className="thresholds-description group-boxplot">
      <Canvas>
        <Axis
          are={axisFor(data, yGap)}
          margin={{ ...margin, left: 5, right: 1 }}
          data={thresholdData(data, inverted)}
        >
          <ThresholdsDescription {...{ units, format, inverted }} />
        </Axis>
      </Canvas>
    </BoxPlotWrapper>
    <BoxPlotWrapper className="demo group-boxplot invert-colors colorize-warnings">
      <Canvas>
        <Axis
          are={axisFor(data, yGap)}
          margin={{ ...margin, right: 0, left: 0 }}
          data={thresholdData(data, inverted)}
        >
          <BoxPlot
            units={units}
            showAxis={false}
            format={format}
            frameless={true}
            widthRatio={0.9}
            zoomEnabled={false}
          />
        </Axis>
      </Canvas>
    </BoxPlotWrapper>
    <BoxPlotWrapper className="demo group-boxplot invert-colors colorize-quartiles">
      <Canvas>
        <Axis
          are={axisFor(data, yGap)}
          margin={{ ...margin, right: 0, left: 0 }}
          data={data}
        >
          <BoxPlot
            units={units}
            format={format}
            frameless={true}
            showAxis={false}
            widthRatio={0.9}
            zoomEnabled={false}
          />
        </Axis>
      </Canvas>
    </BoxPlotWrapper>
    <BoxPlotWrapper className="description group-boxplot invert-colors colorize-quartiles">
      <Canvas>
        <Axis
          are={axisFor(data, yGap)}
          margin={{ ...margin, left: 5 }}
          data={data}
        >
          <QuartilesDescription {...{ units, format, inverted }} />
        </Axis>
      </Canvas>
    </BoxPlotWrapper>
  </PlotAndExplanation>
);

export default BoxPlotDemo;
