import * as d3 from "d3";

export const parseSystemDate = (input) => new Date(input);

export const parseFloatOrNull = (value) =>
  value === "n/a"
    ? null
    : value.endsWith("%") === true
    ? parseFloat((parseFloat(value.trim("%")) / 100).toFixed(4))
    : parseFloat(value);

const dateTimeParser = d3.timeParse("%Y-%m-%dT%H:%M:%S");

const parseKeys = (head, translations = {}) =>
  head.split(/\s+/).map((key) => translations[key] || key);

const timeAndNumbers = (row, keys, skipped = []) =>
  row
    .split(/\s+/)
    .map((value, index) =>
      skipped.length > 0 && skipped.includes(keys[index])
        ? null
        : [
            keys[index],
            index > 0 ? parseFloatOrNull(value) : dateTimeParser(value),
          ]
    )
    .filter((item) => item !== null);

export const fixedPrecision = (value, decimals = 4) =>
  parseFloat(value.toFixed(decimals));

const appendToservices = (services, key, value, valueName = "speed") =>
  services === undefined || services.length === 0
    ? [{ name: key, speed: value }]
    : [...services, { name: key, [valueName]: value }];

const toServicesList =
  (skipped, valueName = "speed") =>
  (reading, [key, value]) =>
    skipped.includes(key) === true
      ? reading
      : key === "time" || key === "total" || key === "dpi"
      ? { ...reading, [key]: value }
      : {
          ...reading,
          services: appendToservices(reading.services, key, value, valueName),
        };

export const parseShareInSpeed = (input) => {
  const lines = input.length === 0 ? [] : input.trim("\n").split("\n");
  if (lines.length === 0) {
    return [];
  }
  const [head, _units, ...rows] = lines;
  const translations = {
    REST: "REST",
    TIME: "time",
    TOTAL: "total",
    DPI: "dpi",
  };
  const keys = parseKeys(head, translations);
  const skipped = [];
  return rows.map((row) =>
    timeAndNumbers(row, keys, skipped, translations).reduce(
      toServicesList(skipped),
      {}
    )
  );
};

const sumToServices = (services, key, value) => ({
  ...services,
  [key]: (services[key] || 0) + value,
});

const sumUpTotalsAndServices = (totals, tuples) =>
  tuples.reduce(
    (totals, [key, value]) =>
      key === "dpi" || key === "total"
        ? { ...totals, [key]: totals[key] + value }
        : { ...totals, services: sumToServices(totals.services, key, value) },
    totals
  );

const totalsAsGb = ({ total, dpi, ...rest }) => ({
  total: total && fixedPrecision(total / 1000, 4),
  dpi: dpi && fixedPrecision(dpi / 1000, 4),
  ...rest,
});

const servicesVolumeAsNameValueInGBs = ({ services, ...totals }) => ({
  ...totalsAsGb(totals),
  services: Object.entries(services).map(([name, value]) => ({
    name,
    volume: fixedPrecision(value / 1000, 4),
  })),
});

export const parseVolumesAddTotals = (input) => {
  const lines = input.length === 0 ? [] : input.trim("\n").split("\n");
  if (lines.length === 0) {
    return [];
  }
  const [head, _units, ...rows] = lines;
  const translations = {
    REST: "REST",
    TIME: "time",
    TOTAL: "total",
    DPI: "dpi",
  };
  const keys = parseKeys(head, translations);
  const skipped = ["time"];
  return servicesVolumeAsNameValueInGBs(
    rows.reduce(
      (totals, row) =>
        sumUpTotalsAndServices(
          totals,
          timeAndNumbers(row, keys, skipped, translations)
        ),
      { total: 0, dpi: 0, services: {} }
    )
  );
};
