import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import BoxPlotGroup from "./BoxPlotGroup";
import { NoEnoughtData } from "./common";
import StatisticsHelpModal from "./Help";
import Request from "common/Request";
import ColumnsGrid from "common/layouts/ColumnsGrid";

const doesNothing = () => {};

const GroupsBoxPlots = ({ groups = [], colorizeQuartiles = false }) => {
  const gap = '2em';

  return <ColumnsGrid columns={groups.length} minWidth="5cm" gap={gap} className="box-plot-container" rowGap="0">
    {groups.map((group) => (
      <BoxPlotGroup
        key={group.field}
        {...group}
        colorizeQuartiles={colorizeQuartiles}
      />
    ))}
  </ColumnsGrid>
};

const StatisticsContainer = styled.div`
  width: 100%;
  margin: 1rem 0 30px 0;
`;

const TotalSubscribers = styled.h4`
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    text-transform: lowercase;
    margin-top: 20px;
`

function debug(response) {
  console.log(arguments);
  debugger;
  return response;
}

const Statistics = ({ request, ...settings }) => (
  <StatisticsContainer>
    <Request
      keepContentWhileLoading={true}
      during={request
        .then(({ statistics }) => statistics)
        .catch((error) => console.error(error) || { error })}
    >
      {({ error, total, groups }) =>
        (error === undefined && (
          <>
            <TotalSubscribers className="total-subscribers-label">{total} active subscribers</TotalSubscribers>
            {total < 2 ? (
              <NoEnoughtData />
            ) : (
              <GroupsBoxPlots groups={groups} total={total} {...settings} />
            )}
          </>
        )) ||
        null
      }
    </Request>
    <StatisticsHelpModal />
  </StatisticsContainer>
);

export default Statistics;
