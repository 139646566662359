import React from "react";
import styled from "styled-components";

const ColumnsGrid = styled.div`
  --grid-layout-gap: ${(props) => props.gap || "3rem"};
  --grid-column-count: ${(props) => props.columns || 2};
  --grid-item--min-width: ${(props) => props.minWidth || "10cm"};

  /**
   * Calculated values.
   */
  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc(
    (100% - var(--total-gap-width)) / var(--grid-column-count)
  );
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
  );
  grid-column-gap: var(--grid-layout-gap);
  grid-row-gap: ${(props) => props.rowGap || " var(--grid-layout-gap)"};
  & .full-width {
    grid-column: 1 / -1;
  }
  & > * {
    min-height: ${(props) => props.minHeight || "inherit"};
  }

  @media print {
    display: block;
    & > * {
      width:100%;
      break-inside: avoid;
      &.wont-print {
        display: none;
      }
    }
  }
`;
export default ColumnsGrid;
