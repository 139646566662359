import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import SubscriberGroupDashboard from "./SubscriberGroupDashboard";
import TargetContext from "./TargetContext";
import ActionsContext from "common/ActionsContext";
import SubscriberDetailHelpModal from "./Help";

const ViewContainer = styled.div`
  min-height: 50vh;
  
  .card {
    height: 100%;
  }

  .card .body {
    padding-top: 10px !important;
  }
`;

const ContextMenu = ({ goBack = null }) => {
  const actions = useContext(ActionsContext);
  return (
    <div className="context-menu">
      {goBack === null ? null : (
        <a
          onClick={goBack}
          data-toggle="cardloading"
          data-loading-effect="pulse"
          title="Back to list"
        >
          <i className="material-icons">
            <small>exit_to_app</small>
          </i>
        </a>
      )}
      <a
        data-loading-effect="pulse"
        data-toggle="cardloading"
        title="Help"
        onClick={() => actions.send("open-help")}
      >
        <i className="material-icons">
          <small>help_outline</small>
        </i>
      </a>
      <a
        onClick={() => actions.send("do-load")}
        data-toggle="cardloading"
        data-loading-effect="pulse"
        title="Refresh"
      >
        <i className="material-icons">
          <small>refresh</small>
        </i>
      </a>
    </div>
  );
};

const HeaderDiv = styled.div`
  display: flex;
  & .title {
    flex: 100% 1 1;
    padding-right: 2.5ch;
    color: #666;
  }
  & .context-menu {
    display: flex;
    flex-direction: row;
    a {
      color: #949494;
      font-size: 0.8em;
    }
  }
`;

export const applyParams = (child, params = {}) =>
  React.isValidElement(child) ? React.cloneElement(child, params) : child;

const Panel = ({ children, ...props }) => (
  <div className="card">
    <div className="body">{children({ ...props })}</div>
  </div>
);

const container = ({
  subscriberGroup,
  returnView,
  direction,
  timePeriod,
  metric,
  ipAddress
}) => { 
  const settingsSubscriberGroups = {
    direction,
    timePeriod,
    metric,
    ipAddress 
  }

  useEffect(() => {
    $('html')[0].scrollTop = 0
  }, [])

  return (
  <>
    <HeaderDiv className="block-header">
      <h2 className="title">
        Subscriber Group Dashboard:&nbsp;
        <span className="case-sensitive"> {subscriberGroup} </span>
      </h2>
      <p className="padding-t-20"></p>
      <ContextMenu
        goBack={
          returnView
            ? () => globalNavigate(returnView, settingsSubscriberGroups)
            : null
        }
      />
    </HeaderDiv>

    <ViewContainer className="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-0">
      <TargetContext subscriberGroup={subscriberGroup}>
        <div />
        <Panel name="analytics">
          {(props) => (
            <>
              <SubscriberGroupDashboard
                {...props}
                subscriberGroup={subscriberGroup}
                settingsSubscriberGroups={settingsSubscriberGroups}
              />
              <div className="control modal-footer">
                <button
                  type="button"
                  className="btn btn-link waves-effect"
                  onClick={() =>
                    globalNavigate(returnView ?? "viewStatusSubscriberGroups", 
                      settingsSubscriberGroups,
                    )
                  }
                >
                  Return
                </button>
              </div>
            </>
          )}
        </Panel>
      </TargetContext>
      <SubscriberDetailHelpModal />
    </ViewContainer>
  </>
)};

export default container;
