import * as d3 from "d3";
import { createPaginatorWithContent } from "common/DataTable";

const calcQuartiles = (sorted, threshold = undefined) => {
  const q1 = d3.quantile(sorted, 0.25);
  const median = d3.quantile(sorted, 0.5);
  const q3 = d3.quantile(sorted, 0.75);
  const min = sorted.at(0);
  const max = sorted.at(-1);
  const p10 = d3.quantile(sorted, 0.1);
  const p90 = d3.quantile(sorted, 0.9);
  return { q1, median, q3, min, max, p10, p90 };
};

const debugging =
  (procedure) =>
  (...params) => {
    const result = procedure(...params);
    console.log("procedure", procedure, "called with", params, "got", result);
    return result;
  };

const notNull = (value) => value !== null;

const hasEnoughData = (values) =>
  values !== undefined && new Set(values.filter(notNull)).size > 0;

const givenQuartiles = ({ q3, median, q1 }) => ({
  directClasiffication: (value) =>
    value < q1
      ? "min-q1"
      : value < median
      ? "q1-median"
      : value < q3
      ? "median-q3"
      : "q3-max",
  invertedClasiffication: (value) =>
    value > q3
      ? "q3-max"
      : value > median
      ? "median-q3"
      : value > q1
      ? "q1-median"
      : "min-q1",
});

const givenThreshold = (
  threshold,
  meaningful = 0,
  inverted = false,
  sortedValues = []
) => {
  if (threshold === undefined) {
    return { value: null, insideThreshold: () => false, inWarning: () => "" };
  }
  const insideThreshold = (value) =>
    threshold === 0
      ? false
      : inverted !== true
      ? value < threshold
      : value > threshold;
  const inWarning =
    meaningful === 0
      ? (value) => (insideThreshold(value) && "warning") || ""
      : (value, mean = null) =>
          mean === null || mean < meaningful
            ? "" // Not relevant
            : (insideThreshold(value) && "warning") || "";
  return { value: threshold, insideThreshold, inWarning };
};

const classification = (
  values,
  inverted = false,
  threshold = undefined,
  meaningful = undefined
) => {
  if (
    values === undefined ||
    values === null ||
    hasEnoughData(values) !== true
  ) {
    return {
      classify: () => "no-categories",
      threshold: { value: threshold, inWarning: () => "no-enough-data" },
      quartiles: null,
    };
  }
  const sortedValues = values.filter(notNull).sort(d3.ascending);
  const quartiles = calcQuartiles(sortedValues);
  const { q1, median, q3, min, max } = quartiles;

  const quantified = givenQuartiles(quartiles);
  const classify = (value) =>
    value === undefined || value === null || isNaN(value)
      ? "invalid"
      : inverted !== true
      ? quantified.directClasiffication(value)
      : quantified.invertedClasiffication(value);
  const { inWarning, ...restOfThreshold } = givenThreshold(
    threshold,
    meaningful,
    inverted,
    sortedValues
  );

  return {
    quartiles,
    threshold: { inWarning, ...restOfThreshold },
    inverted,
    classify:
      inverted !== true
        ? (value, ...rest) =>
            value === null
              ? ""
              : `${classify(value, ...rest)} ${inWarning(value, ...rest)}`.trim(
                  " "
                )
        : (value, ...rest) =>
            value === null
              ? ""
              : `inverted ${classify(value, ...rest)} ${inWarning(
                  value,
                  ...rest
                )}`.trim(" "),
  };
};

const parseAndQuantify = (response, groups, columns) => {
  const paginator = createPaginatorWithContent(response, columns);
  const total = paginator.getTotal();
  const extendWithQuantileAndClassication = ({
    field,
    inverted,
    threshold,
    meaningful,
    ...rest
  }) => ({
    field,
    inverted,
    ...classification(
      paginator.getColumnValues(field),
      inverted,
      threshold,
      meaningful
    ),
    ...rest,
  });
  const statistics = {
    total,
    groups: groups.map((group) => extendWithQuantileAndClassication(group)),
  };
  return { statistics, paginator, response };
};

export const extendWithStatistics =
  (groups = [], columns = []) =>
  ({ response }) =>
    parseAndQuantify(response, groups, columns);
