import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import colors from "common/graphs/colors";

const canvasBackgroundColor = {
  id: 'canvasBackgroundColor',
  beforeDraw: (chart, args, options) => {
    const {ctx, chartArea: {bottom, left, right, top}, width, height } = chart;
    ctx.save();
    ctx.fillStyle =  options.color || 'white';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.fill();
    ctx.restore();
  }
};

const WrapperDIV = styled.div`
  &,
  & > canvas {
    width: 100%;
    height: 100%;
  }
`;

const ChartJSBars = ({ datasets, labels, xAxesAsTime = false }) => {
  const canvas = useRef(null);
  const wrapper = useRef(null);
  let barChart = null;
  let resizeObserver = null;
  useEffect(() => {
    const target = wrapper.current;
    if (canvas.current === null) {
      return;
    }
    if (barChart === null) {
      const data = {
        labels: labels,
        datasets: [],
      };
      const stacked = datasets.length > 1;
      const legend = stacked
        ? {
            textAlign: "right",
            labels: {
              usePointStyle: true,
              boxWidth: 4,
              padding: 5,
            },
          }
        : { display: false };
      const config = {
        type: "bar",
        data,
        plugins: [canvasBackgroundColor],
        options: {
          plugins: {
            title: {
              display: true,
            },
            canvasBackgroundColor: {
              color: login.isTheme("light") ? "#fff" : '#1c1c1e',
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          animation: {
            duration: 2000,
            onProgress: function (animation) {
              //$('#dpiStatsLatencyCategoryLoader').hide();
            },
          },
          scales: {
            xAxes: [
              {
                stacked,
                ...(xAxesAsTime
                  ? {
                      type: "time",
                      time: {
                        tooltipFormat: "DD/MM/YYYY HH:mm",
                        unit: "hour",
                        unitStepSize: 1,
                        displayFormats: {
                          hour: "DD/MM HH:mm",
                        },
                      },
                    }
                  : {}),
              },
            ],
            yAxes: [
              {
                stacked,
                ticks: {
                  beginAtZero: true,
                  max: 100,
                  callback: function (value, index, values) {
                    return value + " %";
                  },
                },
              },
            ],
          },

          interaction: {
            axis: "y",
          },
          legend,
        },
      };
      barChart = new Chart(canvas.current.getContext("2d"), config);
    }
    if (resizeObserver === null && target !== null) {
      resizeObserver = new ResizeObserver(() => barChart.resize());
      resizeObserver.observe(wrapper.current);
    }
    barChart.data.datasets = datasets.map((dataset, index) => {
      return {
        ...dataset,
        backgroundColor: colors[index % colors.length],
        borderColor: colors[index % colors.length],
        borderWidth: 1,
      };
    });
     
    barChart.update();
    return () => {
      barChart !== null && barChart.destroy();
      resizeObserver !== null && resizeObserver.unobserve(target);
    };
  }, [canvas, datasets]);
  return (
    <WrapperDIV ref={wrapper} style={{height: '450px'}}>
      <canvas ref={canvas} />
    </WrapperDIV>
  );
};

export default ChartJSBars;
