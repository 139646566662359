import {useRef} from 'react';

const ADebounce = (delay) => {
  let timeout = null;
  const debounce = (callback) => {
    console.warn('debound requested', timeout);
    timeout !== null && clearTimeout(timeout);
    timeout = setTimeout(() => { timeout = null; callback()}, delay);
  }
  const stop = () => {
    console.warn('debound cancels', timeout);
    timeout !== null && clearTimeout(timeout);
    timeout = null;
  }
  debounce.stop = stop;
  return debounce;
}

const useDebouncer = (delay) => {
  const debouncer = useRef(ADebounce(delay));
  return debouncer.current;
}
export const willCancel = (debounce, delay=1100) =>
  setTimeout( () =>
    debounce.stop(),
    delay
  );

export default useDebouncer;
