import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";

export const InlineInputDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: stretch;
  align-items: center;

  & .input-group {
    margin: 0;
  }
  & .input-group,
  & .input-group-add {
    flex-shrink: 1;
    width: auto;
  }
  & .break {
    margin-right: auto;
    flex: 1 0 100%;
  }
  & .error {
    font-size: 0.9em;
    color: red;
  }
  & .input-section {
    display: flex;
    flex-flow: row wrap;
    align-items: start;
  }
  & > span {
    white-space: nowrap;
    &.as-label {
      align-self: center;
    }
  }
  @media screen and (max-width: 700px) {
    & {
      grid-column: 1 / -1;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;
