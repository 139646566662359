import React from "react";
import styled from "styled-components";
import LatencyChronology from "./Chronology";
import LatencyDistribution from "./Distribution";

const GraphTitle = styled.h4`
  flex: 1 0 2em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  & > a {
  }
  .back,
  .next {
    color: #747474;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ContentDiv = styled.div`
  flex: 1 1 100%;
  display: flex;
  min-height: 12cms;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  & > a {
    padding: 0.25em;
  }
`;

const ServiceDetail = ({ back, next, graph, service, ...context }) => (
  <>
    <GraphTitle>
      <a
        onClick={back}
        className="waves-effect borderless back"
        title="Back to Latency distribution"
      >
        <i className="material-icons actions-icon-color">keyboard_arrow_left</i>
        {graph === "chronology" ? (
          <span>Distribution of Latencies</span>
        ) : (
          <span>Latencies for Main Services</span>
        )}
      </a>
      {graph === "distribution" ? (
        <a
          onClick={next}
          className="waves-effect borderless next"
          title="Back to Latency distribution"
        >
          <span>Distribution by time</span>

          <i className="material-icons actions-icon-color">
            keyboard_arrow_right
          </i>
        </a>
      ) : (
        <a></a>
      )}
    </GraphTitle>
    <ContentDiv className="subtitle-container">
      {graph === "distribution" ? (
        <>
          <h4 className="subtitle">Distribution of latencies for {service}</h4>
          <LatencyDistribution service={service} {...context} />
        </>
      ) : graph === "chronology" ? (
        <>
          <h4  className="subtitle">Time evolution of latencies for {service}</h4>
          <LatencyChronology service={service} {...context} />
        </>
      ) : null}
    </ContentDiv>
  </>
);

export default ServiceDetail;
