import React, { useState, useContext } from "react";
import styled from "styled-components";
import {
  buildThresholdsUpdate,
  clearConfigAttempt,
  checkNothingPending,
} from "../api";
import TextInput from "common/TextInput";
import Modal from "common/Modal";
import ActionsContext from "common/ActionsContext";

const explain = (field, inverted) =>
  field === "MAX-Mbps"
    ? "Warning when below this % of policy rate limit, but only if the subscriber has a rate limit"
    : inverted === true
    ? `Warning when above this value.`
    : `Warning when below this value.`;

const InputWithExplanation = styled.div`
  .input-group {
    margin-bottom: 5px;
    .form-line {
      width: auto;
      input {
        max-width: 10ch;
        text-align: right;
      }
    }
  }
  .input-group + .explanation {
    margin-bottom: 20px;
  }
`;

const ThresholdsSettingsModal = ({ groups }) => {
  const [open, setOpen] = useState(false);
  const [target, setTarget] = useState(undefined);
  const [error, setError] = useState(null);
  const actions = useContext(ActionsContext);
  actions.recv("open-thresholds-settings", (param = {}) => {
    setTarget(param.target);
    setOpen(true);
  });
  const doClose = () => {
    setTarget(undefined);
    setOpen(false);
  };
  const notifyAndRevert = (error) => {
    setError(String(error));
    clearConfigAttempt();
  };
  const doApply = (settings) => {
    const previous = Object.fromEntries(
      groups.map(({ field, threshold }) => [field, threshold])
    );
    checkNothingPending()
      .then(() => ifCl.run(buildThresholdsUpdate(settings, previous), true))
      .then(() => {
        actions.send("do-load");
        doClose();
      })
      .catch(notifyAndRevert);
  };
  const updateThreshold = (field) => (event) => {
    setError(null);
  };
  if (open !== true) {
    return null;
  }
  const givenTarget = (target) =>
    target === undefined ? () => true : ({ field }) => field === target;
  return (
    <Modal
      title="THRESHOLDS SETTINGS"
      onClose={doClose}
      content={() => (
        <div className="modal-body">
          {groups
            .filter(givenTarget(target))
            .map(
              ({
                field,
                label,
                threshold,
                inverted = false,
                percentage = false,
                units = "",
              }) => (
                <InputWithExplanation className="row" key={field}>
                  <TextInput
                    type="number"
                    label={`${label}:`}
                    name={field}
                    defaultValue={threshold === undefined ? 0 : threshold}
                    onChange={updateThreshold(field)}
                    suffix={percentage ? "%" : units}
                  />
                  <div className="explanation"> {explain(field, inverted)}</div>
                </InputWithExplanation>
              )
            )}
          {(error !== null && (
            <div className="modal-err-msg color-red">{error}</div>
          )) ||
            null}
        </div>
      )}
      onApply={doApply}
      applyLabel={"Update"}
      applyDisabled={error !== null}
      onClose={doClose}
      closeLabel={"Discard Changes"}
    />
  );
};
export default ThresholdsSettingsModal;
