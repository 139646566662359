import React, { useRef, useState, useEffect } from "react";
import { applyParams } from "./Canvas.jsx";
import * as d3 from "d3";

const givesNull = () => null;

export const remaining = ({ width, height }, { top, left, right, bottom }) => ({
  width: width - left - right,
  height: height - top - bottom,
});

const calcAxis = (
  { along = "width", type = "linear", value = givesNull, values = undefined },
  { dimensions, data }
) => {
  const isVertical = along === "height";
  const { width, height } = dimensions;
  const input =
    type === "band"
      ? d3
          .scaleBand()
          .domain(values || data.map(value))
          .padding(0, 4)
      : d3.scaleLinear().domain(d3.extent(values || data.map(value))); // input
  const scale = input.range(isVertical ? [height, 0] : [0, width]); // output
  const scaledValue = (d) => scale(value(d));
  return { scale, value, scaledValue };
};

const _DEFAULT_MARGIN = { top: 0, right: 0, bottom: 0, left: 0 };

const Axis = ({
  children = [],
  are = [],
  data = [],
  dimensions,
  margin = _DEFAULT_MARGIN,
  ...others
}) => {
  const [axis, setAxis] = useState(null);

  useEffect(() => {
    if (dimensions === null) {
      return;
    }
    const { width, height } = remaining(dimensions, margin);
    const context = {
      data,
      dimensions: { width, height },
      margin,
    };
    const composedAxis = Object.fromEntries(
      are.map(({ name, ...axis }) => [name, calcAxis(axis, context)])
    );
    setAxis(composedAxis);
  }, [data, are, dimensions]);
  if (axis === null) {
    return null;
  }
  return (
    <>
      {React.Children.map(children, (child) =>
        applyParams(child, { data, axis, dimensions, margin, ...others })
      )}
    </>
  );
};

export default Axis;
