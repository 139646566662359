import React from "react";

interface FormValues {
  [name: string]: any | undefined;
}

const incorporateList = (value: any, previous: any[] | undefined): any[] =>
  previous === undefined ? [value] : [...previous, value];

const composeFields = (
  result: FormValues,
  [name, value]: [string, any]
): FormValues =>
  name.endsWith("[]") === true
    ? {
        ...result,
        [name]: incorporateList(value, result[name] as any | undefined),
      }
    : { ...result, [name]: value };

const retrieveData = (
  event: React.FormEvent<HTMLFormElement>
): Promise<Object> => {
  return new Promise<any>((resolve, reject) => {
    const target =
      event.currentTarget instanceof HTMLFormElement
        ? event.currentTarget
        : null;
    if (target === null) {
      return reject("No valid target");
    }
    event.preventDefault();
    const data = new FormData(target);
    const settings = Array.from(data.entries()).reduce(composeFields, {});
    resolve(settings);
  });
};
interface DataConsumer {
  (value: Object): void;
}

type Props = {
  onChange: React.FormEventHandler;
  onSubmit: DataConsumer;
  children: JSX.Element | JSX.Element[] | string | string[];
};

const Form: React.FC<Props> = ({ onChange, onSubmit, children }) => {
  return (
    <form
      onSubmit={(event: React.FormEvent<HTMLFormElement>) =>
        retrieveData(event).then(onSubmit)
      }
    >
      {children}
    </form>
  );
};

export default Form;
