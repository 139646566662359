import React, {useContext} from 'react';
import ProfileContext from './ProfileContext';


const NotForOperators = ({children}) => {
  const profile = useContext(ProfileContext);
  return profile === null || profile.isOperator === true
    ? <></>
    : children;
}

export default NotForOperators;
