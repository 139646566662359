import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

const SwitchInputDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SwitchInput = ({ onChange, name, defaultChecked, label, className, disabled=false }) => {
  const [value, setValue] = useState(defaultChecked);
  const opposite = useRef(null);
  const doChange = (event) => {
    setValue(event.target.checked);
    onChange(event);
  };
  useEffect( ()=>{
    if(opposite.current){
      opposite.current.cheched = value === false; 
    } 
  }, [value])
  const appliedLabel = typeof label === "function" ? label(value) : label;
  return (
    <SwitchInputDiv className={`input-group ${className}`}>
      <span title={appliedLabel} className="font-bold">
        {appliedLabel}
      </span>
      <input
        ref={opposite}
        type="hidden"
        name={name}
        id={`${name}-switch-off`}
        defaultChecked={!defaultChecked}
        className="sw-input"
        value="off"
        disabled={disabled}
      />
      <input
        type="checkbox"
        name={name}
        id={`${name}-switch`}
        defaultChecked={defaultChecked}
        onChange={doChange}
        className="sw-input"
        disabled={disabled}
      />
      <label htmlFor={`${name}-switch`} className="sw-label min-width-0">
        <span className="toggle--on"></span>
        <span className="toggle--off"></span>
      </label>
    </SwitchInputDiv>
  );
};

export default SwitchInput;
