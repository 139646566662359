
import React from "react";
import styled from "styled-components";

const IconsContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  label{
    margin-bottom: 0px;
  }
`;

const InnerIconsContainer = styled.div`
  display: flex;
  gap: 5px;

  i {
    font-size: 20px;
    color: #999;
    cursor: pointer;
    width: 22px;
    height: 22px;

    &.selected {
      color: #000;
      border: 1px solid #000;
      border-radius: 3px;
    }
  }
`;
function IconsToggle({ label, options, value, onChange }){
  return (
    <IconsContainer>
      <label> {label}</label>
      <InnerIconsContainer>
        {options.map((option) => (
          <i
            className={
              "material-icons" + (option.value === value ? " selected" : "")
            }
            onClick={() => onChange(option.value)}
          >
            {option.icon}
          </i>
        ))}
      </InnerIconsContainer>
    </IconsContainer>
  );
}

export default IconsToggle;