import React from "react";
import {
  expressLatencyTarget,
  expressDestination,
} from "../api";
import provideUserFile from "common/ProvideFile";
import { YYYYMMDDHHmmssSSS } from "common/utils";
import styled from 'styled-components';

const LabelWithIcon = styled.a`
  span {font-weight: bold;}
  white-space: nowrap;
  cursor: pointer;
  display: inline;
  color: #333;
  .material-icons {
    line-height: 1.2em;
    vertical-align: middle;
  }
`

const createCVSFilename = (prefix='dpi-latencies') =>
  `${prefix}-${YYYYMMDDHHmmssSSS(new Date())}.csv`;

const asCSVContent = input =>
  input.split('\n').map( line => line.replaceAll(/\s+/g,',')).join('\n');

const requestCSVExport = ({hours, categories, destination, target}) =>
  expressLatencyTarget(target).then((expressedTarget) =>
    ifCl.run(`show statistics dpi latency top hours ${hours} categories ${categories} ${expressedTarget} ${expressDestination(destination)}`
  )).then(content => provideUserFile(createCVSFilename(), asCSVContent(content)))

const ExportButton = ({context, className=''}) =>
  <LabelWithIcon 
    className={`${className} export-dpi-latencies`}
    onClick={() => requestCSVExport(context)}
  >
    <span>Export:</span>
    <i className="material-icons">file_download</i>
  </LabelWithIcon>


export default ExportButton;
