import * as d3 from "d3";
import styled from "styled-components";

const StyledG = styled.g`
  rect.frame {
    stroke: currentColor;
    & + path.domain {
      stroke: none;
    }
  }
  .units {
    fill: currentColor;
    font: normal 10px Arial, sans-serif;
    text-anchor: middle;
  }
  .warning-zone .value {
    font: normal 10px Arial, sans-serif;
  }
  text.aling-left {
    text-anchor: start;
  }
  .plot {
    fill: white;
    transition: fill 1s, stroke 1s;
    .warning-zone .value {
      text-anchor: start;
    }
    line.median,
    text.median {
      stroke: currentColor;
    }
    .bracket {
      text,
      text tspan {
        fill: gray;
        stroke: none;
        dominant-baseline: central;
        text-anchor: start;
        &.align-left {
          text-anchor: end;
        }
      }
      path {
        fill: none;
        stroke: currentColor;
      }
      &.warning-zone {
        color: var(--warning);
        path.reverse {
          transform: scaleX(-1);
          transform-origin: center center;
        }
      }
    }
    .cardinal {
      line {
        stroke: currentColor;
        stroke-dasharray: 5;
      }
      text {
        stroke: none !important;
        fill: currentColor;
        dominant-baseline: middle;
        text-anchor: start;
      }
    }
  }
`;

export default StyledG;
