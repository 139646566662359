import React, { useState, useContext, useEffect } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const SubscriberDetailHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);

  const doClose = () => setOpen(false);

  useEffect(() => {
    return actions.recv("open-help-details", function () {
      setOpen(true);
    });
  }, []);
  return open !== true ? null : (
    <Modal
      title="Subscriber Dashoard"
      onClose={doClose}
      content={() => (
        <>
          Shows current information and statistics for the subscriber with the
          selected IP address or Subscriber ID.
          <ul>
            <li>
              Click on the refresh{" "}
              <i className="material-icons valign-icon">refresh</i> icon to
              refresh data: in the background for the whole page, or in the the
              first panel with subscriber information to refresh just that data,
              or in the flows table panel to refresh just that table.
            </li>
            <li>
              Click on the return{" "}
              <i className="material-icons valign-icon">exit_to_app</i> icon (or
              in the Return button at the bottom) to return to the previous
              page.
            </li>
            <li>
              Click on the print{" "}
              <i className="material-icons valign-icon">print</i> icon to print the dashboard.
            </li>
          </ul>
          <p>
            The first panel with subscriber information show current data for
            the selected subscriber. If there are several IP Addresses for this
            subscriber, or if they reside in different instances (processs), you
            will see a different entry for each one.
          </p>
          The second panel shows subscriber access statistics over time for the
          period selected (from 1-day to 3 months), including: The subscriber
          network statistics include:
          <ul>
            <li>The average speed (measured over 10-minute intervals)</li>
            <li>
              The number of active flows and the number of flows created in 10
              minute intervals
            </li>
            <li>The maximum speed recorded in every 10 minute interval</li>
            <li>
              The average latency for the subscriber (from this node to the
              subscriber, not counting the path from this node to the content
              services in Internet), and the average latency for all the network
              subscribers (as a reference)
            </li>
            <li>
              The average TCP packet retransmissions for the subscriber, and the
              average TCP packet retransmissions for all the network subscribers
              (as a reference)
            </li>
            <li>
              The average percent of total downlink traffic that is flowing at a
              speed near the maximum measured speed, the average percent of
              total downlink traffic that is detected as congested (high losses
              or high latency due to high speed) and the average of total
              download traffic that is being limited by tge ACM functionality
            </li>
            <li>
              The average reduction in downlink latency thanks to the the ACM
              functionality, in milliseconds
            </li>
            <li>
              The average reduction in downlink retransmissions thanks to the
              the ACM functionality, in absolute percent (e.g. a reduction from
              7% to 5% retransmissions will appear as a 2% reduction)
            </li>
          </ul>
          <p>
            The third panel shows Deep-Packet Inspection (DPI) information for
            the selected subscriber. Please, be aware that this comes from a
            sampling of download records , so if a subscriber has little
            traffic, the information may not be very precise). Select the period
            of data for the DPI information (1 day, 1 week or 1 month), the
            number of services to show (ordered by volume) and whether you want
            to see the downlink traffic, the uplink traffic or both. The DPI
            information includes:
          </p>
          <ul>
            <li>
              The Service Breakdown over Time shows the hourly distribution of
              services observed
            </li>
            <li>
              The Latency of Main Services table lists the average latency
              measured for different services used by this subscriber, but only
              measuring the latency between this node and the content servers
              (not the latency from this node to the subscriber)
            </li>
            <li>
              From the table with Latency of Main Services, if you click on the
              XXX icon, you can see the distribution of latencies for the
              specified service (or for all if you select all-average)
            </li>
            <li>
              From the table with Latency of Main Services, if you click on the
              XXX icon, you can see the distribution of latencies over time for
              the specified service (or for all if you select all-average)
            </li>
          </ul>
          <p>
            The last panel includes a table with the Active Flows for the
            selected subscriber, with its own refresh button that avoids
            reloading all the other graphs. Each flow shows:
          </p>
          <ul>
            <li>
              Flow identification information: protocol (PROTO), the access-side
              port (APORT), the Internet-side address (INET-ADDR) and
              Internet-side port (IPORT).
            </li>
            <li>
              The state of the flow, which can be: TCP-Optimized (OP),
              TCP-Tracked (TR), which means that the TCP parameters of the flow
              are being measured but it is not optimized and it is directly
              forwarded, TCP-Handshake traffic (HS), and forwarded traffic (FD),
              which is traffic that is being directly forwarded without TCP
              optimization (but it may be shaped or rate limited).{" "}
            </li>
            <li>
              The total volume carried by the flow (MBYTES-UP and MBYTES-DOWN)
            </li>
            <li>
              The speed of the flow dring the last second before the refresh
              (MBPS-UP and MBPS-DOWN)
            </li>
            <li>The duration of the flow (DURATION)</li>
            <li>The flow policy to which it has been assigned (FLOW-POLICY)</li>
            <li>
              The domain found by DPI, if any, which is the host address in HTTP
              traffic, or the HTTPS or QUIC SNI.
            </li>
          </ul>
        </>
      )}
      onClose={doClose}
    />
  );
};
export default SubscriberDetailHelpModal;
