const renderReference = (filename, content) => {
  const anchor = document.createElement("a");
  anchor.style.cssText = "display:none;";
  const blob = new Blob([content], { type: "text/plain" });
  anchor.download = filename;
  anchor.href = window.URL.createObjectURL(blob);
  document.body.appendChild(anchor);
  return anchor;
};

const readFileFrom = (anchor) => Promise.resolve(anchor.click());

const disposeReference = (anchor) => {
  document.body.removeChild(anchor);
};

const provideUserFile = (filename, content) => {
  if (filename.length === 0) {
    throw "Filename can't be empty.";
  }
  const anchor = renderReference(filename, content);
  return readFileFrom(anchor).finally(() => disposeReference(anchor));
};

export default provideUserFile;
