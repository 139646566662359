import { PERIOD_TO_HOURS, PERIOD_TO_LINES } from "common/constants";
import { getKeysSeries, parseDate } from "../utils";

export const parseStatsBtaData = (response, period, beginRange, endRange) => {
  const [header, ...rows] = response.trim(/\s/).split("\n");
  const keysSeries = getKeysSeries(header);
  const newKeys = ["time", ...keysSeries];

  if (rows.length === 0) {
    return { fields: keysSeries, items: [] };
  }

  const { items, limits } = rows.reduce(
    (acc, value) => {
      const [timeAt, ...attrs] = value.split(/\s+/);
      const time = parseDate(timeAt);
      if (beginRange < time && time < endRange) {
        const item = attrs.reduce(
          (accItem, valueItem, index) => {
            const newValue = valueItem === "n/a" ? null : Number(valueItem);
            accItem[newKeys[index + 1]] = newValue;
            if (newValue !== 0 && newValue !== null) {
              acc.limits.isZeroOrNull = false;
            }
            if (newValue > acc.limits.max) {
              acc.limits.max = newValue;
            }
            return accItem;
          },
          { time }
        );
        acc.items.push(item);
      }
      return acc;
    },
    { items: [], limits: { isZeroOrNull: true, max: 0 } }
  );

  return { fields: keysSeries, items, limits };
};

function getMaxValue(item) {
  const itemCheck = { ...item };
  delete itemCheck.time;
  const maxValue = Math.max(...Object.values(itemCheck));
  return maxValue;
}

export function getInRangeItems({
  items,
  fields,
  sampleSize,
  range,
  initialRange,
  initLimits,
}) {
  if (items.length === 0 || fields.length === 0) {
    return { items: [], limits: initLimits };
  }

  let inrangeItems = [];
  let maxItems;
  let isZeroOrNull = true;
  let maxTotal = 0;

  const selectedRange = range ? range : initialRange;
  const checkLimits = range === undefined || initLimits.isZeroOrNull ? false : true;
  const { from, to } = selectedRange;

  for (let i = 0; i < items.length; i += 1) {
    const { time } = items[i];
    if (from <= time && time <= to) {
      if (!maxItems) {
        maxItems = { ...items[i] };
        if (checkLimits) {
          const maxValue = getMaxValue(maxItems);
          if (maxValue > maxTotal) {
            maxTotal = maxValue;
          }
          if (maxValue !== null && maxValue > 0) {
            isZeroOrNull = false;
          }
        }
      }
      if (i % sampleSize === 0) {
        inrangeItems.push({ ...maxItems, time });
        maxItems = { ...items[i] };
        if (checkLimits) {
          const maxValue = getMaxValue(maxItems);
          if (maxValue > maxTotal) {
            maxTotal = maxValue;
          }
          if (maxValue !== null && maxValue > 0) {
            isZeroOrNull = false;
          }
        }
      } else {
        const newMax = fields.reduce((acc, value) => {
          const newValue = items[i][value];
          if (newValue > acc[value]) {
            acc[value] = newValue;
            if (checkLimits) {
              if (isZeroOrNull && newValue !== 0 && newValue !== null) {
                isZeroOrNull = false;
              }
              if (newValue > maxTotal) {
                maxTotal = newValue;
              }
            }
          }
          return acc;
        }, maxItems);
        maxItems = newMax;
      }
    }
  }

  if (inrangeItems.length === 0) {
    return { items: [], limits: { isZeroOrNull: true, max: 0 } };
  }

  if (
    inrangeItems[inrangeItems.length - 1]?.time &&
    items[items.length - 1]?.time &&
    inrangeItems[inrangeItems.length - 1].time !== items[items.length - 1]?.time
  ) {
    const { time } = items[items.length - 1];
    if (from <= time && time <= to) {
      inrangeItems.push(items[items.length - 1]);
    }
  }

  let extendedItems = [...inrangeItems];
  if (extendedItems.length !== 0) {
    while (extendedItems[0].time >= from) {
      const emptyElement = fields.reduce((acc, value) => {
        acc[value] = null;
        return acc;
      }, {});
      const time = new Date(
        Date.parse(extendedItems[0].time) - 5 * 60000 * sampleSize
      );
      extendedItems.unshift({ ...emptyElement, time });
    }
  }

  const limits = checkLimits ? { isZeroOrNull, max: maxTotal } : initLimits;
  return { items: extendedItems, limits };
}
