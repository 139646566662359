import React, { useEffect, useRef } from "react";
import PubSub from "./PubSub";
import ActionsContext from "./ActionsContext";
import { ProfileContextProvider } from "common/ProfileContext";

const ActionableView = ({ Component, ...params }) => {
  const actionsRef = useRef(new PubSub());
  const actions = actionsRef.current;
  
  useEffect(() => () => actions.clear(), []);

  const content = window.login.isLogged() ? (
    <ProfileContextProvider>
      <Component {...params} />
    </ProfileContextProvider>
  ) : (
    <Component {...params} />
  );

  return (
    <ActionsContext.Provider value={actions}>
      {content}
    </ActionsContext.Provider>
  );
};
export default ActionableView;
