import React, { useState, useContext, useEffect } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const SubscriberDetailHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);

  const doClose = () => setOpen(false);

  useEffect(() => {
    return actions.recv("open-help", function () {
      setOpen(true);
    });
  }, []);
  return open !== true ? null : (
    <Modal
      title="Subscriber Group Dashboard"
      onClose={doClose}
      content={() => (
        <>
          Shows current information and statistics for the subscriber group.
          <ul>
            <li>
              Click on the refresh{" "}
              <i className="material-icons valign-icon">refresh</i> icon to
              refresh data.
            </li>
            <li>
              Click on the return{" "}
              <i className="material-icons valign-icon">exit_to_app</i> icon (or
              in the Return button at the bottom) to return to the previous
              page.
            </li>
            <li>
              Click on the{" "}
              <i className="material-icons valign-icon">edit</i> <strong> Edit subscriber group members </strong>button to edit the subscriber group.
            </li>
            <li>
              Click on the{" "}
              <i className="material-icons valign-icon">multiline_chart</i> <strong> See main subscribers in group </strong>button to see the active subscribers.
            </li>
          </ul>
          The view shows subscriber group access statistics over time for the
          period selected (from 1-day to 3 months), including: The subscriber
          group statistics include:
          <ul>
            <li>The average speed (measured over 10-minute intervals)</li>
            <li>
              The number of active flows and the number of flows created in 10
              minute intervals
            </li>
            <li>
              The average latency for the subscriber group, and the average latency for all the network
              subscribers group (as a reference)
            </li>
            <li>
              The average TCP packet retransmissions for the subscriber group, and the
              average TCP packet retransmissions for all the network subscriber groups
              (as a reference)
            </li>
            <li>Congestion</li>
            <li>The percentage of traffic at maximum speed recorded in every 10 minute interval</li>
          </ul>
        </>
      )}
    />
  );
};
export default SubscriberDetailHelpModal;
