const colors =  [
  "#006ab7",
  "#FFA500",
  "#228B22",
  "#FA8072",
  "#F522F5",
  "#DC143C",
  "#556B2F",
  "#696969",
  "#483D8B",
  "#00008B",
  "#8FBC8F",
  "#B03060",
  "#1DF11D",
  "#48E2AA",
  "#8B4513",
  "#16F8F8",
  "#1A1AF1",
  "#B7EC64",
  "#DA70D6",
  "#7F007F",
  "#008B8B",
  "#F4F436",
  "#90EE90",
  "#ADD8E6",
  "#F81B93",
  "#7B68EE",
  "#FFDEAD",
  "#D6D6D6",
  "#7F0000",
  "#556B2F",
];
export default colors; 
